import React from 'react'
import { Grid, CardContent, Typography, CardHeader } from '@mui/material'
import 'views/views.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import {
    XAxis,
    YAxis,
    Tooltip,
    Line,
    ResponsiveContainer,
    Label,
    ComposedChart,
    Bar,
    CartesianGrid,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { clientTheme } from 'theme-exports'

/**
 * @name LineBarChart
 * @desc Linehart wrapper
 * @param chartTitle - String that represents the title of the chart
 * @param chartActions - Extended functionality that can exist in the header of the chart container - for example a 'update range' button
 * @param data - Array of data, containing [{name: foo, value: 99},...]
 * @param loading - Bool
 */
const LineBarChart = ({
    chartTitle,
    chartActions,
    data = [],
    loading = false,
    leftYAxisPercent = false,
    rightYAxisPercent = false,
    leftYAxisLabel = '',
    rightYAxisLabel = '',
    barColor = '#b71c1c',
    lineColor = '#60b21d',
}: {
    chartTitle: string
    chartActions?: React.ReactElement
    data: any[]
    loading?: boolean
    leftYAxisPercent?: boolean
    rightYAxisPercent?: boolean
    leftYAxisLabel?: string
    rightYAxisLabel?: string
    barColor?: string
    lineColor?: string
}): React.ReactElement => {
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-lineBarChart-tooltipContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={
                                        'emp-lineBarChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={
                                        'emp-lineBarChart-tooltipTableRow'
                                    }
                                    style={{
                                        borderRight: '1px dotted #b3b3b3',
                                        textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="xs"
                                        icon={faSquare}
                                        style={{
                                            marginRight: '5px',
                                            color: lineColor,
                                        }}
                                    />
                                    {leftYAxisLabel}
                                </td>
                                <td
                                    className={
                                        'emp-lineBarChart-tooltipTableRow'
                                    }
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {leftYAxisPercent
                                        ? `${payload[0].payload.value.toFixed(
                                              2
                                          )}%`
                                        : payload[0].payload.value}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={
                                        'emp-lineBarChart-tooltipTableRow'
                                    }
                                    style={{
                                        borderRight: '1px dotted #b3b3b3',
                                        textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="xs"
                                        icon={faSquare}
                                        style={{
                                            marginRight: '5px',
                                            color: barColor,
                                        }}
                                    />
                                    {rightYAxisLabel}
                                </td>
                                <td
                                    className={
                                        'emp-lineBarChart-tooltipTableRow'
                                    }
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {rightYAxisPercent
                                        ? `${payload[0].payload.count.toFixed(
                                              2
                                          )}%`
                                        : payload[0].payload.count}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }

    const toPercent = (val: number) => `${val}%`

    return (
        <Grid
            className={`graph-placeholder emp-lineBarChart-root`}
            xs={12}
            item
        >
            <CardHeader
                className={'emp-lineBarChart-header'}
                title={
                    <>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ fontWeight: 600 }}
                            >
                                {chartTitle}
                            </Typography>
                            {chartActions}
                        </Grid>
                    </>
                }
            />
            <CardContent className={`emp-lineBarChart-content`}>
                {!loading ? (
                    <>
                        {data.length ? (
                            <ResponsiveContainer height={265} width="100%">
                                <ComposedChart
                                    data={data}
                                    margin={{
                                        right: 40,
                                        bottom: 5,
                                        top: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        angle={90}
                                        textAnchor="start"
                                        height={70}
                                        tick={{
                                            fontSize:
                                                clientTheme.typography.label
                                                    .fontSize,
                                        }}
                                    />
                                    {leftYAxisPercent ? (
                                        <YAxis
                                            tickFormatter={toPercent}
                                            dataKey="value"
                                            label={{
                                                value: leftYAxisLabel,
                                                angle: -90,
                                                position: 'insideLeft',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="left"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            dataKey="value"
                                            label={{
                                                value: leftYAxisLabel,
                                                angle: -90,
                                                position: 'insideLeft',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="left"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                    {rightYAxisPercent ? (
                                        <YAxis
                                            orientation="right"
                                            tickFormatter={toPercent}
                                            dataKey="count"
                                            label={{
                                                value: rightYAxisLabel,
                                                angle: 90,
                                                position: 'insideRight',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="right"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            orientation="right"
                                            dataKey="count"
                                            label={{
                                                value: rightYAxisLabel,
                                                angle: 90,
                                                position: 'insideRight',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="right"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar
                                        dataKey="count"
                                        fill={barColor}
                                        maxBarSize={100}
                                        yAxisId="right"
                                    />
                                    <Line
                                        dataKey="value"
                                        stroke={lineColor}
                                        fill={lineColor}
                                        yAxisId="left"
                                        dot={{
                                            stroke: lineColor,
                                            fill: lineColor,
                                        }}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        ) : (
                            <ResponsiveContainer height={225} width="100%">
                                <ComposedChart data={[]}>
                                    <XAxis ticks={['No Data']}>
                                        <Label
                                            value="No Data"
                                            offset={-100}
                                            position="insideTop"
                                            style={{ fontSize: '14px' }}
                                        />
                                    </XAxis>
                                    {leftYAxisPercent ? (
                                        <YAxis
                                            tickFormatter={(tick): string =>
                                                `${tick}%`
                                            }
                                            domain={[0, 100]}
                                            dataKey="value"
                                            label={{
                                                value: leftYAxisLabel,
                                                angle: -90,
                                                position: 'insideLeft',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="left"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            domain={[0, 4]}
                                            dataKey="value"
                                            label={{
                                                value: leftYAxisLabel,
                                                angle: -90,
                                                position: 'insideLeft',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="left"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                    {rightYAxisPercent ? (
                                        <YAxis
                                            domain={[0, 100]}
                                            orientation="right"
                                            dataKey="count"
                                            label={{
                                                value: rightYAxisLabel,
                                                angle: 90,
                                                position: 'insideRight',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="right"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            domain={[0, 4]}
                                            orientation="right"
                                            dataKey="value"
                                            label={{
                                                value: rightYAxisLabel,
                                                angle: 90,
                                                position: 'insideRight',
                                                fontSize: '12px',
                                            }}
                                            yAxisId="right"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                </ComposedChart>
                            </ResponsiveContainer>
                        )}
                    </>
                ) : (
                    <div className={'emp-lineBarChart-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
            </CardContent>
        </Grid>
    )
}

export default LineBarChart
