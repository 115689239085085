import React from 'react'
import { Grid, CardContent, Typography, CardHeader } from '@mui/material'
import 'views/views.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import {
    BarChart as RechartBarChart,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    Legend,
    Label,
    CartesianGrid,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { clientTheme } from 'theme-exports'

/**
 * @name BarChart
 * @desc BarChart wrapper
 * @param chartTitle - String that represents the title of the chart
 * @param chartActions - Extended functionality that can exist in the header of the chart container - for example a 'update range' button
 * @param data - Array of data, containing [{name: foo, value: 99},...]
 * @param loading - Bool
 */
const BarChart = ({
    chartActions,
    chartTitle,
    data = [],
    loading = false,
    valueDataKey = undefined,
    barLineColor = '#b71c1c',
    showLegend = false,
    stackedBarValueKeys = [],
    colorPalette = [],
    dataValuePercent = false,
    height = 225,
    xAxisAngle = false,
    tooltipPercent = false,
    disableAutoDomain = false,
}: {
    chartTitle: string
    chartActions?: React.ReactElement
    data: any[]
    loading?: boolean
    valueDataKey?: undefined | string
    barLineColor?: string
    showLegend?: boolean
    stackedBarValueKeys?: [string, number][]
    colorPalette?: string[]
    dataValuePercent?: boolean
    height?: number
    xAxisAngle?: boolean
    tooltipPercent?: boolean
    disableAutoDomain?: boolean
}): React.ReactElement => {
    const CustomTooltipPercent = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-barChart-tooltipContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    className={
                                        'emp-barChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    className={'emp-barChart-tooltipTableRow'}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {tooltipPercent
                                        ? `${payload[0].value.toFixed(2)}%`
                                        : payload[0].value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }

    const CustomTooltipValue = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-barChart-tooltipContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={
                                        'emp-barChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>
                            {payload.map((item: any, i: number) => {
                                return (
                                    <tr key={`key-tooltip-${i}`}>
                                        <td
                                            className={
                                                'emp-barChart-tooltipTableRow'
                                            }
                                            style={{
                                                borderRight:
                                                    '1px dotted #b3b3b3',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                size="xs"
                                                icon={faSquare}
                                                style={{
                                                    marginRight: '5px',
                                                    color: colorPalette.length
                                                        ? payload[i].fill
                                                        : barLineColor,
                                                }}
                                            />
                                            {payload[i].name}
                                        </td>
                                        <td
                                            className={
                                                'emp-barChart-tooltipTableRow'
                                            }
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {payload[i].value}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    const toPercent = (decimal: number) => `${decimal * 100}%`

    return (
        <Grid item className={`graph-placeholder emp-barChart-root`} xs={12}>
            <CardHeader
                className={'emp-barChart-header'}
                title={
                    <>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ fontWeight: 600 }}
                            >
                                {/* <Icon icon={faCreditCard} />  */}
                                {chartTitle}
                            </Typography>
                            {chartActions}
                        </Grid>
                    </>
                }
            />
            <CardContent className={'emp-barChart-content'}>
                {!loading ? (
                    <>
                        {data.length ? (
                            <ResponsiveContainer
                                height={xAxisAngle ? 265 : height}
                                width="100%"
                            >
                                <RechartBarChart
                                    data={data}
                                    stackOffset={
                                        dataValuePercent ? 'none' : 'expand'
                                    }
                                    margin={{
                                        right: 30,
                                        bottom: 5,
                                        top: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    {xAxisAngle ? (
                                        <XAxis
                                            dataKey="name"
                                            angle={90}
                                            textAnchor="start"
                                            height={70}
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                            interval={0}
                                        />
                                    ) : (
                                        <XAxis
                                            dataKey="name"
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                    {dataValuePercent ? (
                                        <YAxis
                                            tickFormatter={(tick): string =>
                                                dataValuePercent
                                                    ? `${tick}%`
                                                    : tick
                                            }
                                            domain={
                                                disableAutoDomain
                                                    ? undefined
                                                    : dataValuePercent
                                                    ? [0, 100]
                                                    : undefined
                                            }
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            tickFormatter={toPercent}
                                            tick={{
                                                fontSize:
                                                    clientTheme.typography.label
                                                        .fontSize,
                                            }}
                                        />
                                    )}
                                    {dataValuePercent ? (
                                        <Tooltip
                                            content={<CustomTooltipPercent />}
                                            cursor={{ fill: '#f5f5f5' }}
                                        />
                                    ) : (
                                        <Tooltip
                                            content={<CustomTooltipValue />}
                                            cursor={{ fill: '#f5f5f5' }}
                                        />
                                    )}
                                    {dataValuePercent ||
                                    !stackedBarValueKeys.length ? (
                                        <Bar
                                            dataKey={
                                                valueDataKey
                                                    ? valueDataKey
                                                    : 'val'
                                            }
                                            fill={barLineColor}
                                            maxBarSize={100}
                                        />
                                    ) : (
                                        stackedBarValueKeys.map(
                                            (bar: any, index: number) => {
                                                return (
                                                    <Bar
                                                        dataKey={bar[0]}
                                                        key={`${index}_bar-line`}
                                                        fill={
                                                            colorPalette[
                                                                index %
                                                                    colorPalette.length
                                                            ] || '#b71c1c'
                                                        }
                                                        stackId="a"
                                                        maxBarSize={100}
                                                    />
                                                )
                                            }
                                        )
                                    )}

                                    {showLegend ? (
                                        <Legend
                                            verticalAlign="bottom"
                                            align="center"
                                            height={55}
                                            wrapperStyle={{
                                                left: 27,
                                                fontSize:
                                                    clientTheme.typography.body1
                                                        .fontSize,
                                            }}
                                        />
                                    ) : null}
                                </RechartBarChart>
                            </ResponsiveContainer>
                        ) : (
                            <ResponsiveContainer height={225} width="100%">
                                <RechartBarChart data={[]}>
                                    <XAxis ticks={['No Data']}>
                                        <Label
                                            value="No Data"
                                            offset={-100}
                                            position="insideTop"
                                            style={{ fontSize: '14px' }}
                                        />
                                    </XAxis>
                                    <YAxis
                                        tickFormatter={(tick): string =>
                                            `${tick}%`
                                        }
                                        domain={[0, 100]}
                                        tick={{
                                            fontSize:
                                                clientTheme.typography.label
                                                    .fontSize,
                                        }}
                                    />
                                    <Bar dataKey={'val'} />
                                </RechartBarChart>
                            </ResponsiveContainer>
                        )}
                    </>
                ) : (
                    <div className={'emp-barChart-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
            </CardContent>
        </Grid>
    )
}

export default BarChart
