import React, { useEffect, useState, useMemo, SyntheticEvent } from 'react'
import { useHistory } from 'react-router'
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    Box,
    Divider,
    Popover,
    useTheme,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDocument, EncryptedPDFError } from 'pdf-lib'
import CB from 'lib'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import EntFeature from 'models/EntFeature'
import MerchantApi from 'api/MerchantApi'
import useIsMounted from 'hooks/utils/useIsMounted'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { iconsTheme, clientTheme } from 'theme-exports'
import {
    VisaCE,
    ConfirmActionModal,
    FileUploadNew,
    LoadingIndicator,
    Icon,
    CompellingEvidenceLauncherNew,
    AccentAreaModal,
} from 'components'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { TTemplateOption } from 'components/FileUploadNew'
import idDirectory from './idAttributes'

interface UploadRepresentmentsVC3Props {
    className?: string
    id?: string
    title?: string
    caseIds: number[]
    openModal: boolean
    clientId?: number
    // ModalVariant & ModalMode
    // To-do: get these types in toggleModal from some central store (CaseManagement.VM is there ModalVariant and ModalMode are enforced and exported from)
    // didn't feel right to pull the types for upload representments from CaseManagement.VM as they are unrelated
    toggleModal: (
        variant:
            | 'assignUser'
            | 'moveToDnr'
            | 'revertDnr'
            | 'uploadDocuments'
            | 'toggleFlag',
        mode?: 'multiselect' | 'single'
    ) => void
    toggleUploadSuccess?: () => void
    isMultiSelect?: boolean
    rehydrateView?: () => void
    isReupload?: boolean
    comments?: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
    isVisaCE?: boolean
}

interface ICombinedDocument {
    type: string
    label: string
    templateId?: number
    file?: any
    size: number
    pages?: number
}

const UploadRepresentmentsVC3 = ({
    caseIds,
    openModal,
    clientId = 0,
    toggleModal,
    toggleUploadSuccess = () => {},
    isMultiSelect = false,
    rehydrateView,
    isReupload = false,
    comments = '',
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
    isVisaCE = false
}: UploadRepresentmentsVC3Props) => {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up(800))
    const uiState = useUiStateContext()
    const { setSelectedPatchIDs, setSelectedPatchType, user, userStackConfig } = useAuthedUser()
    const history = useHistory()

    const { enabled: hasCompellingEvidence } = useFeatureToggle('HAS_REMEDY')

    const hasQCReviewCheckbox =
        uiState.whoami?.hasRole(EntFeature.QC_REVIEW_CHECKBOX) || false

    const [uploadNote, setUploadNote] = useState(comments)
    const [loading, setLoading] = useState(false)
    const [loadingFileDragAndDrop, setLoadingFileDragAndDrop] = useState<boolean>(false)
    const [loadingTemplatesList, setLoadingTemplatesList] = useState<boolean>(
        false
    )
    const [templatesList, setTemplatesList] = useState<TTemplateOption[]>([])
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(true)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [isQCReviewChecked, setIsQCReviewChecked] = useState<boolean>(false)
    const [isClosingWithFiles, setIsClosingWithFiles] = useState<boolean>(false)
    const [checkingCanUpload, setCheckingCanUpload] = useState<boolean>(false)
    const [
        hasLockedRepresentment,
        setHasLockedRepresentment,
    ] = useState<boolean>(false)
    const [canUploadErrors, setCanUploadErrors] = useState<any[]>([])
    const [
        compellingEvidenceModalOpen,
        setCompellingEvidenceModalOpen,
    ] = useState<boolean>(false)
    const [
        showCompellingEvidence,
        setShowCompellingEvidence,
    ] = useState<boolean>(false)
    // Contains both files and from template library
    const [combinedDocuments, setCombinedDocuments] = useState<
        ICombinedDocument[]
    >([])
    const [multiCombinedDocuments, setMultiCombinedDocuments] = useState<any[]>(
        []
    )
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    
    const maxUploadFilePages =
        userStackConfig?.max_representment_page_count ?? 0
    const maxUploadFileSize =
        userStackConfig?.max_representment_upload_size ?? 0

    const formattedMaxUploadFileSize = Math.floor(maxUploadFileSize / 1048576)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const { isMounted } = useIsMounted()

    const openModalMemo = useMemo(() => openModal, [openModal])

    useEffect(() => {
        if (
            hasCompellingEvidence &&
            openModal &&
            isVisaCE
        ) { setShowCompellingEvidence(true)}
    }, [hasCompellingEvidence, openModal, isVisaCE])

    const dialogTitle = useMemo(() => {
        const verbiage =
            caseIds.length > 1 ? `${caseIds.length} cases` : caseIds[0]
        return `Upload Case Document (${verbiage})`
    }, [caseIds])

    const isExceedingMaximumUploadPages = (selectedFilePages: number) => {
        if (typeof maxUploadFilePages === 'undefined') {
            setAlertSnackbarProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            return true
        }

        if (maxUploadFilePages === 0) return false

        let totalPages: number = 0

        if (isMultiSelect) {
            totalPages =
                (multiCombinedDocuments[0]?.files ?? []).reduce(
                    (sum: number, document: any) => {
                        return sum + document.pages
                    },
                    0
                ) + selectedFilePages
        } else {
            totalPages =
                combinedDocuments.reduce((sum: number, document: any) => {
                    return sum + document.pages
                }, 0) + selectedFilePages
        }

        if (totalPages > maxUploadFilePages) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Images and PDF pages must not exceed ${maxUploadFilePages} total.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)

            return true
        }

        return false
    }

    const isExceedingMaximumUploadLimit = (selectedFileSize: number) => {
        if (!maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            return true
        }

        let totalSize: number = 0

        if (isMultiSelect) {
            totalSize =
                (multiCombinedDocuments[0]?.files ?? []).reduce(
                    (sum: number, document: any) => {
                        return sum + document.size
                    },
                    0
                ) + selectedFileSize
        } else {
            totalSize =
                combinedDocuments.reduce((sum: number, document: any) => {
                    return sum + document.size
                }, 0) + selectedFileSize
        }

        if (totalSize > maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Files exceed ${formattedMaxUploadFileSize}MB total limit.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)

            return true
        }

        return false
    }

    const handleFileDragAndDrop = async (file: any) => {
        let pageSize = 0
        if (!caseIds.length) return
        if (!file.length) {
            setAlertSnackbarProps({
                title: 'Error',
                message:
                    'Only single JPG or PDF files of no more than 10Mb in size are allowed.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }
        if (isExceedingMaximumUploadLimit(file[0].size)) return
        // Check for bad JPGs, or PNG, etc. that have been renamed to JPG, in order to get past extension validation.
        if (file[0].type === 'image/jpeg') {
            let jpgError = false
            const testJPGFile = (file: any) => {
                return new Promise((resolve) => {
                    const fileReader = new FileReader()
                    fileReader.onload = (e) => {
                        try {
                            // @ts-ignore
                            const rawData = e.target.result.toString() ?? ''
                            const imageData = Uint8Array.from(
                                atob(
                                    rawData.replace(
                                        'data:image/jpeg;base64,',
                                        ''
                                    )
                                ),
                                (c) => c.charCodeAt(0)
                            )
                            jpgError = !(
                                imageData[imageData.length - 1] === 217 &&
                                imageData[imageData.length - 2] === 255
                            )
                        } catch (e) {
                            jpgError = true
                        }
                        if (jpgError) {
                            setAlertSnackbarProps({
                                title: 'Error',
                                message: 'Selected JPG file is corrupt.',
                                intent: 'error',
                            })
                            setAlertSnackbarOpen(true)
                        }
                        return resolve(jpgError)
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            await testJPGFile(file[0])
            if (jpgError) return
            pageSize = 1
        }
        // Check for encrypted or otherwise corrupt PDF file.
        if (file[0].type === 'application/pdf') {
            let pdfError = false
            const PDFBuffer = await file[0].arrayBuffer()
            const PDFByteArray = new Uint8Array(PDFBuffer)
            await PDFDocument.load(PDFByteArray)
                .then(async (doc) => {
                    try {
                        // Final check to see if PDF really was opened, or corrupt in a way that it might sneak by.
                        pageSize = doc.getPageCount()
                        await doc
                            .copyPages(doc, doc.getPageIndices())
                            .catch(() => {
                                throw new EncryptedPDFError()
                            })
                    } catch (e) {
                        throw new EncryptedPDFError()
                    }
                })
                .catch((e: EncryptedPDFError) => {
                    pdfError = true
                    if (e.message.indexOf('encrypted') > -1) {
                        setAlertSnackbarProps({
                            title: 'Error',
                            message:
                                'Selected PDF file is corrupt or encrypted.',
                            intent: 'error',
                        })
                        return setAlertSnackbarOpen(true)
                    }
                })
            if (pdfError) return
        }

        if (isExceedingMaximumUploadPages(pageSize)) return

        if (isMultiSelect) {
            setLoadingFileDragAndDrop(true)
            const docUploadPromiseArray = caseIds.map((id) =>
                CB.documents.uploadSingleFile(id.toString(), file)
            )

            Promise.all(docUploadPromiseArray)
                .then((data) => {
                    const fileArr: any[] = []
                    data.forEach((file, i) => {
                        fileArr.push({
                            caseId: caseIds[i],
                            files: [
                                // if documents aleady exists for the selected cases, spread the existing files before the new file
                                ...(multiCombinedDocuments[i]
                                    ? multiCombinedDocuments[i].files
                                    : []),
                                {
                                    type: 'file',
                                    label: file.filename,
                                    file,
                                    size: file.size,
                                    pages: pageSize,
                                },
                            ],
                        })
                    })

                    if (isMounted.current) setMultiCombinedDocuments(fileArr)
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
                .finally(() => setLoadingFileDragAndDrop(false))
        } else {
            setLoadingFileDragAndDrop(true)
            CB.documents
                .uploadSingleFile(caseIds[0].toString(), file)
                .then((data: any) => {
                    if (isMounted.current)
                        setCombinedDocuments([
                            ...combinedDocuments,
                            {
                                type: 'file',
                                label: data.filename,
                                file: data,
                                size: data.size,
                                pages: pageSize,
                            },
                        ])
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
                .finally(() => setLoadingFileDragAndDrop(false))
        }
    }

    const handleTemplateListSelect = (template: any) => {
        if (isExceedingMaximumUploadLimit(template.file.size)) return
        if (isExceedingMaximumUploadPages(template.file.pages ?? 1)) return

        if (isMultiSelect) {
            const newDocArr: any[] = []
            // loop through the multi combined docs and add the selected template to them
            if (multiCombinedDocuments.length) {
                multiCombinedDocuments.forEach((doc) => {
                    newDocArr.push({
                        ...doc,
                        files: [
                            ...(doc.files.length ? doc.files : []),
                            {
                                type: 'template',
                                label: template.name,
                                templateId: template.id,
                                size: template.file.size,
                                pages: template.file.pages ?? 1,
                            },
                        ],
                    })
                })
            } else {
                // if the template is the first file selected, this builds the array of case objects from the selected case ids
                caseIds.forEach((id, i) => {
                    newDocArr.push({
                        caseId: id,
                        files: [
                            // if documents aleady exists for the selected cases, spread the existing files before the new file
                            ...(multiCombinedDocuments[i]
                                ? multiCombinedDocuments[i].files
                                : []),
                            {
                                type: 'template',
                                label: template.name,
                                templateId: template.id,
                                size: template.file.size,
                                pages: template.file.pages ?? 1,
                            },
                        ],
                    })
                })
            }

            if (isMounted.current) setMultiCombinedDocuments(newDocArr)
        } else {
            if (isMounted.current)
                setCombinedDocuments([
                    ...combinedDocuments,
                    {
                        type: 'template',
                        label: template.name,
                        templateId: template.id,
                        size: template.file.size,
                        pages: template.file.pages ?? 1,
                    },
                ])
        }
    }

    const handleUnstage = (file: number) => {
        if (isMultiSelect) {
            multiCombinedDocuments.forEach((cases) => {
                cases.files.splice(file, 1)
            })
            if (isMounted.current)
                setMultiCombinedDocuments([...multiCombinedDocuments])
        } else {
            combinedDocuments.splice(file, 1)
            if (isMounted.current) setCombinedDocuments([...combinedDocuments])
        }
    }

    const handleReorderCombinedDocuments = (result: any) => {
        const to = result.destination.index
        const from = result.source.index

        if (isMultiSelect) {
            multiCombinedDocuments.forEach((cases, i) => {
                cases.files.splice(
                    to,
                    0,
                    multiCombinedDocuments[i].files.splice(from, 1)[0]
                )
            })
            if (isMounted.current)
                setMultiCombinedDocuments([...multiCombinedDocuments])
        } else {
            combinedDocuments.splice(
                to,
                0,
                combinedDocuments.splice(from, 1)[0]
            )
            if (isMounted.current) setCombinedDocuments([...combinedDocuments])
        }
    }

    const clearIsUploadingVars = () => {
        setCombinedDocuments([])
        setMultiCombinedDocuments([])
        setCanUploadErrors([])
        setUploadNote('')
        setIsQCReviewChecked(false)
        setSelectedPatchIDs && setSelectedPatchIDs('')
        setSelectedPatchType && setSelectedPatchType('')
    }

    const handleRepresentmentUpload = () => {
        if (uploadDisabled) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Please select a file to upload.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }

        setLoading(true)
        CB.documents
            .postRepresentment(
                caseIds[0],
                combinedDocuments,
                isQCReviewChecked,
                isReupload,
                uploadNote
            )
            .then(() => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success!',
                        message: 'The representment was successfully uploaded.',
                        intent: 'success',
                    })
                clearIsUploadingVars()
                setAlertSnackbarMainOpen(true)
                toggleUploadSuccess()
                toggleModal('uploadDocuments')
                if (rehydrateView) rehydrateView()
            })
            .catch((message) => {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message:
                            message ??
                            'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const toggleCompellingEvidenceModalOpen = (
        open: boolean,
        closeRepresentment?: boolean
    ) => {
        if (closeRepresentment) {
            if (rehydrateView) rehydrateView()
            setCompellingEvidenceModalOpen(open)
            return toggleModal('uploadDocuments')
        }
        return setCompellingEvidenceModalOpen(open)
    }

    const closeUploadDialogWithoutSave = async () => {
        toggleModal('uploadDocuments')
        setIsClosingWithFiles(false)
        if (!isReupload && !canUploadErrors.length) {
            return await CB.cases
                .callCasePatch(caseIds.join(','), 1, 'upload')
                .finally(() => {
                    if (isMounted.current) {
                        clearIsUploadingVars()
                    }
                })
        }
        if (isMounted.current) clearIsUploadingVars()
    }

    const checkFilesBeforeClose = () => {
        if (
            combinedDocuments.length ||
            multiCombinedDocuments.filter((obj) => obj.files.length).length ||
            comments !== uploadNote
        ) {
            return setIsClosingWithFiles(true)
        }
        closeUploadDialogWithoutSave()
    }

    const handleBulkRepresentmentUpload = () => {
        if (uploadDisabled) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Please select a file to upload.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }
        setLoading(true)

        const uploadArray = caseIds.map((id) => {
            // find case object in multi combined doc array
            const _case = multiCombinedDocuments.filter(
                (doc) => doc.caseId === id
            )[0]
            return CB.documents.postRepresentment(
                id,
                _case.files,
                isQCReviewChecked,
                isReupload,
                uploadNote
            )
        })

        Promise.all(uploadArray)
            .then(() => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success!',
                        message: 'The representment was successfully uploaded.',
                        intent: 'success',
                    })
                clearIsUploadingVars()
                setAlertSnackbarMainOpen(true)
                toggleUploadSuccess()
                toggleModal('uploadDocuments')
                unselectAllRows()
                if (rehydrateView) rehydrateView()
            })
            .catch((message) => {
                if (isMounted.current)
                    setAlertSnackbarProps({
                        title: 'Error',
                        message,
                        intent: 'error',
                    })
                setAlertSnackbarOpen(true)
            })
            .finally(() => {
                setSelectedPatchIDs && setSelectedPatchIDs('')
                setSelectedPatchType && setSelectedPatchType('')
                setLoading(false)
            })
    }

    useEffect(() => {
        if (openModalMemo && isMounted.current) {
            setLoadingTemplatesList(true)
            const userMerchantId = user?.merchant?.id || 1
            MerchantApi.getDocumentTemplatesList(userMerchantId)
                .then(({ data: { data } }) => {
                    setTemplatesList(
                        data.map((data: any) => {
                            return {
                                name: data.file.filename,
                                id: data.id,
                                file: data.file,
                            }
                        })
                    )
                })
                .finally(() => setLoadingTemplatesList(false))
        }
    }, [clientId, openModalMemo, isMounted, user])

    useEffect(() => {
        const shouldBeDisabled =
            combinedDocuments.length || multiCombinedDocuments.length
                ? false
                : true

        setUploadDisabled(shouldBeDisabled)
    }, [combinedDocuments, multiCombinedDocuments])

    useEffect(() => {
        if (!openModal && hasLockedRepresentment) {
            return setHasLockedRepresentment(false)
        }
        if (
            openModal &&
            !isReupload &&
            !checkingCanUpload &&
            !hasLockedRepresentment
        ) {
            setCheckingCanUpload(true)
            CB.cases
                .callCasePatch(caseIds.join(','), 16, 'upload')
                .then(() => {
                    setSelectedPatchIDs &&
                        setSelectedPatchIDs(caseIds.join(','))
                    setSelectedPatchType && setSelectedPatchType('upload')
                    history.push({
                        pathname: '',
                        state: {
                            caseId: caseIds.join(','),
                            reviewType: 'upload',
                        },
                    })
                })
                .catch((err: any) => {
                    setCanUploadErrors(err.response.data.data)
                })
                .finally(() => {
                    setHasLockedRepresentment(true)
                    setCheckingCanUpload(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal, isReupload, caseIds, hasLockedRepresentment])

    return (
        <>
            <AccentAreaModal
                openModal={openModalMemo && !compellingEvidenceModalOpen}
                onModalClose={checkFilesBeforeClose}
                modalHeaderTitle={
                    showCompellingEvidence
                        ? `${dialogTitle} or Start CE 3.0 Remedy`
                        : dialogTitle
                }
                testId={'uploadRepresentments'}
                maxModalWidth={showCompellingEvidence ? 'md' : 'sm'}
                enablePrimaryActionBtn={false}
                enableSecondaryActionBtn={false}
            >
                <div
                    className={'emp-uploadRepresentmentsVC3-rootContainer'}
                    id={idDirectory.divRoot}
                >
                    {checkingCanUpload || canUploadErrors.length ? (
                        !canUploadErrors.length ? (
                            <div
                                className={
                                    'emp-uploadRepresentmentsVC3-loadingContainer'
                                }
                                id={idDirectory.divLoading}
                            >
                                <LoadingIndicator />
                            </div>
                        ) : (
                            <div
                                className={
                                    'emp-uploadRepresentmentsVC3-errorContainer'
                                }
                                id={idDirectory.divError}
                            >
                                <div
                                    className={
                                        'emp-uploadRepresentmentsVC3-errorMsgContainer'
                                    }
                                    id={idDirectory.divErrorMsg}
                                >
                                    <h3>
                                        Unable to process the following cases at
                                        this time. Please try again later.
                                    </h3>
                                    {canUploadErrors.map((err, idx) => {
                                        return (
                                            <div key={`key-${idx}`}>
                                                {err.id} -{' '}
                                                {err.errors.join(', ')}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div
                                    className={
                                        'emp-uploadRepresentmentsVC3-errorBtnContainer'
                                    }
                                >
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={checkFilesBeforeClose}
                                        id={idDirectory.btnBack}
                                    >
                                        Back to cases
                                    </Button>
                                </div>
                            </div>
                        )
                    ) : (
                        <div
                            className={
                                'emp-uploadRepresentmentsVC3-mainContainer'
                            }
                            id={idDirectory.divMain}
                            style={{
                                ...(showCompellingEvidence &&
                                    isLargeScreen && {
                                        gridTemplateColumns: '1fr auto 1fr',
                                    }),
                            }}
                        >
                            <div
                                className={
                                    'emp-uploadRepresentmentsVC3-uploadContainer'
                                }
                                id={idDirectory.divUpload}
                            >
                                <FileUploadNew
                                    onFileDragAndDrop={handleFileDragAndDrop}
                                    loadingFileDragAndDrop={
                                        loadingFileDragAndDrop
                                    }
                                    onTemplateListSelect={
                                        handleTemplateListSelect
                                    }
                                    onUnstage={handleUnstage}
                                    onReorderCombinedDocuments={
                                        handleReorderCombinedDocuments
                                    }
                                    accepts={['.jpg', '.pdf']}
                                    isRepresentmentModal
                                    templatesList={templatesList}
                                    loadingTemplatesList={loadingTemplatesList}
                                    combinedDocuments={
                                        isMultiSelect
                                            ? multiCombinedDocuments
                                            : combinedDocuments
                                    }
                                    isMultiSelect={isMultiSelect}
                                    fileUploadInstructionText={
                                        'Drag & Drop here, or choose a file to upload your case document'
                                    }
                                    enableFullWidth={
                                        !Boolean(
                                            showCompellingEvidence &&
                                                isLargeScreen
                                        )
                                    }
                                    isLargeScreen={isLargeScreen}
                                />
                                <Box
                                    className={
                                        'emp-uploadRepresentmentsVC3-caseCommentContainer'
                                    }
                                    id={idDirectory.divCaseComment}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius:
                                                clientTheme.selectionBox
                                                    .borderRadius,
                                        },
                                    }}
                                >
                                    <Typography>Case Comments</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        value={uploadNote}
                                        onChange={(e): void =>
                                            setUploadNote(e.target.value)
                                        }
                                        name="case_comments"
                                    />
                                </Box>
                                {hasQCReviewCheckbox && !isReupload && (
                                    <div
                                        className={
                                            'emp-uploadRepresentmentsVC3-isQCReviewContainer'
                                        }
                                        id={idDirectory.divQCReview}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isQCReviewChecked}
                                                    onChange={(
                                                        ev: SyntheticEvent
                                                    ) => {
                                                        const target = ev.target as HTMLInputElement
                                                        return setIsQCReviewChecked(
                                                            target.checked
                                                        )
                                                    }}
                                                    color={'secondary'}
                                                />
                                            }
                                            label="Send the case for Quality Review"
                                        />
                                        <div
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                            className={
                                                'emp-uploadRepresentmentsVC3-isQCReviewHelp'
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                                color="#3cb4e5"
                                            />
                                        </div>
                                        <Popover
                                            sx={{
                                                pointerEvents: 'none',
                                            }}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            onClose={handlePopoverClose}
                                        >
                                            <Typography
                                                className={
                                                    'emp-uploadRepresentmentsVC3-isQCReviewPopover'
                                                }
                                            >
                                                If this option is selected, then
                                                the case would be routed to
                                                Quality Review after the file
                                                upload process is completed and
                                                this pop up window is closed.
                                            </Typography>
                                        </Popover>
                                    </div>
                                )}
                                <Typography style={{ fontSize: '12px' }}>
                                    {isQCReviewChecked ? (
                                        <span>
                                            *Click upload below to send the
                                            document to Quality Control.
                                        </span>
                                    ) : (
                                        <span>
                                            *Click upload below to send the
                                            document and complete case.
                                        </span>
                                    )}
                                </Typography>
                                <div>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={
                                            isMultiSelect
                                                ? handleBulkRepresentmentUpload
                                                : handleRepresentmentUpload
                                        }
                                        disabled={loading}
                                        id={idDirectory.btnUpload}
                                    >
                                        {iconsTheme.upload && (
                                            <Icon
                                                className={`${iconsTheme.upload} emp-uploadRepresentmentsVC3-uploadIcon`}
                                            />
                                        )}
                                        {loading
                                            ? 'Uploading files...'
                                            : 'Upload'}
                                    </Button>
                                </div>
                            </div>
                            {showCompellingEvidence && (
                                <>
                                    <Divider
                                        orientation={
                                            isLargeScreen
                                                ? 'vertical'
                                                : 'horizontal'
                                        }
                                        className={
                                            'emp-uploadRepresentmentsVC3-divider'
                                        }
                                    />
                                    <div
                                        className={
                                            'emp-uploadRepresentmentsVC3-CEContainer'
                                        }
                                        id={idDirectory.divCE}
                                    >
                                        <CompellingEvidenceLauncherNew
                                            isRemedyPossible={true}
                                            setCompellingEvidenceModalOpen={
                                                setCompellingEvidenceModalOpen
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <AlertSnackbar
                    content={alertSnackbarProps}
                    open={alertSnackbarOpen}
                    onClose={() => {
                        setAlertSnackbarOpen(false)
                    }}
                    showCloseIcon
                />
            </AccentAreaModal>
            <VisaCE
                caseId={caseIds[0]}
                open={compellingEvidenceModalOpen}
                setCompellingEvidenceModalOpen={
                    toggleCompellingEvidenceModalOpen
                }
            />
            <ConfirmActionModal
                open={isClosingWithFiles}
                toggleOpen={() => setIsClosingWithFiles(false)}
                onConfirm={closeUploadDialogWithoutSave}
                header={'Are you sure?'}
                message={'The changes you made in this session will be lost.'}
                confirmButtonText={'Discard'}
                testId={'upload-representments'}
            />
        </>
    )
}

export default UploadRepresentmentsVC3
