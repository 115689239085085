import MerchantApi from 'api/MerchantApi'
import {
    useSWRActions,
    SWRActions,
    useSwrData,
    useParams,
    User as Users,
} from 'hooks/cb-api'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useIsMounted from 'hooks/utils/useIsMounted'
import CB from 'lib'
import { Merchant } from 'lib/Merchants'
import { Mid } from 'lib/Mids'
import { useEffect, useState } from 'react'
import axios from 'axios'

export type { Merchant } from 'lib/Merchants'
export type { Mid } from 'lib/Mids'

interface User {
    merchantId: string | undefined
}

export type UseMidsForMerchant = (
    merchantId: number,
    shouldFetch: boolean
) => {
    mids: Mid[]
    isMidsLoading: boolean
    midsError: Error | null
}

export type SetOwnParentMerchant = (
    merchantId: number,
    parentMerchantId: number | null
) => Promise<any>

export type SetChildParentMerchant = (
    merchantId: number,
    childIds: number[]
) => Promise<any>

export type SelectMid = (midId: number) => void

export type AssignAllMidsToUser = (userId: number) => Promise<any>

export type AssignAllMerchantsToUser = (userId: number) => Promise<any>

export type RemoveAllMidsFromUser = (userId: number) => Promise<any>

export type RemoveAllMerchantsFromUser = (userId: number) => Promise<any>

export type SetActiveNodeId = (nodeId: string) => void

export type SetMerchantSearchValue = (value: string) => void

export type SelectMerchant = (merchantId: number) => void

export type SetForceReload = (value: boolean) => void

export type Deflector = {
    alias: string
    id: number
    name: string
    status: {
        id: number
        name: string
    }
}

interface MerchantHierarchyVM {
    users: Users[]
    usersParams: { [key: string]: any }
    user: User
    merchants: Merchant[]
    rootMerchant: Merchant | null
    isMerchantsLoading: boolean
    midDetails: Mid | null
    merchantSearchValue: string
    activeNodeId: string
    setActiveNodeId: SetActiveNodeId
    setMerchantSearchValue: SetMerchantSearchValue
    setOwnParentMerchant: SetOwnParentMerchant
    setChildParentMerchant: SetChildParentMerchant
    selectMid: SelectMid
    selectMerchant: SelectMerchant
    assignAllMidsToUser: AssignAllMidsToUser
    assignAllMerchantsToUser: AssignAllMerchantsToUser
    removeAllMidsFromUser: RemoveAllMidsFromUser
    removeAllMerchantsFromUser: RemoveAllMerchantsFromUser
    useMidsForMerchant: UseMidsForMerchant
    setForceReload: SetForceReload
    start: number
    setStart: (val: number) => void
    pagination: {
        start: number
        pageSize: number
        totalEntries: number
        pageCount: number
    }
    activeMerchantId: number
    swrActions: SWRActions
    wasMidTileClicked: boolean
    midsMerchantId: number
    setWasMidTileClickReset: (val: boolean) => void
    wasMidTileClickReset: boolean
    availableDeflectors: Deflector[]
    selectSearchType: string
    setSelectSearchType: (value: string) => void
}

export const useMerchantHierarchy = (props: any): MerchantHierarchyVM => {
    const { user } = useAuthedUser()
    const { id } = useActiveMerchant()
    const [activeNodeId, setActiveNodeId] = useState(
        `merchant-${user?.merchant?.id ?? 1}`
    )
    const [rootMerchant, setRootMerchant] = useState<Merchant | null>(null)
    const [merchants, setMerchants] = useState<Merchant[]>([])
    const [isMerchantsLoading, setIsMerchantLoading] = useState(false)
    const [midDetails, setMidDetails] = useState<Mid | null>(null)
    const [merchantSearchValue, setMerchantSearchValue] = useState('')
    const [invalidatedAt, setInvalidatedAt] = useState(0)
    const [activeMerchantId, setActiveMerchantId] = useState(
        user?.merchant?.id ?? 1
    )
    const [wasMidTileClicked, setWasMidTileClicked] = useState<boolean>(false)
    const [wasMidTileClickReset, setWasMidTileClickReset] = useState<boolean>(
        false
    )
    const [forceReload, setForceReload] = useState<boolean>(false)
    const [start, setStart] = useState(0)
    const [pagination, setPagination] = useState({
        start: 0,
        limit: 20,
        total: 0,
    })
    const [availableDeflectors, setAvailableDeflectors] = useState([])
    const [selectSearchType, setSelectSearchType] = useState<string>('merchant')

    const midsTileClicked = props.location.state?.preset_filter.find(
        (filter: any) => filter.filter_name === 'mids_tile_clicked'
    )
        ? true
        : false
    const { params, setQueryParams, setParams, reset } = useParams()
    const rootMerchantId = rootMerchant?.id
    const userId = user?.id

    const endpointCongfig = {
        paramInUrl: false,
        url: 'users/users',
        id: {
            key: 'merchant_id',
            value: activeMerchantId,
        },
    }

    useEffect(() => {
        if (midsTileClicked && user && +id !== user?.merchant?.id) {
            setWasMidTileClicked(true)
        }
    }, [setWasMidTileClicked, midsTileClicked, id, user])

    const usersData = useSwrData(endpointCongfig, params)

    const swrActions = useSWRActions(usersData, {
        setParams,
        setQueryParams,
        params,
        reset,
    })

    // represents mounted state
    const { isMounted } = useIsMounted()

    // Call this to run all useEffects.
    const invalidate = () => setInvalidatedAt(Date.now())

    useEffect(() => {
        if (user && user.merchant) {
            CB.merchants.get(user.merchant.id.toString()).then((r) => {
                if (isMounted.current) setRootMerchant(r)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isMounted])

    useEffect(() => {
        if (rootMerchant) {
            // Merchant Tree data
            if (!rootMerchant) return
            setIsMerchantLoading(true)
            setMerchants([])
            axios
                .get('/cm/merchants/list', {
                    params: {
                        merchant_id: rootMerchant.id.toString(),
                        start: start,
                        limit: 20,
                        sort_order: 'asc',
                        sort_by: 'business_name',
                        search: merchantSearchValue ? merchantSearchValue : '',
                        search_merchant_id:
                            midsTileClicked &&
                            +id !== rootMerchant.id &&
                            !wasMidTileClickReset
                                ? +id
                                : null,
                    },
                })
                .then((res) => {
                    if (isMounted.current) {
                        !res.data.data.length
                            ? setMerchants(res.data.data)
                            : setMerchants(res.data.data[0].children)

                        setPagination(res.data.pagination)
                    }
                })
                .finally(() => {
                    if (isMounted.current) setIsMerchantLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        id,
        midsTileClicked,
        wasMidTileClickReset,
        rootMerchantId,
        merchantSearchValue,
        invalidatedAt,
        forceReload,
        isMounted,
        start,
    ])

    const selectMerchant = (merchantId: number) => {
        if (isMounted.current) setMidDetails(null)
        setActiveMerchantId(merchantId)
    }

    const selectMid = (midId: number) => {
        CB.mids.get(midId.toString()).then((mid) => {
            if (isMounted.current) setMidDetails(mid)
            setActiveMerchantId(mid.merchant_id)
        })
    }

    const setOwnParentMerchant = (
        merchantId: number,
        parentMerchantId: number | null
    ) => {
        return MerchantApi.updateMerchantParent(
            merchantId,
            parentMerchantId
        ).finally(invalidate)
    }

    const setChildParentMerchant = (
        parentMerchantId: number,
        childIds: number[]
    ): Promise<any> => {
        return Promise.all(
            childIds.map((id) => setOwnParentMerchant(id, parentMerchantId))
        )
    }

    const assignAllMidsToUser: AssignAllMidsToUser = (userId: number) => {
        return CB.mids.addAllMidPermissions(userId, [])
    }

    const assignAllMerchantsToUser: AssignAllMerchantsToUser = (
        userId: number
    ) => {
        return Promise.resolve()
    }

    const removeAllMidsFromUser: RemoveAllMidsFromUser = (userId: number) => {
        return CB.mids.removeAllMidPermissions(userId, [])
    }

    const removeAllMerchantsFromUser: RemoveAllMerchantsFromUser = (
        userId: number
    ) => {
        return Promise.resolve()
    }

    const useMidsForMerchant = (merchantId: number, shouldFetch = true) => {
        const [mids, setMids] = useState<Mid[]>([])
        const [isMidsLoading, setIsMidsLoading] = useState(false)
        const [midsError, setMidsError] = useState<Error | null>(null)

        const activeNodeIdActual = +/[0-9]+$/.exec(activeNodeId)![0] || 1

        useEffect(() => {
            if (shouldFetch && merchantId === activeNodeIdActual) {
                if (mids.length === 0) {
                    setIsMidsLoading(true)
                }
                CB.mids
                    .list(merchantId.toString(), {
                        limit: 999,
                        self: 1,
                        ...(merchantSearchValue &&
                            selectSearchType === 'mid' && {
                                search: merchantSearchValue,
                            }),
                    })
                    .then((r) => {
                        if (isMounted.current) setMids(r.data)
                        setMidsError(null)
                    })
                    .catch(setMidsError)
                    .finally(() => {
                        if (isMounted.current) setIsMidsLoading(false)
                    })
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [shouldFetch, merchantId, forceReload, selectSearchType])

        return {
            mids,
            isMidsLoading,
            midsError,
        }
    }

    const userMerchantId: User = {
        merchantId: user?.merchant?.id.toString(),
    }

    useEffect(() => {
        axios.get('/cm/gen/deflectors').then((res) => {
            setAvailableDeflectors(res.data.data)
        })
    }, [])

    return {
        user: userMerchantId,
        users: usersData.data ?? [],
        usersParams: params,
        activeNodeId,
        rootMerchant,
        merchants,
        isMerchantsLoading,
        merchantSearchValue,
        midDetails,
        setMerchantSearchValue,
        setActiveNodeId,
        selectMid,
        selectMerchant,
        setOwnParentMerchant,
        setChildParentMerchant,
        assignAllMerchantsToUser,
        assignAllMidsToUser,
        removeAllMerchantsFromUser,
        removeAllMidsFromUser,
        useMidsForMerchant,
        setForceReload,
        start,
        setStart,
        pagination: {
            start: pagination.start,
            pageSize: pagination.limit,
            totalEntries: pagination.total,
            pageCount: Math.ceil(pagination.total / pagination.limit),
        },
        activeMerchantId,
        swrActions,
        wasMidTileClicked,
        midsMerchantId: +id,
        setWasMidTileClickReset,
        wasMidTileClickReset,
        availableDeflectors,
        selectSearchType,
        setSelectSearchType,
    }
}

export default useMerchantHierarchy
