import React, { FC } from 'react'
import {
    Bar,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Label,
    Line,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { CbDatum } from '../ResultantKpi.vm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'

interface Props {
    data: CbDatum[]
    loading: boolean
}

const ResultantKpiStackedBarChart: FC<Props> = ({ data, loading }) => {
    const returnFormattedLegendLabel = (value: any, entry: any) => {
        return (
            <span
                style={{
                    textTransform: 'capitalize',
                    fontSize: clientTheme.typography.body1.fontSize,
                }}
            >
                {value}
            </span>
        )
    }

    const CustomTooltipValue = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-tooltipContainer '}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={'emp-tooltipTableHeader'}
                                >
                                    {label}
                                </td>
                            </tr>
                            {payload.map((item: any, i: number) => {
                                return (
                                    <tr key={`key-tooltip-${i}`}>
                                        <td
                                            className={'emp-tooltipTableRow'}
                                            style={{
                                                borderRight:
                                                    '1px dotted #b3b3b3',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                size="xs"
                                                icon={faSquare}
                                                style={{
                                                    marginRight: '5px',
                                                    color: payload[i].fill,
                                                }}
                                            />
                                            {payload[i].name}
                                        </td>
                                        <td
                                            className={'emp-tooltipTableRow'}
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {payload[i].dataKey ===
                                            'result_ratio'
                                                ? `${payload[i].value}%`
                                                : payload[i].value}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    return (
        <div id={'resultantKpiStackedBarChart'}>
            {!loading ? (
                data.length ? (
                    <ResponsiveContainer height={300} width="100%">
                        <ComposedChart
                            data={data.map((obj: any) => {
                                return {
                                    ...obj,
                                    chargebacks: +obj.chargebacks,
                                    transactions: +obj.transactions,
                                    result_ratio: +obj.result_ratio,
                                }
                            })}
                            margin={{ right: 25, left: 20, top: 10 }}
                        >
                            <XAxis
                                dataKey="month"
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                            <YAxis
                                dataKey="result_ratio"
                                label={{
                                    value: 'Ratio',
                                    angle: -90,
                                    position: 'insideLeft',
                                    dy: 20,
                                    fontSize: '12px',
                                }}
                                yAxisId="left"
                                tickFormatter={(value: any) => `${value}%`}
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />

                            <YAxis
                                orientation="right"
                                dataKey="transactions"
                                label={{
                                    value: 'Chargebacks & Transactions',
                                    angle: 90,
                                    position: 'insideRight',
                                    dy: 90,
                                    fontSize: '12px',
                                }}
                                yAxisId="right"
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />

                            <Tooltip
                                content={<CustomTooltipValue />}
                                cursor={{ fill: '#f5f5f5' }}
                            />
                            <Bar
                                dataKey="chargebacks"
                                name="Chargebacks"
                                stroke="#ff7124"
                                fill="#ff7124"
                                yAxisId="right"
                                stackId="a"
                            />
                            <Bar
                                dataKey="transactions"
                                name="Transactions"
                                stroke="#006da8"
                                fill="#006da8"
                                yAxisId="right"
                                stackId="a"
                            />

                            <Line
                                dataKey="result_ratio"
                                stroke="#1e9533"
                                fill="#1e9533"
                                yAxisId="left"
                                dot={{
                                    stroke: '#1e9533',
                                    fill: '#1e9533',
                                }}
                                name="Ratio"
                            />
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                height={36}
                                iconType="square"
                                iconSize={10}
                                formatter={returnFormattedLegendLabel}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={[]}>
                            <XAxis ticks={['No Data']}>
                                <Label
                                    value="No Data"
                                    offset={-140}
                                    position="insideTop"
                                    style={{
                                        fontSize: '14px',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                yAxisId="left"
                                tickFormatter={(value: any) => `${value}%`}
                                domain={[0, 100]}
                                label={{
                                    value: 'Ratio',
                                    angle: -90,
                                    position: 'insideLeft',
                                    dy: 20,
                                    fontSize: '12px',
                                }}
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                domain={[0, 100]}
                                orientation="right"
                                label={{
                                    value: 'Chargebacks & Transactions',
                                    angle: 90,
                                    position: 'insideRight',
                                    dy: 90,
                                    fontSize: '12px',
                                }}
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                )
            ) : (
                <div className={'emp-loadingContainer'}>
                    <LoadingIndicator />
                </div>
            )}
        </div>
    )
}

export default ResultantKpiStackedBarChart
