import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { Amplify } from 'aws-amplify'
import { LicenseManager } from "ag-grid-enterprise"
import {
    ActiveMerchantProvider,
    CaseStatusPatchContext,
    ErrorBoundary,
    IdleUserBoundary,
    MainLayoutModal,
} from 'components'
// @ts-ignore
import { createBrowserHistory } from 'history'
import React from 'react'
import { Router } from 'react-router-dom'
import theme from 'theme'
import setupAxios from './api/BaseApiClient'
import awsconfig from './aws-exports'
import { AuthedUserProvider } from './context/AuthedUser/AuthedUserContext'
import Routes from './routes/Routes'
import './App.scss'
import { FeatureToggleProvider } from 'context/FeatureToggles/FeatureToggleContext'
import featuresconfig from 'features-exports'
import '../src/assets/icomoon/style.css'
import '../src/assets/fontFamily/worldpay/style.css'
import '../src/assets/fontAwesome/css/all.css'
import '../src/assets/fontFamily/googleFonts/style.css'
import { SWRConfig } from 'swr'
import { UiStateContextProvider } from 'context/UiState/UiStateContext'
import { useUiState } from 'hooks/cb-api/useUiState'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Configure amplify from export settings
// Also check if SAML client ID present and swap out value of aws_user_pools_web_client_id when applicable.
Amplify.configure({
    ...awsconfig,
    aws_user_pools_web_client_id: awsconfig?.saml_web_client_id?.length
        ? awsconfig.saml_web_client_id
        : awsconfig.aws_user_pools_web_client_id
})

// This is needed to configure all default settings like authorization.
setupAxios()

const browserHistory = createBrowserHistory()

// Apply our AG-Grid license key.
LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-075303}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Glogal_eTrading,_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_January_2026}____[v3]_[01]_MTc2ODYwODAwMDAwMA==5a28b2eb673731ded11b6fd0082ca0bc'
)

function App() {
    const uiState = useUiState()

    return <>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ErrorBoundary area="App">
                    <FeatureToggleProvider features={featuresconfig}>
                        <Router history={browserHistory}>
                            <AuthedUserProvider>
                                <UiStateContextProvider value={uiState}>
                                    <IdleUserBoundary
                                        warningMinutes={14}
                                        timeoutMinutes={15}
                                    >
                                        <ActiveMerchantProvider>
                                            <CaseStatusPatchContext>
                                                <SWRConfig>
                                                    <MainLayoutModal />
                                                    <Routes />
                                                </SWRConfig>
                                            </CaseStatusPatchContext>
                                        </ActiveMerchantProvider>
                                    </IdleUserBoundary>
                                </UiStateContextProvider>
                            </AuthedUserProvider>
                        </Router>
                    </FeatureToggleProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    </>;
}

export default App
