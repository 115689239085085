import { useEffect, useState } from 'react'
import axios from 'axios'

export enum CaseFilters {
    Primary = 1,
    Secondary = 2,
    RFIS = 9,
}

export const useCaseFilterValue = (
    defaultPayload: { [key: string]: any },
    value: CaseFilters | null,
    selectedTabFilterGroup: string | null,
    isFraud: number | null = null,
    dayValue: number | null = null
) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [total, setTotal] = useState(0)

    if (defaultPayload) {
        Object.keys(defaultPayload).forEach((key) => {
            if (defaultPayload[key] === '') delete defaultPayload[key]
        })
    }

    const filterGroup =
        dayValue !== null
            ? selectedTabFilterGroup
                ? `primary_case_expire,${selectedTabFilterGroup}`
                : `primary_case_expire`
            : selectedTabFilterGroup

    useEffect(() => {
        if (defaultPayload.client_id) {
            setLoading(true)
            axios
                .get('cm/cases', {
                    params: {
                        ...defaultPayload,
                        start: undefined,
                        sort_by: undefined,
                        sort_order: undefined,
                        limit: 0,
                        is_fraud: isFraud,
                        cycle: value,
                        primary_case_due_days: dayValue,
                        filter_group: filterGroup,
                    },
                })
                .then((res) => setTotal(res.data.pagination.total))
                .catch((err) => setError(err))
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayValue, defaultPayload, selectedTabFilterGroup])

    return {
        loading,
        error,
        totalCases: total,
    }
}
