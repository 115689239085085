import React from 'react'
import { Alert } from 'components'
import {
    Button,
    CircularProgress,
    TextField,
    FormHelperText,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import idDirectory from './idAttributes'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { clientTheme } from 'theme-exports'

type TPasswordChangeInputs = {
    currentPassword: string
    newPassword: string
    confirmPassword: string
}

export interface IPasswordChangeSet {
    currentPassword: string
    newPassword: string
    confirmPassword: string
}

interface IPasswordChangeProps {
    className?: string
    id?: string
    onSubmit: (passwords: IPasswordChangeSet) => Promise<void>
    color?: 'primary' | 'secondary' | 'inherit'
    isFederated?: boolean
}

const minCharacters = 8 // Hardcoded for now
export const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required('New password cannot be blank.')
        .matches(
            /[^a-z]/,
            `Password needs a symbol, capital, and number, and must be at least ${minCharacters} characters.`
        )
        .trim()
        .min(
            minCharacters,
            `Password must be at least ${minCharacters} characters.`
        )
        .matches(/[^a-zA-Z]/, 'Password needs a symbol and number.')
        .matches(/[^a-z0-9]/, 'Password needs a symbol and capital.')
        .matches(/[^a-zA-Z0-9]/, 'Password needs a symbol.')
        .matches(/[A-Z]/, 'Password needs a capital.')
        .matches(/[0-9]/, 'Password needs a number.')
        .matches(/[a-z]/, 'Password needs a lowercase letter.'),
    confirmPassword: Yup.string()
        .required('Confirm password cannot be blank.')
        .trim()
        .min(
            minCharacters,
            `Password must be at least ${minCharacters} characters.`
        )
        .oneOf(
            [Yup.ref('newPassword')],
            'New Password and Confirm Password must match'
        ),
})

const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required('You must enter your current password.')
        .trim()
        .min(
            minCharacters,
            `Current password must be at least ${minCharacters} characters.`
        )
        .notOneOf(
            [Yup.ref('newPassword')],
            'Current password and new password cannot be the same.'
        ),
    ...passwordSchema.fields,
})

/**
 * Authed View component for self-changing of password
 */
export const PasswordChange = ({
    className,
    id,
    color = 'primary',
    onSubmit: handleOnSubmit,
    isFederated = false,
}: IPasswordChangeProps) => {
    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'FORM_FIELDS'
    )

    const {
        reset,
        trigger,
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isDirty },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TPasswordChangeInputs".
    } = useForm<any>({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        mode: 'onChange',
        resolver: yupResolver(changePasswordSchema),
    })

    const onSubmit: SubmitHandler<TPasswordChangeInputs> = (values) => {
        return handleOnSubmit({
            currentPassword: values.currentPassword.trim(),
            newPassword: values.newPassword.trim(),
            confirmPassword: values.confirmPassword.trim(),
        }).then(() => reset())
    }

    return (
        <section className={`${className}`} id={id}>
            <form className={className} onSubmit={handleSubmit(onSubmit)}>
                <Alert
                    icon={false}
                    severity="info"
                    className={'emp-passwordChange-rules'}
                    id={idDirectory.alertPolicy}
                >
                    <strong>Password Policy:</strong>
                    <ul>
                        <li>
                            Must be between {minCharacters} and 25 characters.
                        </li>
                        <li>Must contain at least 1 capital letter.</li>
                        <li>Must contain at least 1 lowercase letter.</li>
                        <li>
                            Must contain at least 1 special character (?.!,etc).
                        </li>
                        <li>Password must not contain username.</li>
                    </ul>
                </Alert>
                <div className={'emp-passwordChange-inputs'}>
                    <div>
                        <TextField
                            label="Current Password"
                            type="password"
                            id="password-change-current-password"
                            autoComplete="off"
                            {...register('currentPassword')}
                            onBlur={() => trigger()}
                            name="currentPassword"
                            variant={formVariantStyle}
                            InputLabelProps={{
                                htmlFor: 'password-change-current-password',
                            }}
                            disabled={isFederated}
                            fullWidth
                            sx={[
                                formVariantStyle === 'standard' && {
                                    '& .MuiInputBase-input': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                        borderBottom: '1px solid #84878E',
                                    },
                                    '& label': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                    },
                                },
                            ]}
                        />
                        {Boolean(errors?.currentPassword?.message) && (
                            <FormHelperText
                                className={'emp-passwordChange-errorText'}
                                error
                            >
                                {errors?.currentPassword?.message}
                            </FormHelperText>
                        )}
                    </div>
                    <div>
                        <TextField
                            label="New Password"
                            type="password"
                            autoComplete="off"
                            {...register('newPassword')}
                            onBlur={() => trigger()}
                            name="newPassword"
                            id="password-change-new-password"
                            variant={formVariantStyle}
                            InputLabelProps={{
                                htmlFor: 'password-change-new-password',
                            }}
                            disabled={isFederated}
                            fullWidth
                            sx={[
                                formVariantStyle === 'standard' && {
                                    '& .MuiInputBase-input': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                        borderBottom: '1px solid #84878E',
                                    },
                                    '& label': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                    },
                                },
                            ]}
                        />
                        {Boolean(errors?.newPassword?.message) && (
                            <FormHelperText
                                className={'emp-passwordChange-errorText'}
                                error
                            >
                                {errors?.newPassword?.message}
                            </FormHelperText>
                        )}
                    </div>
                    <div>
                        <TextField
                            label="Confirm Password"
                            type="password"
                            autoComplete="off"
                            {...register('confirmPassword')}
                            onBlur={() => trigger()}
                            name="confirmPassword"
                            id="password-change-confirm-password"
                            variant={formVariantStyle}
                            InputLabelProps={{
                                htmlFor: 'password-change-confirm-password',
                            }}
                            disabled={isFederated}
                            fullWidth
                            sx={[
                                formVariantStyle === 'standard' && {
                                    '& .MuiInputBase-input': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                        borderBottom: '1px solid #84878E',
                                    },
                                    '& label': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                    },
                                },
                            ]}
                        />
                        {Boolean(errors?.confirmPassword?.message) && (
                            <FormHelperText
                                className={'emp-passwordChange-errorText'}
                                error
                            >
                                {errors?.confirmPassword?.message}
                            </FormHelperText>
                        )}
                    </div>
                </div>

                <Button
                    id={idDirectory.btnChangePwd}
                    type="submit"
                    variant="contained"
                    color={color}
                    disabled={
                        isSubmitting || !isValid || !isDirty || isFederated
                    }
                    style={{ textTransform: 'uppercase' }}
                >
                    {isSubmitting ? (
                        <>
                            Submitting...{' '}
                            <CircularProgress
                                className={'emp-standardModal-circularProgress'}
                                color="secondary"
                            />
                        </>
                    ) : (
                        <>Change Password</>
                    )}
                </Button>
            </form>
        </section>
    )
}

export default PasswordChange
