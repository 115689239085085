import React, { useState } from 'react'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import {
    TextField,
    Typography,
    Button,
    CircularProgress,
    Box,
} from '@mui/material'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { passwordSchema } from 'components/PasswordChange/PasswordChange'
import { FormState } from './AuthedUserContext'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

type TChangePasswordInputs = {
    newPassword: string
    confirmPassword: string
}

interface IChangePasswordProps {
    formState: FormState
    authAWSUser: CognitoUser | null
    setAuthAWSUser: (user: CognitoUser | null) => void
    setFormState: (val: any) => void
    setSnackbar: (val: { isOpen: boolean; errorMessage: any }) => void
    isSubmit: boolean
    setIsSubmit: (val: boolean) => void
    handleOnChange: (e: any) => void
}

const ChangePassword = ({
    formState,
    authAWSUser,
    setAuthAWSUser,
    setFormState,
    setSnackbar,
    isSubmit,
    setIsSubmit,
    handleOnChange,
}: IChangePasswordProps) => {
    const { username, authCode, formType } = formState
    const [passwordPolicy, setPasswordPolicy] = useState({
        minChar: false,
        number: false,
        capitalChar: false,
        lowerCaseChar: false,
        specialChar: false,
        notUsername: false,
    })

    const handlePassword = async ({ newPassword }: { newPassword: string }) => {
        if (formType === 'forgotPassword' && !authCode.length)
            return setSnackbar({
                isOpen: true,
                errorMessage: 'Verification code cannot be empty.',
            })
        setIsSubmit(true)
        try {
            if (formType === 'passwordResetRequired') {
                await Auth.currentAuthenticatedUser()
                    .then((user) =>
                        Auth.changePassword(user, authCode, newPassword)
                            .then(() => {
                                axios.post(`/users/pass/updated`)
                                    .then((resp) => {
                                        if (resp.data?.code !== 401) {
                                            return window.location.reload()
                                        }
                                        return Promise.reject("An error occurred resetting password.")
                                    }).catch((e) => {
                                        return setSnackbar({
                                            isOpen: true,
                                            errorMessage: e,
                                        })
                                    })
                            })
                    )
                    .catch((e) => {
                        return setSnackbar({
                            isOpen: true,
                            errorMessage: `Problem changing password: ${e.message}`,
                        })
                    })
            }
            if (formType === 'forgotPassword') {
                await Auth.forgotPasswordSubmit(
                    username,
                    authCode,
                    newPassword.trim()
                )
                setFormState(() => ({
                    ...formState,
                    formType: 'signIn',
                    password: '',
                    authCode: '',
                }))
            }
            if (formType === 'confirmSignUp') {
                await Auth.completeNewPassword(authAWSUser, newPassword.trim())
                setAuthAWSUser(null)
                setFormState(() => ({
                    ...formState,
                    formType: 'signIn',
                    password: '',
                }))
            }
            setIsSubmit(false)
        } catch (err) {
            const e = err as any
            setSnackbar({ isOpen: true, errorMessage: e.message })
            setIsSubmit(false)
        } finally {
            reset()
        }
    }

    const {
        reset,
        register,
        handleSubmit,
        watch,
        setValue,
        trigger,
        formState: { errors, isSubmitting, isValid },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TChangePasswordInputs".
    } = useForm<any>({
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
        mode: 'onChange',
        resolver: yupResolver(passwordSchema),
    })

    const onSubmit: SubmitHandler<TChangePasswordInputs> = (values) => {
        return handlePassword({
            newPassword: values.newPassword,
        })
    }

    const handlePasswordValidation = (e: any) => {
        const currentValue = e.target.value.trim()
        const passwordPolicyUpdate = {
            minChar: false,
            number: false,
            capitalChar: false,
            lowerCaseChar: false,
            specialChar: false,
            notUsername: false,
        }
        if (currentValue.length > 7) {
            passwordPolicyUpdate.minChar = true
        }
        if (/[0-9]/.test(currentValue)) {
            passwordPolicyUpdate.number = true
        }
        if (/[A-Z]/.test(currentValue)) {
            passwordPolicyUpdate.capitalChar = true
        }
        if (/[a-z]/.test(currentValue)) {
            passwordPolicyUpdate.lowerCaseChar = true
        }
        if (/[^a-zA-Z0-9]/.test(currentValue)) {
            passwordPolicyUpdate.specialChar = true
        }
        if (currentValue !== username && currentValue?.length) {
            passwordPolicyUpdate.notUsername = true
        }
        setPasswordPolicy(() => passwordPolicyUpdate)
    }

    const handleOnChangeValue = (
        key: 'newPassword' | 'confirmPassword',
        value: string
    ) => {
        setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    const passwordsMatch =
        formType === 'passwordResetRequired'
        && authCode.length
        && (watch('newPassword') === authCode) ? true : false

    return (
        <form onSubmit={handleSubmit(onSubmit)} id={'changePassword'}>
            <div
                className={'emp-container'}
                id={idDirectory.changePassword.divContainer}
            >
                <div>
                    <div
                        className={'emp-policyMessaging'}
                        id={idDirectory.changePassword.divPolicyMsg}
                    >
                        <h3>Password Policy:</h3>
                        <ul>
                            <li
                                className={
                                    passwordPolicy.minChar
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Must be between 8 and 25 characters.
                                {passwordPolicy.minChar && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                            <li
                                className={
                                    passwordPolicy.number
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Must contain at least 1 number.
                                {passwordPolicy.number && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                            <li
                                className={
                                    passwordPolicy.capitalChar
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Must contain at least 1 capital letter.
                                {passwordPolicy.capitalChar && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                            <li
                                className={
                                    passwordPolicy.lowerCaseChar
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Must contain at least 1 lowercase letter.
                                {passwordPolicy.lowerCaseChar && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                            <li
                                className={
                                    passwordPolicy.specialChar
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Must contain at least 1 special character (?, !,
                                etc.).
                                {passwordPolicy.specialChar && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                            <li
                                className={
                                    passwordPolicy.notUsername
                                        ? 'emp-validPassword'
                                        : 'emp-notValidPassword'
                                }
                            >
                                Password must not contain username.
                                {passwordPolicy.notUsername && (
                                    <span>
                                        {'  '}
                                        <Icon icon={faCheck} />
                                    </span>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className={'emp-spacer'}></div>
                    <div className={'emp-banner'}>
                        {formType === 'passwordResetRequired'
                            ? `You need to update your password.`
                            : 'You need to create a new password.'}
                    </div>
                </div>

                <div
                    className={'emp-resetContainer'}
                    id={idDirectory.changePassword.divResetContainer}
                >
                    <div className={'emp-inputs'}>
                        {['forgotPassword', 'passwordResetRequired'].includes(
                            formType
                        ) && (
                            <>
                                <Typography
                                    variant={'h6'}
                                    className={'emp-textInput'}
                                >
                                    {formType === 'passwordResetRequired'
                                        ? `Current Password`
                                        : 'Verification Code'}
                                </Typography>
                                <Box
                                    className={'emp-textField '}
                                    sx={{
                                        '& .MuiInput-underline:after': {
                                            border: `1px solid ${clientTheme.textField}`,
                                        },
                                    }}
                                >
                                    <TextField
                                        autoComplete="password"
                                        type={
                                            formType === 'passwordResetRequired'
                                                ? 'password'
                                                : 'text'
                                        }
                                        name="authCode"
                                        variant="standard"
                                        onChange={handleOnChange}
                                        value={authCode}
                                        error={passwordsMatch}
                                        helperText={passwordsMatch ? `Current password and new password cannot be the same.` : ''}
                                        fullWidth
                                        id={
                                            idDirectory.changePassword
                                                .inputPassword
                                        }
                                    />
                                </Box>
                            </>
                        )}
                        <Typography variant={'h6'} className={'emp-textInput'}>
                            New Password
                        </Typography>
                        <Box
                            className={'emp-textField '}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    border: `1px solid ${clientTheme.textField}`,
                                },
                            }}
                        >
                            <TextField
                                autoComplete="new-password"
                                id={idDirectory.changePassword.inputNewPassword}
                                type="password"
                                name="newPassword"
                                variant="standard"
                                onChange={(e) => {
                                    handleOnChangeValue(
                                        'newPassword',
                                        e.target.value
                                    )
                                    handlePasswordValidation(e)
                                }}
                                onBlur={() => trigger()}
                                value={watch('newPassword')}
                                error={Boolean(errors.newPassword?.message)}
                                helperText={errors.newPassword?.message}
                                InputLabelProps={{
                                    htmlFor: 'new-password-change',
                                }}
                                fullWidth
                            />
                        </Box>
                        <Typography variant={'h6'} className={'emp-textInput'}>
                            Confirm Password
                        </Typography>
                        <Box
                            className={'emp-textField '}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    border: `1px solid ${clientTheme.textField}`,
                                },
                            }}
                        >
                            <TextField
                                autoComplete="confirm-password"
                                id={
                                    idDirectory.changePassword
                                        .inputConfirmPassword
                                }
                                type="password"
                                {...register('confirmPassword')}
                                onBlur={() => trigger()}
                                name="confirmPassword"
                                variant="standard"
                                error={Boolean(errors.confirmPassword?.message)}
                                helperText={errors.confirmPassword?.message}
                                InputLabelProps={{
                                    htmlFor: 'confirm-password-change',
                                }}
                                fullWidth
                            />
                        </Box>
                    </div>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={'emp-btn'}
                        type="submit"
                        disabled={
                            isSubmitting ||
                            passwordsMatch ||
                            !isValid ||
                            ([
                                'forgotPassword',
                                'passwordResetRequired',
                            ].includes(formType) &&
                                !authCode)
                        }
                        fullWidth
                        id={idDirectory.changePassword.btnCreate}
                    >
                        {isSubmitting || isSubmit ? (
                            <CircularProgress
                                className={'emp-circularProgress'}
                                color="secondary"
                            />
                        ) : (
                            'Create'
                        )}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default ChangePassword
