import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { format } from 'date-fns'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

interface VisaCEVMConfig {
    caseId: number
    open: boolean
    setCompellingEvidenceModalOpen: (open: boolean, closeRepresentment?: boolean) => void
}

export const merchandiseOrServicesValues = [
    {
        id: 'Merchandise',
        value: 'Merchandise',
        name: 'Merchandise'
    },
    {
        id: 'Services',
        value: 'Services',
        name: 'Services'
    },
]

export const deliveryStatuses = [
    {
        id: 'NS',
        value: 'NS',
        name: 'Not Shipped'
    },
    {
        id: 'B',
        value: 'B',
        name: 'Backordered'
    },
    {
        id: 'IT',
        value: 'IT',
        name: 'In transit'
    },
    {
        id: 'PS',
        value: 'PS',
        name: 'Partial Shipped'
    },
    {
        id: 'S',
        value: 'S',
        name: 'Shipped'
    },
    {
        id: 'C',
        value: 'C',
        name: 'Cancelled'
    },
    {
        id: 'SE',
        value: 'SE',
        name: 'Shipping Exception'
    },
    {
        id: 'P',
        value: 'P',
        name: 'Picked Up by customer'
    },
    {
        id: 'O',
        value: 'O',
        name: 'Other'
    },
    {
        id: 'D',
        value: 'D',
        name: 'Delivered'
    },
]

const primaryButtonTextValues: {[idx: number]: string} = {
    1: 'Add Details',
    2: 'Add Transaction',
    3: 'Add Transaction',
    4: 'Save and Continue',
    5: 'Complete'
}

export const useDebounce = (callback: (arg0: any) => void, delay: number) => {
    const timeoutRef = useRef<any>(null);
  
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])
  
    const debouncedCallback = (...args: any[]) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
  
        timeoutRef.current = setTimeout(() => {
            // @ts-ignore-next-line
            callback(...args)
        }, delay)
    }
  
    return debouncedCallback;
}

interface VisaCEVM {
    caseDetails: any
    isSubmitting: boolean
    isCompleted: boolean
    submitDisabled: boolean
    setSubmitDisabled: (value: boolean) => void
    currencies: any
    countries: any
    matchingTransactionDetails: any
    undisputedCases: any
    alertSnackbarOpen: boolean
    shippingInfo: any
    submitRemedy: () => void
    handlePrimaryButtonClick: () => void
    handleBackButtonClick: () => void
    handlelMatchingTransactionDetails: (values: any, isValid: boolean) => void
    addUndisputedCases: (value: any, type: string, formStep: number, isAdd?: boolean) => boolean
    setShippingInfo: (values: any) => void
    setCaseDetailValues: (txnId: any, values: any, isValid: any) => void
    deleteUndisputedCases: (txnId: string) => void
    alertSnackbarProps: any
    setAlertSnackbarOpen: (value: boolean) => void
    formStep: number
    primaryButtonText: string
    isCompleteEnabled: boolean
    setIsCompleteEnabled: (val: boolean) => void
    sendNewRelicEvent: (name: string, action: string, message?: string, overrideConfig?: boolean) => void
}

const useVisaCE = ({
    caseId,
    open = false,
    setCompellingEvidenceModalOpen,
}: VisaCEVMConfig): VisaCEVM => {
    const { userStackConfig } = useAuthedUser()

    const trackInNewRelic = userStackConfig?.enable_new_relic_tracking ?? false

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isCompleteEnabled, setIsCompleteEnabled] = useState<boolean>(false)
    const [isCompleted, setIsCompleted] = useState<boolean>(false)
    const [currencies, setCurrencies] = useState<any>([{}])
    const [countries, setCountries] = useState<any>([{}])
    const [caseDetails, setCaseDetails] = useState<any>(undefined)
    const [matchingTransactionDetails, setMatchingTransactionDetails] = useState<any>({isValid: false})
    const [undisputedCases, setUndisputedCases] = useState<any[]>([])
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [shippingInfo, setShippingInfo] = useState<any>({
        shipping_delivery_status: '',
        tracking_information: '',
        merchandise_or_services: '',
        merchandise_or_services_description: '',
        isValid: false
    })
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [formStep, setFormStep] = useState<number>(1)
    const [primaryButtonText, setPrimaryButtonText] = useState<string>(primaryButtonTextValues[1])

    const formatDetailValues = (values: any, isDisputed?: boolean) => {
        const deliveryAddressDetails = matchingTransactionDetails.address ? {
            address: matchingTransactionDetails.address,
            ...(matchingTransactionDetails.address2 && {address2: matchingTransactionDetails.address2}),
            city: matchingTransactionDetails.city,
            state_region: matchingTransactionDetails.state_region,
            postal_code: matchingTransactionDetails.postal_code,
            country: matchingTransactionDetails.country,
        } : undefined

        // We determine whether we're sending ARN or Transaction ID, by length of this field.
        let arn_value = null
        let trans_id_value = null
        if (!isDisputed) {
            const value = values.historical_arn_or_transaction_id
            const sharedValueLength = value.length
            if (sharedValueLength >= 23 && sharedValueLength <= 24) {
                arn_value = value
            } else {
                trans_id_value = value
            }
        }

        const formattedValues = {
            ...(!isDisputed && values.historical_tran_amount && {
                historical_tran_amount: (Math.round(values.historical_tran_amount * 100) / 100).toFixed(2)
            }),
            ...(!isDisputed && values.historical_tran_currency && {historical_tran_currency: +values.historical_tran_currency}),
            ...(!isDisputed && values.historical_tran_date && {
                historical_tran_date: format(new Date(`${values.historical_tran_date} 00:00:00`), `yyyy-LL-dd'T'00:00:00+00:00`)
            }),
            ...(!isDisputed && {historical_transaction_id: trans_id_value}),
            ...(!isDisputed && {historical_arn: arn_value}),
            ...(!isDisputed && values.historical_order_id && {order_id: values.historical_order_id}),
            ...(!isDisputed && values.merchandise_or_services && {merchandise_or_services: values.merchandise_or_services}),
            ...(!isDisputed && values.merchandise_or_services_description && {merchandise_or_services_desc: values.merchandise_or_services_description}),
            ...(!isDisputed && values.shipping_delivery_status && {shipping_delivery_status: values.shipping_delivery_status}),
            ...(!isDisputed && values.tracking_information && {tracking_information: values.tracking_information}),
            ...(matchingTransactionDetails.device_id_details && {device_id_details: matchingTransactionDetails.device_id_details}),
            ...(matchingTransactionDetails.device_fingerprinting_details && {device_fingerprinting_details: matchingTransactionDetails.device_fingerprinting_details}),
            ...(matchingTransactionDetails.device_type_details && {device_type_details: matchingTransactionDetails.device_type_details}),
            ...(matchingTransactionDetails.ip_address_details && {ip_address_details: matchingTransactionDetails.ip_address_details}),
            ...(matchingTransactionDetails.customer_account_login_id_details && {customer_account_login_id_details: matchingTransactionDetails.customer_account_login_id_details}),
            ...(deliveryAddressDetails && {delivery_address_details: deliveryAddressDetails}),
        }

        return formattedValues
    }

    const errorLabelMap = [
        {
            id: 'historicalArn',
            value: 'ARN'
        },
        {
            id: 'historicalTranAmount',
            value: 'TXN Amount'
        },
        {
            id: 'historicalTranCurrency',
            value: 'TXN Currency'
        },
        {
            id: 'historicalTranDate',
            value: 'TXN Date'
        },
        {
            id: 'historicalTransactionId',
            value: 'TXN Id'
        },
        {
            id: 'merchandiseOrServicesDesc',
            value: 'Merchandise or Services Description'
        },
        {
            id: 'deviceIdDetails',
            value: 'Device ID Details'
        },
        {
            id: 'deviceFingerprintingDetails',
            value: 'Device Fingerprint Details'
        },
        {
            id: 'ipAddressDetails',
            value: 'IP Address Details'
        },
        {
            id: 'customerAccountLoginIdDetails',
            value: 'Customer Account Login'
        },
        {
            id: 'deliveryAddressDetails.address',
            value: 'Address'
        },
        {
            id: 'deliveryAddressDetails.address2',
            value: 'Address 2'
        },
        {
            id: 'deliveryAddressDetails.city',
            value: 'City'
        },
        {
            id: 'deliveryAddressDetails.stateRegion',
            value: 'State/Region'
        },
        {
            id: 'deliveryAddressDetails.postalCode',
            value: 'Postal Code'
        },
        {
            id: 'deliveryAddressDetails.country',
            value: 'Country'
        }
    ]

    const getOrdinal = (value: number) => {
        let ord = 'th'

        if (value % 10 === 1 && value % 100 !== 11) {
            ord = 'st'
        } else if (value % 10 === 2 && value % 100 !== 12) {
            ord = 'nd'
        } else if (value % 10 === 3 && value % 100 !== 13) {
            ord = 'rd'
        }

        return ord
    }

    const handleStepChangeNewRelic = (step: number) => {
        let message = ''
        switch (step) {
            case 1: 
                message = 'Entered Transaction Info Step'
                break
            case 2: 
                message = 'Entered Undisputed Transaction 1 Step'
                break
            case 3: 
                message = 'Entered Undisputed Transaction 2 Step'
                break
            case 4: 
                message = 'Entered Matching Evidence Step'
                break
            case 5: 
                message = 'Entered Confirmation Step'
                break
            default:
                break
        }

        sendNewRelicEvent('onClick', message)
    }

    const handlePrimaryButtonClick = () => {
        if (formStep === 5) {
            sendNewRelicEvent('onClick', 'Submitted Form')
            return submitRemedy()
        }
        setFormStep(formStep + 1)
        handleStepChangeNewRelic(formStep + 1)
        setPrimaryButtonText(primaryButtonTextValues[formStep + 1])
    }

    const handleBackButtonClick = () => {
        setPrimaryButtonText(primaryButtonTextValues[formStep - 1])
        if (formStep > 1) {
            handleStepChangeNewRelic(formStep - 1)
            return setFormStep(formStep - 1)
        }
        setCompellingEvidenceModalOpen(false)
    }

    const handleVisaCEReset = () => {
        setUndisputedCases([])
        setCaseDetails([])
        setFormStep(1)
        setPrimaryButtonText(primaryButtonTextValues[1])
        setMatchingTransactionDetails([])
        setShippingInfo({
            delivery_status: '',
            tracking_number: '',
            merchandise_or_services: '',
            merchandise_or_services_description: '',
            isValid: false
        })
    }

    const createDocumentPayload = () => {
        // [TODO] - Maybe refactor some of this, once the dust settles.
        const values = {
            merchant_name: caseDetails.merchant_name,
            mid: caseDetails.mid,
            acquirer_case_number: caseDetails.case_no,
            cc_last_four: caseDetails.cc_last_four,
            disputed_merchandise_or_services_provided_date: caseDetails.date_trans,
            ...(matchingTransactionDetails.ip_address_details && {disputed_ip_address_details: matchingTransactionDetails.ip_address_details}),
            ...(matchingTransactionDetails.customer_account_login_id_details && {disputed_customer_account_login_id_details: matchingTransactionDetails.customer_account_login_id_details}),
            ...(matchingTransactionDetails.device_id_details && {disputed_device_id_details: matchingTransactionDetails.device_id_detail}),
            ...(matchingTransactionDetails.device_fingerprinting_details && {disputed_device_fingerprinting_details: matchingTransactionDetails.device_fingerprinting_details}),
            ...(matchingTransactionDetails.address && {disputed_shipping_address: matchingTransactionDetails.address}),
            ...(matchingTransactionDetails.address2 && {disputed_shipping_address: matchingTransactionDetails.address2}),
            ...(matchingTransactionDetails.city && {disputed_shipping_city: matchingTransactionDetails.city}),
            ...(matchingTransactionDetails.state_region && {disputed_shipping_state_region: matchingTransactionDetails.state_region}),
            ...(matchingTransactionDetails.postal_code && {disputed_shipping_postal_code: matchingTransactionDetails.postal_code}),
            ...(matchingTransactionDetails.country && {disputed_shipping_country: countries.find((obj: any) => obj.value === matchingTransactionDetails.country)?.name ?? ''}),
            disputed_historical_tran_amount: caseDetails.trans_amt.toString(),
            disputed_historical_tran_date: caseDetails.date_trans,
            // disputed_historical_transaction_id: caseDetails.case_no.toString(),
            disputed_historical_arn: caseDetails.arn,
            disputed_merchandise_or_services: shippingInfo.merchandise_or_services,
            disputed_delivery_status: deliveryStatuses.find((obj) => obj.id === shippingInfo.shipping_delivery_status)?.name ?? '',
            ...(shippingInfo.tracking_information && {disputed_tracking_information: shippingInfo.tracking_information}),
            disputed_merchandise_or_services_description: shippingInfo.merchandise_or_services_description,
            undisputed1_merchandise_or_services_provided_date: undisputedCases[0].historical_tran_date,
            ...(matchingTransactionDetails.ip_address_details && {undisputed1_ip_address_details: matchingTransactionDetails.ip_address_details}),
            ...(matchingTransactionDetails.customer_account_login_id_details && {undisputed1_customer_account_login_id_details: matchingTransactionDetails.customer_account_login_id_details}),
            ...(matchingTransactionDetails.device_id_details && {undisputed1_device_id_details: matchingTransactionDetails.device_id_detail}),
            ...(matchingTransactionDetails.device_fingerprinting_details && {undisputed1_device_fingerprinting_details: matchingTransactionDetails.device_fingerprinting_details}),
            ...(matchingTransactionDetails.address && {undisputed1_shipping_address: matchingTransactionDetails.address}),
            ...(matchingTransactionDetails.address2 && {undisputed1_shipping_address: matchingTransactionDetails.address2}),
            ...(matchingTransactionDetails.city && {undisputed1_shipping_city: matchingTransactionDetails.city}),
            ...(matchingTransactionDetails.state_region && {undisputed1_shipping_state_region: matchingTransactionDetails.state_region}),
            ...(matchingTransactionDetails.postal_code && {undisputed1_shipping_postal_code: matchingTransactionDetails.postal_code}),
            ...(matchingTransactionDetails.country && {undisputed1_shipping_country: countries.find((obj: any) => obj.value === matchingTransactionDetails.country)?.name ?? ''}),
            undisputed1_historical_tran_amount: (Math.round(undisputedCases[0].historical_tran_amount * 100) / 100).toFixed(2).toString(),
            undisputed1_historical_tran_date: undisputedCases[0].historical_tran_date,
            undisputed1_historical_arn_or_transaction_id: undisputedCases[0].historical_arn_or_transaction_id,
            ...(undisputedCases[0].historical_order_id && {undisputed1_historical_order_id: undisputedCases[0].historical_order_id}),
            undisputed1_merchandise_or_services: undisputedCases[0].merchandise_or_services,
            undisputed1_delivery_status: deliveryStatuses.find((obj) => obj.id === undisputedCases[0].shipping_delivery_status)?.name ?? '',
            ...(undisputedCases[0].tracking_information && {undisputed1_tracking_information: undisputedCases[0].tracking_information}),
            undisputed1_merchandise_or_services_description: undisputedCases[0].merchandise_or_services_description,
            undisputed2_merchandise_or_services_provided_date: undisputedCases[1].historical_tran_date,
            ...(matchingTransactionDetails.ip_address_details && {undisputed2_ip_address_details: matchingTransactionDetails.ip_address_details}),
            ...(matchingTransactionDetails.customer_account_login_id_details && {undisputed2_customer_account_login_id_details: matchingTransactionDetails.customer_account_login_id_details}),
            ...(matchingTransactionDetails.device_id_details && {undisputed2_device_id_details: matchingTransactionDetails.device_id_detail}),
            ...(matchingTransactionDetails.device_fingerprinting_details && {undisputed2_device_fingerprinting_details: matchingTransactionDetails.device_fingerprinting_details}),
            ...(matchingTransactionDetails.address && {undisputed2_shipping_address: matchingTransactionDetails.address}),
            ...(matchingTransactionDetails.address2 && {undisputed2_shipping_address: matchingTransactionDetails.address2}),
            ...(matchingTransactionDetails.city && {undisputed2_shipping_city: matchingTransactionDetails.city}),
            ...(matchingTransactionDetails.state_region && {undisputed2_shipping_state_region: matchingTransactionDetails.state_region}),
            ...(matchingTransactionDetails.postal_code && {undisputed2_shipping_postal_code: matchingTransactionDetails.postal_code}),
            ...(matchingTransactionDetails.country && {undisputed2_shipping_country: countries.find((obj: any) => obj.value === matchingTransactionDetails.country)?.name ?? ''}),
            undisputed2_historical_tran_amount: (Math.round(undisputedCases[1].historical_tran_amount * 100) / 100).toFixed(2).toString(),
            undisputed2_historical_tran_date: undisputedCases[1].historical_tran_date,
            undisputed2_historical_arn_or_transaction_id: undisputedCases[1].historical_arn_or_transaction_id,
            ...(undisputedCases[1].historical_order_id && {undisputed2_historical_order_id: undisputedCases[1].historical_order_id}),
            undisputed2_merchandise_or_services: undisputedCases[1].merchandise_or_services,
            undisputed2_delivery_status: deliveryStatuses.find((obj) => obj.id === undisputedCases[1].shipping_delivery_status)?.name ?? '',
            ...(undisputedCases[1].tracking_information && {undisputed2_tracking_information: undisputedCases[1].tracking_information}),
            undisputed2_merchandise_or_services_description: undisputedCases[1].merchandise_or_services_description
        }
        // For return, transform the above into the shape that tne endpoint expects.
        return [...Object.keys(values).map((key) => { return { name: key, value: values[key] } })]
    }

    const submitRemedy = async () => {
        setIsSubmitting(true)

        const formattedDateTrans = format(new Date(`${caseDetails.date_trans} 00:00:00`), `yyyy-LL-dd'T'00:00:00+00:00`)

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const values = {
            "acquirer_case_number": caseDetails.case_no,
            "merchandise_or_services_provided_date": formattedDateTrans,
            ...(shippingInfo.merchandise_or_services_description && {"merchandise_or_services_desc": shippingInfo.merchandise_or_services_description}),
            ...(shippingInfo.merchandise_or_services && {merchandise_or_services: shippingInfo.merchandise_or_services}),
            ...(shippingInfo.shipping_delivery_status && {shipping_delivery_status: shippingInfo.shipping_delivery_status}),
            ...(shippingInfo.tracking_information && {tracking_information: shippingInfo.tracking_information}),
            "disputed_transaction": formatDetailValues(undisputedCases[0], true),
            "undisputed_transactions": [
                ...undisputedCases.map((obj: any) => formatDetailValues(obj))
            ]
        } as any

        const docPayload = createDocumentPayload()
        const payloadToSend = {
            dl: values,
            doc: docPayload
        }

        // Leave this here, in case we have to demo without actual applicable cases.
        // const debug = true
        // if (debug) {
        //     console.log(JSON.stringify(payloadToSend, null, 2))
        //     return await new Promise((resolve, reject) => {
        //         setTimeout(resolve, 2000)
        //     }).then(() => {
        //         setIsSubmitting(false)
        //         setIsCompleted(true)
        //         return new Promise((resolve) => {
        //             setTimeout(resolve, 2500)
        //         }).then(() => {
        //             setCompellingEvidenceModalOpen(false, true)
        //         })
        //     }).catch(() => {
        //         setIsSubmitting(false)
        //     })
        // }

        await axios.post(`/cm/cases/${caseDetails.id}/ce`, payloadToSend)
            .then(() => {
                setIsCompleted(true)
                return new Promise((resolve) => {
                    setTimeout(resolve, 2000)
                }).then(() => {
                    sendNewRelicEvent('onSubmit', 'Form Submitted Successfully')
                    handleVisaCEReset()
                    setCompellingEvidenceModalOpen(false, true)
                })
            })
            .catch((e) => {
                const respData = e.response.data
                let errors = []
                let errorGroups: any[] = []

                sendNewRelicEvent('onSubmit', 'Form Submitted With Errors')

                try {
                    if (respData.data.violations) {
                        errors.push({
                            label: '',
                            text: respData.message,
                        })
                        respData.data.violations.forEach((obj: any) => {
                            const {
                                propertyPath,
                                title,
                            } = obj
                            const propertyField = propertyPath.slice(propertyPath.indexOf('.') + 1)
                            const propertyIndex = /(?<=\[).+?(?=\])/.exec(propertyPath)

                            if (!propertyField || !propertyIndex) return

                            const idx = +propertyIndex[0]
                            const label = errorLabelMap.find((obj: any) => propertyField === obj.id)

                            if (typeof errorGroups[idx] === 'undefined') {
                                errorGroups[idx] = [{
                                    label: (label && label.value) || propertyField,
                                    text: title
                                }]
                            } else {
                                errorGroups[idx].push({
                                    label: (label && label.value) || propertyField,
                                    text: title
                                })
                            }
                        })
                        errorGroups.forEach((obj: any, idx: number) => {
                            const idxPlusOne = idx + 1

                            errors.push({
                                label: '',
                                text: ' '
                            })
                            errors.push({
                                label: `-- Errors for ${idxPlusOne}${getOrdinal(idxPlusOne)} Undistputed Transaction --`,
                                text: ''
                            })
                            obj.forEach((obj: any) => {
                                errors.push({
                                    label: `${obj.label}:`,
                                    text: obj.text
                                })
                            })
                        })
                    } else {
                        errors.push({
                            label: '',
                            text: respData.message,
                        })
                    }
                } catch {
                    errors.push({
                        label: '',
                        title: respData.message
                    })
                }

                setAlertSnackbarProps({
                    title: 'Errors Submitting Remedy',
                    message: errors,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
            .finally(() => setIsSubmitting(false))
    }

    const handlelMatchingTransactionDetails = (values: any, isValid: boolean) => {
        setMatchingTransactionDetails({...values, isValid})
    }

    const deleteUndisputedCases = (txnId: string) => {
        const updatedCases = undisputedCases.filter((obj: any) => obj.historical_transaction_id !== txnId)
        setUndisputedCases(updatedCases)
        if (formStep === 3) setFormStep(formStep - 1)
        if (formStep >= 4) setFormStep(2)
    }

    const addUndisputedCases = (value: any, type: string, formStep: number, isAdd?: boolean) => {
        const foundCase = undisputedCases[formStep - 2]

        if (!foundCase) {
            // ** NOTE ** Leaving this in here, as I assume this is going to need to be re-enabled. 
            // const foundDuplicateTXN = undisputedCases.find((obj: any) => obj.historical_transaction_id === value.historical_transaction_id) ? true : false
            // if (foundDuplicateTXN) {
            //     setAlertSnackbarProps({
            //         title: 'Duplicate TXN ID',
            //         message: 'Please enter a unique TXN ID for this case.',
            //         intent: 'error'
            //     })
            //     setAlertSnackbarOpen(true)
            //     return false
            // }
            setUndisputedCases([...undisputedCases, {...value, type}])
            return true
        }

        let updatedUndisputedCases = undisputedCases
        updatedUndisputedCases[formStep - 2] = {...value, type}
        setUndisputedCases([
            ...updatedUndisputedCases
        ])

        return true
    }

    const sendNewRelicEvent = (name: string, action: string, message?: string, overrideConfig?: boolean) => {
        if (!trackInNewRelic && !overrideConfig) return

        const payloadToSend = {
            key: 'visa_ce',
            case: {
                id: caseId
            },
            event: {
                 name: name,
                 action: action,
                 message: message ?? ''
            }
        }

        axios.post(`/users/events/tracking`, payloadToSend)
    }

    useEffect(() => {
        sendNewRelicEvent('onChange', `step 5: Complete Checkbox Checked: [${isCompleteEnabled ? 'checked' : 'unchecked'}]`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCompleteEnabled])

    useEffect(() => {
        if (!open) return
   
        sendNewRelicEvent(
            'onClick',
            'Remedy Button Clicked',
            '',
            trackInNewRelic
        )
        
        axios
            .get(`/cm/gen/currencies?limit=999`)
            .then(({ data: { data }}) => {
                const filteredData = data.map((row: any) => {
                    return {
                        value: row.code,
                        name: row.currency,
                    }
                }).filter((obj: any) => obj.id !== 0)
                setCurrencies(filteredData)
            })
        axios
            .get(`/cm/gen/countries?limit=999`)
            .then(({ data: { data }}) => {
                const filteredData = data.map((row: any) => {
                    return {
                        value: row.number_code,
                        name: `${row.code} - ${row.name}`,
                    }
                }).filter((obj: any) => obj.id !== 0)
                setCountries(filteredData)
            })
        axios
            .get(`/cm/cases/${caseId}`)
            .then(({ data }) => {
                setCaseDetails(data)
            })
        return () => handleVisaCEReset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, caseId])

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
    const setCaseDetailValues = (txnId: any, values: any, isValid: any) => {
        let updatedCases = undisputedCases
        const foundCaseIdx = updatedCases.findIndex((obj) => obj.historical_transaction_id === txnId) 
        updatedCases[foundCaseIdx] = {
            ...updatedCases[foundCaseIdx],
            isValid,
            formVals: {
                ...values
            }
        }
        setUndisputedCases([...updatedCases])
    }

    useEffect(() => {
        if (formStep === 1) {
            return setSubmitDisabled(shippingInfo.isValid ? false : true)
        }
        if (formStep >= 2 && formStep <= 3) {
            return setSubmitDisabled(undisputedCases.length && undisputedCases[formStep - 2]?.isValid ? false : true)
        }
        if (formStep === 4) {
            return setSubmitDisabled(!matchingTransactionDetails?.isValid)
        }
        if (formStep === 5) {
            return setSubmitDisabled(!isCompleteEnabled)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undisputedCases, shippingInfo, formStep, isCompleteEnabled])

    return {
        caseDetails,
        isSubmitting,
        isCompleted,
        submitDisabled,
        setSubmitDisabled,
        currencies,
        countries,
        matchingTransactionDetails,
        undisputedCases,
        alertSnackbarOpen,
        shippingInfo,
        submitRemedy,
        handlePrimaryButtonClick,
        handleBackButtonClick,
        handlelMatchingTransactionDetails,
        addUndisputedCases,
        setShippingInfo,
        setCaseDetailValues,
        deleteUndisputedCases,
        alertSnackbarProps,
        setAlertSnackbarOpen,
        formStep,
        primaryButtonText,
        isCompleteEnabled,
        setIsCompleteEnabled,
        sendNewRelicEvent,
    }
}

export default useVisaCE