import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

interface obj {
    [key: string]: any
}
export class Tools {
    static copy() {}

    static generatePDF = (
        tableData: any[],
        columns: any[],
        fileName: string,
        enableAutoPrint: boolean
    ) => {
        if (!tableData) throw new Error('No table data provided.')
        const JSON_array = tableData.map((row, idx) => {
            let rowJSON: obj = {}
            columns.forEach((column) => {
                const key = column.accessor.includes('.')
                    ? column.accessor.split('.')[0]
                    : column.accessor
                if (typeof row[key] === 'object') {
                    if (column.accessor === 'merchant.business_name') {
                        rowJSON[key] = row['merchant']?.business_name ?? ''
                    } else if (column.accessor === 'flag') {
                        rowJSON[key] = row[key]?.comment ?? ''
                    } else if (column.accessor === 'assigned_user') {
                        rowJSON[key] = row[key]?.fname
                            ? `${row[key]?.fname} ${row[key]?.lname}`
                            : row[key] ?? ''
                    } else {
                        rowJSON[key] = row[key]?.name ?? ''
                    }
                } else {
                    if (column.accessor === 'is_guaranteed') {
                        rowJSON[key] = row[key] ? 'Yes' : 'No'
                    } else {
                        rowJSON[key] = row[key]
                    }
                }
            })
            rowJSON['index'] = idx + 1

            // Reason code messages are too lengthy and break PDF lib. So truncate to first 10 words.
            if (rowJSON.reason_code_message) {
                let reasonCodeMsgArr = rowJSON.reason_code_message.split(' ')
                rowJSON.reason_code_message = `${reasonCodeMsgArr.splice(
                    0,
                    10
                )}...`
            }

            return rowJSON
        })
        let properties = columns
            .map((column) => {
                const key = column.accessor.includes('.')
                    ? column.accessor.split('.')[0]
                    : column.accessor

                if (column.accessor === 'flag') {
                    return {
                        dataKey: key,
                        header: 'Flag Comment',
                    }
                }
                if (typeof column.Header === 'object') {
                    return { dataKey: key, header: column.Header.props.value }
                }
                if (typeof column.Header === 'string') {
                    return { dataKey: key, header: column.Header }
                }
                return undefined
            })
            .filter(
                (p) => p !== undefined && JSON_array[0][p.dataKey] !== undefined
            )
        properties.push({ dataKey: 'index', header: '#' })

        const doc = new jsPDF({
            orientation: 'landscape',
        })

        // @ts-ignore
        doc.autoTable({
            tableWidth: 'wrap',
            headStyles: { fillColor: [34, 49, 66] },
            styles: { cellPadding: 0.75, fontSize: 8 },
            horizontalPageBreak: true,
            horizontalPageBreakRepeat: 'index',
            body: JSON_array,
            columns: properties,
        })

        if (enableAutoPrint) {
            doc.setDocumentProperties({
                title: fileName,
            })
            const pdfBlob = doc.output('blob')
            const pdfUrl = URL.createObjectURL(pdfBlob)

            // Open the PDF in a new tab and trigger the print dialog automatically
            const printWindow = window.open(pdfUrl, '_blank')
            if (printWindow) {
                printWindow.onload = () => {
                    printWindow.print()
                }
            } else {
                throw new Error(
                    'Print window could not be opened. Please check browser settings.'
                )
            }
        } else {
            return doc.save(`${fileName}.pdf`)
        }
    }
}
