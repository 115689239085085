import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import {
    CognitoUserSession,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoAccessToken,
    CognitoUser,
} from "amazon-cognito-identity-js"
import logger from 'utils/logger'
import User from 'models/User'
import AuthCombinedApi from 'api/AuthCombinedApi'
import { Snackbar, Typography } from '@mui/material'
import {
    faExclamationTriangle,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import SignIn from './SignIn'
import ResetPassword from './ResetPassword'
import ChangePassword from './ChangePassword'
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage'
import { parse } from 'qs'
import { LoadingIndicator } from 'components'
import awsconfig from '../../aws-exports'
import { useHistory } from 'react-router'
import idDirectory from './idAttributes'

export interface IAuthedUserProviderState {
    loading: boolean
    user: User | undefined
    refreshAuthedUser?: () => void
    selectedUserId?: number
    setSelectedUserId?: (value: number) => void
    selectedUsername?: string
    setSelectedUsername?: (value: string) => void
    selectedPatchIDs?: string
    setSelectedPatchIDs?: (value: string) => void
    selectedPatchType?: string
    setSelectedPatchType?: (value: string) => void
    SSOTokenURL?: string
    setSSOTokenURL?: (value: string) => void
    hasClearedCaseStatus?: boolean
    setHasClearedCaseStatus?: (value: boolean) => void
    showNewDataGrid?: boolean
    setShowNewDataGrid?: (value: boolean) => void
    userStackConfig?: { [key: string]: any }
    setUserStackConfig?: (config: { [key: string]: any }) => void
}

interface SnackbarProps {
    isOpen: boolean
    errorMessage: string
}

interface AuthedUserProviderProps {
    children: React.ReactNode
}

export interface FormState {
    username: string
    password: string
    confirmPassword: string
    authCode: string
    formType: string
}

export const AuthedUserContext = React.createContext<IAuthedUserProviderState>({
    loading: true,
    user: undefined,
    refreshAuthedUser: () => {},
    selectedUserId: 0,
    setSelectedUserId: (value) => {},
    selectedUsername: '',
    setSelectedUsername: (value) => {},
    selectedPatchIDs: '',
    setSelectedPatchIDs: (value) => {},
    selectedPatchType: '',
    setSelectedPatchType: (value) => {},
    SSOTokenURL: '',
    setSSOTokenURL: (value) => {},
    hasClearedCaseStatus: false,
    setHasClearedCaseStatus: (value) => {},
    showNewDataGrid: false,
    setShowNewDataGrid: (value) => {},
    userStackConfig: {},
    setUserStackConfig: (config) => {},
})

export const fontStyle =
    '"Source Sans Pro",Arial,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,Arial,sans-serif'

const initialFormState = {
    username: '',
    password: '',
    confirmPassword: '',
    authCode: '',
    formType: 'signIn',
}

export const clearLocalStorage = () => {
    localStorage.removeItem('selectedUser')
    localStorage.removeItem('selectedUsername')
    localStorage.removeItem('selectedMerchant')
    localStorage.removeItem('selectedMerchantId')
    localStorage.removeItem('SAMLUserLoggedIn')
    localStorage.removeItem('isSAMLBackDoor')
    localStorage.removeItem('disabledMessageIds')
}

/**
 * Will give you access to a user model, and a loading boolean
 * usage: const { user, loading } = useAuthedUser()
 */
function AuthedUserProvider({ children }: AuthedUserProviderProps) {
    const queryString = parse(window.location.search.substring(1))
    const history = useHistory()
    const {
        sso_auth_key: SSOAuthKey,
        aws_user_pools_web_client_id: AWSUserPoolWebClientId,
        aws_project_region: AWSProjectRegion,
        saml_web_client_id: SAMLWebClientId,
        saml_custom_error_url: SAMLCustomErrorURL,
        saml_authorize_url: SAMLAuthorizeURL,
    } = awsconfig
    const [userState, setUserState] = useState<IAuthedUserProviderState>({
        loading: true,
        user: undefined,
    })
    const {
        inverted: invertedSignInLogo,
        enabled: enabledSignInLogo,
    } = useFeatureToggle('SIGN_IN_LOGO')
    const { LOGO_IMAGE, LOGO_TEXT } = useFeatureToggle('MAIN_TOPBAR')
    const [formState, setFormState] = useState<FormState>(initialFormState)
    const { formType } = formState
    const [snackbar, setSnackbar] = useState<SnackbarProps>({
        isOpen: false,
        errorMessage: '',
    })
    const [isSubmit, setIsSubmit] = useState(false)
    const [authAWSUser, setAuthAWSUser] = useState<CognitoUser| null>(null)

    const [SAMLUserLoggedIn, setSAMLUserLoggedIn] = useLocalStorage<number>(
        'SAMLUserLoggedIn',
        0
    )
    const [isSAMLBackDoor, setIsSAMLBackDoor] = useLocalStorage<number>(
        'isSAMLBackDoor',
        0
    )
    const [selectedUserId, setSelectedUserId] = useLocalStorage<number>(
        'selectedUser',
        0
    )
    const [selectedUsername, setSelectedUsername] = useLocalStorage<string>(
        'selectedUsername',
        ''
    )
    const [selectedPatchIDs, setSelectedPatchIDs] = useLocalStorage<number>(
        'selectedPatchIDs',
        localStorage['selectedPatchIDs'] || ''
    )
    const [selectedPatchType, setSelectedPatchType] = useLocalStorage<string>(
        'selectedPatchType',
        localStorage['selectedPatchType'] || ''
    )
    const [SSOTokenURL, setSSOTokenURL] = useLocalStorage<string>(
        'SSOTokenURL',
        ''
    )
    const [hasClearedCaseStatus, setHasClearedCaseStatus] = React.useState<boolean>(false)
    const [showNewDataGrid, setShowNewDataGrid] = React.useState<boolean>(false)
    const [userStackConfig, setUserStackConfig] = React.useState<{
        [key: string]: any
    }>({})

    // needed to handle status 200 "Errors"
    axios.interceptors.response?.use(
        (response) => {
            if (response.data?.code === 401) {
                checkUser(true)
            }
            if (
                response.headers['content-type'] === 'text/html' &&
                response.config.url === 'users/whoami'
            ) {
                Auth.signOut().then(() => {
                    clearLocalStorage()
                    if (SSOTokenURL?.length) {
                        const tempURL = SSOTokenURL
                        localStorage.removeItem('SSOTokenURL')
                        return document.location.href = tempURL
                    }
                    setFormState(() => ({
                        ...formState,
                        username: '',
                        password: '',
                        formType: 'signIn',
                    }))
                    setSnackbar({
                        isOpen: true,
                        errorMessage:
                            'There was an issue logging in. Please try again.',
                    })
                })
            }
            return response
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    const checkUser = async (isReset?: boolean) => {
        try {
            await Auth.currentAuthenticatedUser()
                .then(() => {
                    if (isReset) {
                        return setFormState(() => ({ ...formState, formType: 'passwordResetRequired' }))
                    }
                    setFormState(() => ({ ...formState, formType: 'signedIn' }))
                })
                .catch((e) => {
                    if(SAMLUserLoggedIn) localStorage.removeItem('SAMLUserLoggedIn')
                    setFormState(() => ({ ...formState, formType: 'signIn' }))
                })
        } catch (err) {}
    }

    const refreshAuthedUser = React.useCallback(() => {
        try {
            // eslint-disable-next-line no-unused-expressions
            AuthCombinedApi.authedUser()
                ?.then((combinedUser) => {
                    setUserState({
                        user: combinedUser,
                        loading: false,
                    })
                })
                .catch(() => {
                    logger('User is not authenticated.')
                })
        } catch (errC) {
            logger('Problem getting the authenticated user', errC)
        }

        // Ensures that axios.defaults.headers.common is defined before trying to set header.
        axios.defaults.headers.common = axios.defaults.headers.common || {}
        axios.defaults.headers.common['emp-imp-id'] = selectedUserId ?? 0

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserId])

    useEffect(() => {
        checkUser()
        // eslint-disable-next-line
    }, [])

    useEffect(refreshAuthedUser, [refreshAuthedUser, formType]) // Pull the user data once when context loads in or refresh if auth ui state changes

    const value = React.useMemo(
        () => ({
            ...userState,
            refreshAuthedUser: refreshAuthedUser,
            selectedUserId,
            setSelectedUserId,
            selectedUsername,
            setSelectedUsername,
            selectedPatchIDs,
            setSelectedPatchIDs,
            selectedPatchType,
            setSelectedPatchType,
            SSOTokenURL,
            setSSOTokenURL,
            hasClearedCaseStatus,
            setHasClearedCaseStatus,
            showNewDataGrid,
            setShowNewDataGrid,
            userStackConfig,
            setUserStackConfig,
        }),
        [
            userState,
            refreshAuthedUser,
            selectedUserId,
            setSelectedUserId,
            selectedUsername,
            setSelectedUsername,
            selectedPatchIDs,
            setSelectedPatchIDs,
            selectedPatchType,
            setSelectedPatchType,
            SSOTokenURL,
            setSSOTokenURL,
            hasClearedCaseStatus,
            setHasClearedCaseStatus,
            showNewDataGrid,
            setShowNewDataGrid,
            userStackConfig,
            setUserStackConfig,
        ]
    )

    const handleOnChange = (e: any) => {
        e.persist()
        setFormState(() => ({
            ...formState,
            [e.target.name]: e.target.value.trim(),
        }))
    }

    const [initiateLoginAttempt, setInitiateLoginAttempt] = useState<boolean>(false)
    const [SSOLoginSuccess, setSSOLoginSuccess] = useState<boolean>(false)
    const [SAMLLoginSuccess, setSAMLLoginSuccess] = useState<boolean>(false)

    const {
        host,
        hostname,
        protocol
     } = window.location
    const isSAMLApp = Boolean(SAMLWebClientId?.length)

    // SSO login handling.
    useEffect(() => {
        const getCognitoSessionFromTokens = async(
            IdToken: string,
            RefreshToken: string,
            AccessToken: string,
            isArtego: boolean = false,
            origin?: string
        ) => {
            const session = new CognitoUserSession({
                IdToken: new CognitoIdToken({
                    IdToken,
                }),
                RefreshToken: new CognitoRefreshToken({
                    RefreshToken,
                }),
                AccessToken: new CognitoAccessToken({
                    AccessToken,
                }),
            })

            try {
                const currentUser = (Auth as any).createCognitoUser(session.getIdToken().decodePayload()["cognito:username"])
                currentUser.setSignInUserSession(session)
                return await Auth.currentAuthenticatedUser()
            } catch(message) {
                if (isArtego) {
                    return window.location.assign(`${queryString.token_url}?artego_sso_token=${queryString.tokeartego_sso_tokenn_url}&error_code=0&error=${message}${origin ? '&origin=' + origin : ''}`)
                }
                setSnackbar({
                    isOpen: true,
                    errorMessage:
                        `Issue logging in: ${message}`,
                })
            }
        }

        const handleArtegoSSOLogin = async (
            artegoSSOToken: any,
            tokenURL: any,
            origin: any = ''
        ) => {
            const fixedErrorURL = tokenURL.replace(/\/+$/, '') + '/sso/error'

            // We'll need to use fetch, as we're assuming our axios calls will happen only when authenticated.
            await fetch("/api/users/auth/artego", {
                mode: 'cors',
                cache: 'no-cache',
                method: 'POST',
                headers: {
                    // This Authorization header has to be here, even though we're not passing a value.
                    "Authorization": "",
                    "x-api-key": encodeURI(SSOAuthKey),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    artego_sso_token: queryString.artego_sso_token,
                    token_url: queryString.token_url,
                    origin,
                }),
            }).then(async (res: any) => {
                const body = await res.json()
                const wasSuccess = res.status === 200 ?? false

                if (!wasSuccess) {
                    if (res.status === 401) {
                        return window.location.assign(`${fixedErrorURL}?artego_sso_token=${artegoSSOToken}&error_code=0&error=${res.statusText}${origin ? '&origin=' + origin : ''}`)
                    }

                    const {
                        artego_sso_token: ArtegoSSOToken,
                        error,
                        error_code: ErrorCode,
                    } = body

                    return window.location.assign(`${fixedErrorURL}?artego_sso_token=${ArtegoSSOToken}&error_code=${ErrorCode}&error=${error}${origin ? '&origin=' + origin : ''}`)
                }

                const {
                    access_token: SSOAccessToken,
                    id_token: SSOIdToken,
                    refresh_token: SSORefreshToken,
                } = body

                await getCognitoSessionFromTokens(SSOIdToken, SSORefreshToken, SSOAccessToken, true)
                    .then(() => {
                        setSSOLoginSuccess(true)
                        setSSOTokenURL(tokenURL)
                        setFormState(() => ({ ...formState, formType: 'signedIn' }))
                        window.location.assign('/')
                    })
                    .catch(({message}) => {
                        window.location.assign(`${fixedErrorURL}?artego_sso_token=${artegoSSOToken}&error_code=0&error=${message}${origin ? '&origin=' + origin : ''}`)
                    })
            }).catch(e => {
                window.location.assign(`${fixedErrorURL}?artego_sso_token=${artegoSSOToken}&error_code=0&error=${e.message}${origin ? '&origin=' + origin : ''}`)
            })
        }

        const handleSSOLogin = async(SSORefreshToken: any) => {
            await fetch(`https://cognito-idp.${AWSProjectRegion}.amazonaws.com/`, {
                headers: {
                    "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                    "Content-Type": "application/x-amz-json-1.1",
                },
                mode: 'cors',
                cache: 'no-cache',
                method: 'POST',
                body: JSON.stringify({
                    ClientId: AWSUserPoolWebClientId,
                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                    AuthParameters: {
                        REFRESH_TOKEN: SSORefreshToken,
                    }
                }),
            }).then( async(res) => {
                await res.json()
                    .then(async (data) => {
                        const {
                            AccessToken: SSOAccessToken,
                            IdToken: SSOIdToken
                        } = data.AuthenticationResult

                        await getCognitoSessionFromTokens(SSOIdToken, SSORefreshToken, SSOAccessToken)
                            .then(() => {
                                setFormState(() => ({ ...formState, formType: 'signedIn' }))
                                window.location.assign('/')
                            })
                            .catch(({message}) => {
                                setSnackbar({
                                    isOpen: true,
                                    errorMessage:
                                        `Issue logging in: ${message}`,
                                })
                                history.replace({
                                    search: '',
                                })
                            })
                            .finally(() => setSSOLoginSuccess(true))
                    })
                    .catch((message) => {
                        setSSOLoginSuccess(true)
                        setSnackbar({
                            isOpen: true,
                            errorMessage:
                                `Issue logging in: ${message}`,
                        })
                        history.replace({
                            search: '',
                        })
                    })
            })
        }

        const handleSAMLLogin = async (samlCode: any) => {
            const paramsToSend
                = new URLSearchParams(`grant_type=authorization_code&code=${samlCode}&redirect_uri=${protocol}//${host}&client_id=${SAMLWebClientId}`)

            await fetch(`${protocol}//${host}/oauth2/token`, {
                mode: 'cors',
                cache: 'no-cache',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: paramsToSend
            }).then(async (res: any) => {
                if (res.status !== 200) throw new Error('Error getting tokens.')
                const body = await res.json()
                const {
                    access_token: accessToken,
                    id_token: idToken,
                    refresh_token: refreshToken 
                } = body

                await getCognitoSessionFromTokens(idToken, refreshToken, accessToken)
                    .then(async() => {
                        await fetch('/api/users/whoami', {
                            mode: 'cors',
                            cache: 'no-cache',
                            method: 'POST',
                            headers: {
                                'Authorization': idToken
                            }
                        }).then(async() => {
                            setSAMLUserLoggedIn(1)
                            setSAMLLoginSuccess(true)
                            setFormState(() => ({ ...formState, formType: 'signedIn' }))
                            window.location.assign('/')
                        }).catch(() => {
                            window.location.href = SAMLCustomErrorURL
                        })
                    })
                    .catch(() => {
                        window.location.href = SAMLCustomErrorURL
                    })
                    .finally(() => setSSOLoginSuccess(true))
            }).catch(() => {
                window.location.href = SAMLCustomErrorURL
            })
        }

        const {
            artego_sso_token: artegoSSOToken,
            token_url: tokenURL,
            refreshToken: SSORefreshToken,
            code: samlCode,
            saml_back_door: SAMLBackDoor,
            saml_back_door_logout: SAMLBackDoorLogout,
            origin,
        } = queryString

        if (SAMLBackDoor) setIsSAMLBackDoor(1)

        if (isSAMLApp && !samlCode && !Boolean(SAMLUserLoggedIn) && !SAMLBackDoor && !isSAMLBackDoor && !SAMLBackDoorLogout) {
            // const pathAndQSWithPlaceholders = `/oauth2/authorize?identity_provider={SAMLIdentityProvider}&redirect_uri={redirectURI}&response_type=CODE&client_id={SAMLWebClientId}&scope=openid+profile`
            // const directTrafficRedirectURL = `${protocol}//${host}${pathAndQSWithPlaceholders}`
            //     .replace('{SAMLIdentityProvider}', SAMLIdentityProvider)
            //     .replace('{SAMLWebClientId}', SAMLWebClientId)
            //     .replace('{redirectURI}', `${protocol}//${host}`)
            window.location.href = SAMLAuthorizeURL
        }

        // SAML Login
        if (samlCode && isSAMLApp && !initiateLoginAttempt && (!SAMLBackDoor && !isSAMLBackDoor && !SAMLBackDoorLogout)) {
            setInitiateLoginAttempt(true)
            handleSAMLLogin(samlCode)
        }

        // Artego SSO
        if (artegoSSOToken && tokenURL && !initiateLoginAttempt) {
            setInitiateLoginAttempt(true)
            handleArtegoSSOLogin(artegoSSOToken, tokenURL, origin)
        }

        // General SSO - Currently used for CPO -> EMP Migration
        if (SSORefreshToken && !initiateLoginAttempt) {
            setInitiateLoginAttempt(true)
            handleSSOLogin(SSORefreshToken)
        }
    }, [
        queryString,
        initiateLoginAttempt,
        SSOAuthKey,
        formState,
        setSSOTokenURL,
        history,
        AWSProjectRegion,
        AWSUserPoolWebClientId,
        host,
        hostname,
        protocol,
        isSAMLApp,
        SAMLUserLoggedIn,
        setSAMLUserLoggedIn,
        SAMLWebClientId,
        SAMLAuthorizeURL,
        isSAMLBackDoor,
        setIsSAMLBackDoor,
        SAMLCustomErrorURL
    ])

    if (queryString.user_pass_reset && formType !== 'forgotPassword') {
        const qs2 = parse(window.location.search.substring(1), {
            decoder: (val: any) => val
        })

        const {
            user_pass_reset: username
        } = qs2

        setFormState(() => ({
            ...formState,
            username: `${username}`,
            formType: 'forgotPassword',
        }))

        if (formState.username) {
            window.history.pushState({}, document.title, window.location.pathname)
        }
    }

    if (queryString.force_pass_reset && formType !== 'resetPassword') {
        // Do NOT use queryString. We need to do a special decoder here, because
        // qs.parse transforms + to space " ", and a couple of other undesirable things here.
        const qs2 = parse(window.location.search.substring(1), {
            decoder: (val: any) => val
        })
        const username = qs2.force_pass_reset

        setFormState(() => ({
            ...formState,
            username: `${username}`,
            formType: 'resetPassword',
        }))

        if (formState.username) {
            window.history.pushState({}, document.title, window.location.pathname)
        }
    }

    if (queryString.code || (
        isSAMLApp &&
        !SAMLLoginSuccess &&
        !SAMLUserLoggedIn &&
        !isSAMLBackDoor &&
        !queryString.saml_back_door &&
        !queryString.saml_back_door_logout)
    ) {
        return (
            <div style={{padding: 16}}>
                <LoadingIndicator iconOnly />
                <span style={{paddingLeft: 4}}>Please wait while we log you in...</span>
            </div>
        )
    }

    // SSO Login? Wait here.
    if ((queryString.artego_sso_token && queryString.token_url && !SSOLoginSuccess) || (queryString.refreshToken && !SSOLoginSuccess)) {
        return (
            <div style={{padding: 16}}>
                <LoadingIndicator iconOnly />
                <span style={{paddingLeft: 4}}>Please wait while we log you in...</span>
            </div>
        )
    }

    if (formType === 'signedIn' && userState.loading) {
        return (
            <div style={{padding: 16}}>
                <LoadingIndicator iconOnly />
                <span style={{ paddingLeft: 4, fontSize: '14px' }}>
                    Loading UI...
                </span>
            </div>
        )
    }

    return formType === 'signedIn' ? (
        // @ts-ignore
        <AuthedUserContext.Provider value={value}>
            {children}
        </AuthedUserContext.Provider>
    ) : (
        <div id={'authedUserContext'}>
            <div className={'emp-authContainer'}>
                <div className={'emp-authContainerMain'}>
                    <div
                        className={'emp-authContainerInner'}
                        id={idDirectory.authedUserContext.divAuthContainer}
                    >
                        {enabledSignInLogo ? (
                            <div
                                className={'emp-authContainerLogo'}
                                id={idDirectory.authedUserContext.divLogo}
                            >
                                {LOGO_IMAGE.enabled ? (
                                    <img
                                        // static files will be overwitten in the public dir at build time
                                        src={
                                            invertedSignInLogo
                                                ? process.env.PUBLIC_URL +
                                                  '/images/logos/brand-logo-lg-inverted-login.png'
                                                : process.env.PUBLIC_URL +
                                                  '/images/logos/brand-logo-lg-login.png'
                                        }
                                        alt="Client Portal"
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        className={'emp-authContainerLogoText'}
                                    >
                                        {LOGO_TEXT.logo_text}
                                    </Typography>
                                )}
                            </div>
                        ) : null}
                        {/* SIGN IN */}
                        {formType === 'signIn' && (
                            <SignIn
                                formState={formState}
                                setFormState={setFormState}
                                setSnackbar={setSnackbar}
                                isSubmit={isSubmit}
                                setIsSubmit={setIsSubmit}
                                handleOnChange={handleOnChange}
                                setAuthAWSUser={setAuthAWSUser}
                                setIsSAMLBackDoor={setIsSAMLBackDoor}
                                isSAMLBackDoorLogout={
                                    queryString.saml_back_door_logout
                                        ? true
                                        : false
                                }
                            />
                        )}
                        {/* RESET PASSWORD */}
                        {formType === 'resetPassword' && (
                            <ResetPassword
                                formState={formState}
                                setFormState={setFormState}
                                setSnackbar={setSnackbar}
                                isSubmit={isSubmit}
                                setIsSubmit={setIsSubmit}
                                handleOnChange={handleOnChange}
                            />
                        )}
                        {/* FORGOT PASSWORD or CONFIRM SIGNUP */}
                        {(formType === 'forgotPassword' ||
                            formType === 'passwordResetRequired' ||
                            formType === 'confirmSignUp') && (
                            <ChangePassword
                                formState={formState}
                                authAWSUser={authAWSUser}
                                setAuthAWSUser={setAuthAWSUser}
                                setFormState={setFormState}
                                setSnackbar={setSnackbar}
                                isSubmit={isSubmit}
                                setIsSubmit={setIsSubmit}
                                handleOnChange={handleOnChange}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbar.isOpen}
                onClose={() => setSnackbar({ ...snackbar, isOpen: false })}
                message={
                    <div className={'emp-snackbarContainer'}>
                        <div>
                            <span style={{ marginRight: '8px' }}>
                                <Icon icon={faExclamationTriangle} />
                            </span>
                            {snackbar.errorMessage}
                        </div>
                        <div>
                            <span className={'emp-timesIcon'}>
                                <Icon
                                    icon={faTimes}
                                    onClick={() =>
                                        setSnackbar({
                                            ...snackbar,
                                            isOpen: false,
                                        })
                                    }
                                />
                            </span>
                        </div>
                    </div>
                }
                className={'emp-snackbar'}
                id={idDirectory.authedUserContext.snackbarAlert}
            />
        </div>
    )
}

function useAuthedUser() {
    const context: IAuthedUserProviderState = React.useContext(
        AuthedUserContext
    )
    if (context === undefined) {
        throw new Error(
            'The contextual useAuthedUser must be used within a AuthedUserProvider'
        )
    }
    return context
}

export { AuthedUserProvider, useAuthedUser }