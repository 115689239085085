import React, { useState } from 'react'
import { Grid, CardContent, Typography, CardHeader } from '@mui/material'
import idDirectory from './idAttributes'
import 'views/views.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import {
    PieChart as RechartPieChart,
    Pie,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    Sector,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { clientTheme } from 'theme-exports'

const DEFAULT_COLOR_PALETTE = clientTheme.charts.pieCharts.defaultPieChart

/**
 * @name PieChart
 * @desc Wrapper for Rechart Pie Chart
 * @param chartTitle string
 * @param data array
 * @param loading boolean
 */
export const PieChart = ({
    chartTitle = '',
    data = [],
    loading = false,
    showLegend = true,
    colorPalette = DEFAULT_COLOR_PALETTE,
    width = 550,
    height = 380,
    showValueOnHover = false,
    testId,
    tooltipPercent = false,
}: {
    chartTitle?: string
    data: any[]
    loading?: boolean
    showLegend?: boolean
    colorPalette?: string[]
    height?: number | string
    width?: number | string
    showValueOnHover?: boolean
    testId?: string
    tooltipPercent?: boolean
}): React.ReactElement => {
    const [activeIndex, setActiveIndex] = useState<number>(1)

    // on mouse enter for pie chart mouseover
    const onMouseEnter = (_: any, index: number) => setActiveIndex(index)
    const onMouseExit = () => setActiveIndex(1)

    //custom active pie label shape
    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180
        const { cx, cy, midAngle, outerRadius, fill, percent } = props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const sx = cx + outerRadius * cos
        const sy = cy + outerRadius * sin
        const mx = cx + (outerRadius + 15) * cos
        const my = cy + (outerRadius + 15) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * 10
        const ey = my + (sin >= 0 ? +2 : -2)
        const textAnchor = cos >= 0 ? 'start' : 'end'

        return percent * 100 >= 1 ? (
            <g>
                <path
                    d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                    stroke={fill}
                    fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 3}
                    y={ey + (sin >= 0 ? +4 : +2)}
                    textAnchor={textAnchor}
                    fill="grey"
                    fontSize="11px"
                    transform={`rotate(${
                        ((cos >= 0 && sin >= 0) || (cos <= 0 && sin <= 0)
                            ? 1
                            : -1) * 12
                    }, ${ex}, ${ey})`}
                >
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        ) : null
    }

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-pieChart-tooltipContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td
                                    className={'emp-pieChart-tooltipTableRow '}
                                    style={{
                                        borderRight: '1px dotted #b3b3b3',
                                        textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="xs"
                                        icon={faSquare}
                                        style={{
                                            marginRight: '5px',
                                            color: payload[0].payload.fill,
                                        }}
                                    />
                                    {payload[0]?.payload?.tooltipName
                                        ? payload[0]?.payload?.tooltipName
                                        : payload[0].name}
                                </td>
                                <td
                                    className={'emp-pieChart-tooltipTableRow '}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {tooltipPercent
                                        ? `${payload[0].value.toFixed(2)}%`
                                        : payload[0].value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    //custom hover pie cell
    const CustomPieCellHover = (props: any) => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
        } = props
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        )
    }

    //Needed to align piecharts on Reports page
    const CustomLegend = () => {
        return <div style={{ height: 19 }}></div>
    }

    return (
        <Grid
            item
            xs={12}
            md={6}
            id={`${idDirectory.gridContainer}-${testId}`}
            className={'emp-pieChart-mainContainer'}
        >
            <CardHeader
                id={`${idDirectory.cardHeader}-${testId}`}
                className={'emp-pieChart-header'}
                title={
                    <>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ fontWeight: 600 }}
                            >
                                {chartTitle}
                            </Typography>
                        </Grid>
                    </>
                }
            />
            <CardContent
                className={'emp-pieChart-pieChartContent'}
                id={`${idDirectory.cardContent}-${testId}`}
            >
                {!loading ? (
                    <>
                        {data.length ? (
                            <ResponsiveContainer width="100%" height={height}>
                                <RechartPieChart>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={80}
                                        outerRadius={130}
                                        paddingAngle={0.5}
                                        dataKey="value"
                                        labelLine={false}
                                        label={renderActiveShape}
                                        activeIndex={activeIndex}
                                        activeShape={CustomPieCellHover}
                                        onMouseEnter={onMouseEnter}
                                        onMouseLeave={onMouseExit}
                                        isAnimationActive={false}
                                    >
                                        {data.map((entry, index) => {
                                            const name = entry.name
                                                .trim()
                                                .toLowerCase()
                                            return (
                                                <Cell
                                                    key={`cell-${index}-${entry.name}`}
                                                    fill={
                                                        [
                                                            'other',
                                                            'pending',
                                                        ].some((word) =>
                                                            name.startsWith(
                                                                word
                                                            )
                                                        )
                                                            ? 'grey'
                                                            : colorPalette[
                                                                  index %
                                                                      colorPalette.length
                                                              ]
                                                    }
                                                />
                                            )
                                        })}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                    {showLegend ? (
                                        <Legend
                                            verticalAlign="bottom"
                                            align="center"
                                            wrapperStyle={{
                                                fontSize:
                                                    clientTheme.typography.body1
                                                        .fontSize,
                                            }}
                                        />
                                    ) : (
                                        <Legend content={<CustomLegend />} />
                                    )}
                                </RechartPieChart>
                            </ResponsiveContainer>
                        ) : (
                            <div className={'emp-pieChart-noDataMessaging'}>
                                No Data
                            </div>
                        )}
                    </>
                ) : (
                    <div className={'emp-pieChart-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
            </CardContent>
        </Grid>
    )
}

export default PieChart
