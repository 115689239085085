import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import BarChart from 'components/BarChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByPlatform
 * @desc Render % of Chargebacks by Platform (Top 5) chart
 * @filterValues IReportsFilter
 */
const ByPlatform = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByPlatform(filterValues).then((data) => {
            setLoading(false)
            // removes the 'Others' data object from the chart data
            const top5Issuers = data.filter((bin: any) => bin.name !== 'Others')

            setData(top5Issuers)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BarChart
            chartTitle="% of Chargebacks by Platform (Top 5)"
            data={data}
            loading={loading}
            valueDataKey="value"
            barLineColor={clientTheme.charts.barCharts.byPlatformBarChart.fill}
            dataValuePercent
            showLegend={false}
            tooltipPercent
            //Remove if no showLegend
            // height={280}
        />
    )
}

export default ByPlatform
