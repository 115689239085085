import { useActiveMerchant } from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import CB from 'lib'
import { Currency, CardType, Source, MIDDescriptor } from 'lib/Generic'
import { ConfirmJob, JobParams, ScheduledJob } from 'lib/Process'
import { useMemo, useReducer, useState, useEffect } from 'react'
import { format } from 'date-fns'

export enum AlertsAutoRefundParam {
    AMOUNT = 'amount',
    CURRENCY = 'currency',
    SOURCE = 'source',
    CARD_TYPE = 'card_type',
    MID_DESCRIPTOR = 'mid_descriptor',
}

type ParamValue = string | { min: number; max: number }

export interface Param<V = any> {
    name: AlertsAutoRefundParam
    label: string
    value: V
    displayValue: string
}

export type Field = Partial<Param> & {
    name: AlertsAutoRefundParam
}

type ParamMap = {
    [AlertsAutoRefundParam.AMOUNT]: Param<{ min: number; max: number }>
    [AlertsAutoRefundParam.CURRENCY]: Param<string>
    [AlertsAutoRefundParam.SOURCE]: Param<string>
    [AlertsAutoRefundParam.CARD_TYPE]: Param<string>
    [AlertsAutoRefundParam.MID_DESCRIPTOR]: Param<string>
}

type AddParam = (name: AlertsAutoRefundParam, value: ParamValue) => void

type RemoveParam = (field: AlertsAutoRefundParam) => void

type GetParamByKey = (key: AlertsAutoRefundParam) => Param | undefined

type Submit = () => Promise<ConfirmJob>

type Confirm = () => Promise<ScheduledJob>

type Cancel = () => void

interface AlertsAutoRefundInstance {
    params: Param[]
    addParam: AddParam
    removeParam: RemoveParam
    submit: Submit
    confirm: Confirm
    getParamByKey: GetParamByKey
    currencies: Currency[]
    setCurrenciesSearchValue: (value: string) => void
    currenciesSearchValue: string
    sources: CardType[]
    setSourcesSearchValue: (value: string) => void
    sourcesSearchValue: string
    cardTypes: CardType[]
    setCardTypesSearchValue: (value: string) => void
    cardTypesSearchValue: string
    midDescriptors: CardType[]
    setMidDescriptorsSearchValue: (value: string) => void
    midDescriptorsSearchValue: string
    isSubmitting: boolean
    isConfirming: boolean
    jobConfirmation: ConfirmJob | null
    cancel: Cancel
    isSubmitDisabled: boolean
    currentScheduledJob: string | null
    lastScheduledJob: string | null
}

type UseAlertsAutoRefund = () => AlertsAutoRefundInstance

interface StateObject<D> {
    loading: boolean
    error: Error | null
    data: D[] | null
    search: ''
}

export enum Fields {
    CURRENCY = 'currency',
    SOURCE = 'source',
    CARD_TYPE = 'card_type',
    MID_DESCRIPTOR = 'mid_descriptor',
}

type State = {
    [Fields.CURRENCY]: StateObject<Currency>
    [Fields.SOURCE]: StateObject<Source>
    [Fields.CARD_TYPE]: StateObject<CardType>
    [Fields.MID_DESCRIPTOR]: StateObject<MIDDescriptor>
}

enum ActionType {
    RESOLVE_QUERY = 'resolve-query',
    REJECT_QUERY = 'reject-query',
    SET_SEARCH_STRING = 'set-search-string',
}

interface Action {
    type: ActionType
    field: Fields
    [key: string]: any
}

const initialState: State = {
    currency: { loading: false, error: null, data: null, search: '' },
    source: { loading: false, error: null, data: null, search: '' },
    card_type: { loading: false, error: null, data: null, search: '' },
    mid_descriptor: { loading: false, error: null, data: null, search: '' },
}

type Reducer = (state: State, action: Action) => State

const reducer: Reducer = (state, action) => {
    switch (action.type) {
        case ActionType.RESOLVE_QUERY: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: false,
                    data: action.data,
                    error: null,
                },
            }
        }
        case ActionType.REJECT_QUERY: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: false,
                    data: null,
                    error: action.error,
                },
            }
        }
        case ActionType.SET_SEARCH_STRING: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: true,
                    search: action.search,
                    data: null,
                    error: null,
                },
            }
        }
        default: {
            throw new Error(
                `Error setting state, action.type '${action.type}' is unknown`
            )
        }
    }
}

//@ts-ignore
export const useAlertsAutoRefund: UseAlertsAutoRefund = () => {
    const { user } = useAuthedUser()
    const activeMerchant = useActiveMerchant()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isConfirming, setIsConfirming] = useState(false)
    const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
    const [currentScheduledJob, setCurrentScheduledJob] = useState<
        string | null
    >(null)
    const [lastScheduledJob, setLastScheduledJob] = useState<string | null>(
        null
    )
    const [jobConfirmation, setJobConfirmation] = useState<ConfirmJob | null>(
        null
    )
    const [paramMap, setParamMap] = useState<Partial<ParamMap>>({
        amount: undefined,
        currency: undefined,
        source: undefined,
        card_type: undefined,
        mid_descriptor: undefined,
    })
    const { id: merchantId } = useActiveMerchant()

    useEffect(() => {
        const fvalue = (
            name: AlertsAutoRefundParam,
            value: string | { max: number; min: number }
        ): any => ({
            name,
            value,
            label: name
                .split('_')
                .map((i) => i.toLowerCase())
                .join(' '),
            displayValue:
                typeof value === 'string'
                    ? value
                    : `${value?.min} - ${value?.max}`,
        })

        CB.process
            .listAutoAcceptJobs({ sort_order: 'desc', sort_by: 'id' })
            .then(async (r) => {
                const queuedJobs = r.data.filter(
                    (i) => !Boolean(i.date_run_end)
                )
                if (queuedJobs.length) {
                    const {
                        date_created,
                        records_num,
                        user_id,
                        currency,
                        amt_from,
                        amt_to,
                        card_type,
                        source,
                        mid_descriptor,
                    } = queuedJobs[queuedJobs.length - 1]
                    const user = await CB.users.get(user_id)
                    setCurrentScheduledJob(
                        `${format(
                            new Date(date_created),
                            "do 'of' LLLL yyyy 'at' K':'mmaaa"
                        )}, ${user.email}, ${records_num} records to update!`
                    )
                    setParamMap({
                        [AlertsAutoRefundParam.CURRENCY]: fvalue(
                            AlertsAutoRefundParam.CURRENCY,
                            currency
                        ),

                        [AlertsAutoRefundParam.SOURCE]: fvalue(
                            AlertsAutoRefundParam.SOURCE,
                            source
                        ),
                        [AlertsAutoRefundParam.CARD_TYPE]: fvalue(
                            AlertsAutoRefundParam.CARD_TYPE,
                            card_type
                        ),
                        [AlertsAutoRefundParam.MID_DESCRIPTOR]: fvalue(
                            AlertsAutoRefundParam.MID_DESCRIPTOR,
                            mid_descriptor
                        ),
                        [AlertsAutoRefundParam.AMOUNT]: fvalue(
                            AlertsAutoRefundParam.AMOUNT,
                            {
                                min: +amt_from,
                                max: +amt_to,
                            }
                        ),
                    })
                } else if (r.data.length) {
                    const {
                        date_created,
                        records_num,
                        user_id,
                    } = r.data.shift() as any
                    const user = await CB.users.get(user_id)
                    setLastScheduledJob(
                        `${format(
                            new Date(date_created),
                            "do 'of' LLLL yyyy 'at' K':'mmaaa"
                        )}, ${user.email}, ${records_num} records updated.`
                    )
                }
            })
    }, [])

    useEffect(() => {
        CB.generic
            .listCurrencies({ search: state.currency.search, limit: '10' })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.CURRENCY,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.CURRENCY,
                    error: err,
                })
            )
    }, [state.currency.search])

    useEffect(() => {
        CB.generic
            .listSources({ search: state.source.search, limit: '10' })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.SOURCE,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.SOURCE,
                    error: err,
                })
            )
    }, [state.source.search])

    useEffect(() => {
        CB.generic
            .listCardTypes({ search: state.card_type.search, limit: '10' })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.CARD_TYPE,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.CARD_TYPE,
                    error: err,
                })
            )
    }, [state.card_type.search])

    useEffect(() => {
        CB.generic
            .listMidDescriptors(merchantId, {
                search: state.mid_descriptor.search,
                limit: '10',
            })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.MID_DESCRIPTOR,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.MID_DESCRIPTOR,
                    error: err,
                })
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.mid_descriptor.search])

    const addParam: AddParam = (name, value) => {
        setParamMap({
            ...paramMap,
            [name]: {
                name,
                value,
                label: name
                    .split('_')
                    .map((i) => i.toLowerCase())
                    .join(' '),
                displayValue:
                    typeof value === 'string'
                        ? value
                        : `${value?.min} - ${value?.max}`,
            },
        })
    }

    const removeParam: RemoveParam = (field) => {
        const newParamMap = Object.keys(paramMap).reduce(
            (acc, key) => ({
                ...acc,
                [key as AlertsAutoRefundParam]:
                    key === field
                        ? undefined
                        : paramMap[key as AlertsAutoRefundParam],
            }),
            {}
        )
        setParamMap(newParamMap)
    }

    const getParamByKey: GetParamByKey = (key: AlertsAutoRefundParam) => {
        return paramMap[key]
    }

    const submit: Submit = () =>
        new Promise((resolve, reject) => {
            if (!user?.id) reject('No authenticated user')
            else {
                let jobParams: JobParams = {
                    user_id: user.id,
                    merchant_id: +activeMerchant.id,
                }
                Object.keys(paramMap).forEach((key) => {
                    switch (key) {
                        case AlertsAutoRefundParam.AMOUNT: {
                            if (paramMap.amount?.value) {
                                jobParams.amt_from = paramMap.amount.value.min
                                jobParams.amt_to = paramMap.amount.value.max
                            }
                            break
                        }
                        case AlertsAutoRefundParam.CURRENCY: {
                            if (paramMap.currency?.value) {
                                jobParams.currency_id = state.currency.data?.find(
                                    (i) =>
                                        i.currency === paramMap.currency?.value
                                )?.id
                            }
                            break
                        }

                        case AlertsAutoRefundParam.SOURCE: {
                            if (paramMap.source?.value) {
                                jobParams.source_id = state.source.data?.find(
                                    (i) =>
                                        i.code.toString() ===
                                        paramMap.source?.value
                                )?.id
                            }
                            break
                        }

                        case AlertsAutoRefundParam.CARD_TYPE: {
                            if (paramMap.card_type?.value) {
                                jobParams.card_type_id = state.card_type.data?.find(
                                    (i) =>
                                        i.name.toString() ===
                                        paramMap.card_type?.value
                                )?.id
                            }
                            break
                        }

                        case AlertsAutoRefundParam.MID_DESCRIPTOR: {
                            if (paramMap.mid_descriptor?.value) {
                                jobParams.mid_descriptor_id = state.mid_descriptor.data?.find(
                                    (i) =>
                                        i.descriptor.toString() ===
                                        paramMap.mid_descriptor?.value
                                )?.id
                            }
                            break
                        }
                    }
                })

                setIsSubmitting(true)

                CB.process
                    .autoAccept(jobParams)
                    .then((r) => {
                        setJobConfirmation(r)
                        resolve(r)
                    })
                    .catch(reject)
                    .finally(() => setIsSubmitting(false))
            }
        })

    const confirm: Confirm = () => {
        if (jobConfirmation === null) {
            throw new Error(
                'No submitted job to confirm. Submit a job before confirming'
            )
        }
        setIsConfirming(true)
        return CB.process
            .confirmAutoAccept(jobConfirmation)

            .finally(() => {
                setIsConfirming(false)
                setJobConfirmation(null)
            })
    }

    const cancel: Cancel = () => {
        setJobConfirmation(null)
    }

    const params: Param[] = useMemo(
        () => Object.values(paramMap).filter(Boolean) as Param[],
        [paramMap]
    )

    return {
        params,
        addParam,
        removeParam,
        submit,
        confirm,
        currencies: state.currency.data ?? [],
        currenciesSearchValue: state.currency.search,
        setCurrenciesSearchValue: (search) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.CURRENCY,
                search,
            }),
        sources: state.source.data ?? [],
        sourcesSearchValue: state.source.search,
        setSourcesSearchValue: (search: any) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.SOURCE,
                search,
            }),
        cardTypes: state.card_type.data ?? [],
        cardTypesSearchValue: state.card_type.search,
        setCardTypesSearchValue: (search: any) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.CARD_TYPE,
                search,
            }),
        midDescriptors: state.mid_descriptor.data ?? [],
        midDescriptorsSearchValue: state.mid_descriptor.search,
        setMidDescriptorsSearchValue: (search: any) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.MID_DESCRIPTOR,
                search,
            }),
        getParamByKey,
        isConfirming,
        isSubmitting,
        jobConfirmation,
        cancel,
        isSubmitDisabled:
            !Object.values(paramMap).filter(
                (param) => typeof param !== 'undefined'
            ).length ||
            Boolean(currentScheduledJob) ||
            isSubmitting ||
            isConfirming,
        currentScheduledJob,
        lastScheduledJob,
    }
}
