import React, { useEffect, useState, useMemo, SyntheticEvent } from 'react'
import {
    AccentArea,
    ConfirmActionModal,
    FileUpload,
    LoadingIndicator,
    Icon as IconIcon
} from 'components'
import {
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import MerchantApi from 'api/MerchantApi'
import CB from 'lib'
import { PDFDocument, EncryptedPDFError } from 'pdf-lib'
import useIsMounted from 'hooks/utils/useIsMounted'
import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useHistory } from 'react-router'
import { iconsTheme } from 'theme-exports'

interface UploadRepresentmentsProps {
    className?: string
    id?: string
    title?: string
    caseIds: number[]
    openModal: boolean
    clientId?: number
    // ModalVariant & ModalMode
    // To-do: get these types in toggleModal from some central store (CaseManagement.VM is there ModalVariant and ModalMode are enforced and exported from)
    // didn't feel right to pull the types for upload representments from CaseManagement.VM as they are unrelated
    toggleModal: (
        variant:
            | 'assignUser'
            | 'moveToDnr'
            | 'revertDnr'
            | 'uploadDocuments'
            | 'toggleFlag',
        mode?: 'multiselect' | 'single'
    ) => void
    toggleUploadSuccess?: () => void
    isMultiSelect?: boolean
    rehydrateView?: () => void
    isReupload?: boolean
    comments?: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
}

interface ICombinedDocument {
    type: string
    label: string
    templateId?: number
    file?: any
    size: number
    pages?: number
}

/**
 * Use UploadRepresentments to ___________________
 */
const UploadRepresentments = ({
    caseIds,
    openModal,
    clientId = 0,
    toggleModal,
    toggleUploadSuccess = () => {},
    isMultiSelect = false,
    rehydrateView,
    isReupload = false,
    comments = '',
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
}: UploadRepresentmentsProps) => {
    const [uploadNote, setUploadNote] = useState(comments)
    const [loading, setLoading] = useState(false)
    const [loadingFileDragAndDrop, setLoadingFileDragAndDrop] = useState<boolean>(false)
    const [templatesList, setTemplatesList] = useState([])
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(true)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [isQCReviewChecked, setIsQCReviewChecked] = useState<boolean>(false)
    const [isQCReviewHelpHovered, setIsQCReviewHelpHovered] = useState<boolean>(false)
    const [isClosingWithFiles, setIsClosingWithFiles] = useState<boolean>(false)
    const uiState = useUiStateContext()
    const hasQCReviewCheckbox = uiState.whoami?.hasRole(EntFeature.QC_REVIEW_CHECKBOX) || false
    const openModalMemo = React.useMemo(() => openModal, [openModal])
    const {
        setSelectedPatchIDs,
        setSelectedPatchType,
        user,
        userStackConfig,
    } = useAuthedUser()
    const [checkingCanUpload, setCheckingCanUpload] = useState<boolean>(false)
    const [hasLockedRepresentment, setHasLockedRepresentment] = useState<boolean>(false)
    const [canUploadErrors, setCanUploadErrors] = useState<any[]>([])
    
    const maxUploadFilePages =
        userStackConfig?.max_representment_page_count ?? 0
    const maxUploadFileSize =
        userStackConfig?.max_representment_upload_size ?? 0

    const formattedMaxUploadFileSize = Math.floor(maxUploadFileSize / 1048576)

    const history = useHistory()

    // Contains both files and from template library
    const [combinedDocuments, setCombinedDocuments] = useState<
        ICombinedDocument[]
    >([])

    const [multiCombinedDocuments, setMultiCombinedDocuments] = useState<any[]>(
        []
    )

    const dialogTitle = useMemo(() => {
        const verbiage =
            caseIds.length > 1 ? `${caseIds.length} cases` : caseIds[0]
        return `Upload Representment - ${verbiage}`
    }, [caseIds])

    const isExceedingMaximumUploadPages = (selectedFilePages: number) => {
        if (typeof maxUploadFilePages === 'undefined') {
            setAlertSnackbarProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            return true
        }

        if (maxUploadFilePages === 0) return false

        let totalPages: number = 0

        if (isMultiSelect) {
            totalPages =
                (multiCombinedDocuments[0]?.files ?? []).reduce(
                    (sum: number, document: any) => {
                        return sum + document.pages
                    },
                    0
                ) + selectedFilePages
        } else {
            totalPages =
                combinedDocuments.reduce((sum: number, document: any) => {
                    return sum + document.pages
                }, 0) + selectedFilePages
        }

        if (totalPages > maxUploadFilePages) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Images and PDF pages must not exceed ${maxUploadFilePages} total.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)

            return true
        }

        return false
    }

    const isExceedingMaximumUploadLimit = (selectedFileSize: number) => {
        if (!maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            return true
        }

        let totalSize: number = 0

        if (isMultiSelect) {
            totalSize =
                (multiCombinedDocuments[0]?.files ?? []).reduce(
                    (sum: number, document: any) => {
                        return sum + document.size
                    },
                    0
                ) + selectedFileSize
        } else {
            totalSize =
                combinedDocuments.reduce((sum: number, document: any) => {
                    return sum + document.size
                }, 0) + selectedFileSize
        }

        if (totalSize > maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Files exceed ${formattedMaxUploadFileSize}MB total limit.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)

            return true
        }

        return false
    }

    const { isMounted } = useIsMounted()

    const handleFileDragAndDrop = async (file: any) => {
        let pageSize = 0
        if (!caseIds.length) return
        if (isExceedingMaximumUploadLimit(file[0]?.size)) return
        if (!file.length) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Only single JPG or PDF files of no more than ${formattedMaxUploadFileSize}MB in total size are allowed.`,
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }
        // Check for bad JPGs, or PNG, etc. that have been renamed to JPG, in order to get past extension validation.
        if(file[0].type === 'image/jpeg') {
            let jpgError = false
            const testJPGFile = (file: any) => {
                return new Promise((resolve) => {
                    const fileReader = new FileReader()
                    fileReader.onload = (e) => {
                        try {
                            // @ts-ignore
                            const rawData = e.target.result.toString() ?? ''
                            const imageData = Uint8Array.from(atob(rawData.replace('data:image/jpeg;base64,', '')), c => c.charCodeAt(0))
                            jpgError = !((imageData[imageData.length - 1] === 217) && (imageData[imageData.length - 2] === 255))
                        } catch(e) {
                            jpgError = true
                        }
                        if (jpgError) {
                            setAlertSnackbarProps({
                                title: 'Error',
                                message:
                                    'Selected JPG file is corrupt.',
                                intent: 'error',
                            })
                            setAlertSnackbarOpen(true)
                        }
                        return resolve(jpgError)
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            await testJPGFile(file[0])
            if (jpgError) return
            pageSize = 1
        }
        // Check for encrypted or otherwise corrupt PDF file.
        if (file[0].type === 'application/pdf') {
            let pdfError = false
            const PDFBuffer = await file[0].arrayBuffer()
            const PDFByteArray = new Uint8Array(PDFBuffer)
            await PDFDocument.load(PDFByteArray)
                .then(async(doc) => {
                    try {
                        // Final check to see if PDF really was opened, or corrupt in a way that it might sneak by.
                        pageSize = doc.getPageCount()
                        await doc.copyPages(doc, doc.getPageIndices())
                            .catch(() => {
                                throw new EncryptedPDFError()
                            })
                    } catch(e) {
                        throw new EncryptedPDFError()
                    }
                })
                .catch((e: EncryptedPDFError) => {
                    pdfError = true
                    if (e.message.indexOf('encrypted') > -1) {
                        setAlertSnackbarProps({
                            title: 'Error',
                            message:
                                'Selected PDF file is corrupt or encrypted.',
                            intent: 'error',
                        })
                        return setAlertSnackbarOpen(true)
                    }
                })
            if (pdfError) return
        }

        if (isExceedingMaximumUploadPages(pageSize)) return

        if (isMultiSelect) {
            setLoadingFileDragAndDrop(true)
            const docUploadPromiseArray = caseIds.map((id) =>
                CB.documents.uploadSingleFile(id.toString(), file)
            )

            Promise.all(docUploadPromiseArray)
                .then((data) => {
                    const fileArr: any[] = []
                    data.forEach((file, i) => {
                        fileArr.push({
                            caseId: caseIds[i],
                            files: [
                                // if documents aleady exists for the selected cases, spread the existing files before the new file
                                ...(multiCombinedDocuments[i]
                                    ? multiCombinedDocuments[i].files
                                    : []),
                                {
                                    type: 'file',
                                    label: file.filename,
                                    file,
                                    size: file.size,
                                    pages: pageSize,
                                },
                            ],
                        })
                    })

                    if (isMounted.current) setMultiCombinedDocuments(fileArr)
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
                .finally(() => setLoadingFileDragAndDrop(false))
        } else {
            setLoadingFileDragAndDrop(true)
            CB.documents
                .uploadSingleFile(caseIds[0].toString(), file)
                .then((data: any) => {
                    if (isMounted.current)
                        setCombinedDocuments([
                            ...combinedDocuments,
                            {
                                type: 'file',
                                label: data.filename,
                                file: data,
                                size: data.size,
                                pages: pageSize,
                            },
                        ])
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
                .finally(() => setLoadingFileDragAndDrop(false))
        }
    }

    const handleTemplateListSelect = (template: any) => {
        if (isExceedingMaximumUploadLimit(template.file?.size)) return
        if (isExceedingMaximumUploadPages(template.file.pages ?? 1)) return

        if (isMultiSelect) {
            const newDocArr: any[] = []
            // loop through the multi combined docs and add the selected template to them
            if (multiCombinedDocuments.length) {
                multiCombinedDocuments.forEach((doc) => {
                    newDocArr.push({
                        ...doc,
                        files: [
                            ...(doc.files.length ? doc.files : []),
                            {
                                type: 'template',
                                label: template.label,
                                templateId: template.value,
                                size: template.file.size,
                                pages: template.file.pages ?? 1,
                            },
                        ],
                    })
                })
            } else {
                // if the template is the first file selected, this builds the array of case objects from the selected case ids
                caseIds.forEach((id, i) => {
                    newDocArr.push({
                        caseId: id,
                        files: [
                            // if documents aleady exists for the selected cases, spread the existing files before the new file
                            ...(multiCombinedDocuments[i]
                                ? multiCombinedDocuments[i].files
                                : []),
                            {
                                type: 'template',
                                label: template.label,
                                templateId: template.value,
                                size: template.file.size,
                                pages: template.file.pages ?? 1,
                            },
                        ],
                    })
                })
            }

            if (isMounted.current) setMultiCombinedDocuments(newDocArr)
        } else {
            if (isMounted.current)
                setCombinedDocuments([
                    ...combinedDocuments,
                    {
                        type: 'template',
                        label: template.label,
                        templateId: template.value,
                        size: template.file.size,
                        pages: template.file.pages ?? 1,
                    },
                ])
        }
    }

    const handleUnstage = (file: number) => {
        if (isMultiSelect) {
            multiCombinedDocuments.forEach((cases) => {
                cases.files.splice(file, 1)
            })
            if (isMounted.current)
                setMultiCombinedDocuments([...multiCombinedDocuments])
        } else {
            combinedDocuments.splice(file, 1)
            if (isMounted.current) setCombinedDocuments([...combinedDocuments])
        }
    }

    const handleReorderCombinedDocuments = (result: any) => {
        const to = result.destination.index
        const from = result.source.index

        if (isMultiSelect) {
            multiCombinedDocuments.forEach((cases, i) => {
                cases.files.splice(
                    to,
                    0,
                    multiCombinedDocuments[i].files.splice(from, 1)[0]
                )
            })
            if (isMounted.current)
                setMultiCombinedDocuments([...multiCombinedDocuments])
        } else {
            combinedDocuments.splice(
                to,
                0,
                combinedDocuments.splice(from, 1)[0]
            )
            if (isMounted.current) setCombinedDocuments([...combinedDocuments])
        }
    }

    const clearIsUploadingVars = () => {
        setCombinedDocuments([])
        setMultiCombinedDocuments([])
        setCanUploadErrors([])
        setUploadNote('')
        setIsQCReviewChecked(false)
        setSelectedPatchIDs && setSelectedPatchIDs('')
        setSelectedPatchType && setSelectedPatchType('')
    }

    const handleRepresentmentUpload = () => {
        if (uploadDisabled) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Please select a file to upload.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }

        setLoading(true)
        CB.documents
            .postRepresentment(
                caseIds[0],
                combinedDocuments,
                isQCReviewChecked,
                isReupload,
                uploadNote
            )
            .then(() => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success!',
                        message: 'The representment was successfully uploaded.',
                        intent: 'success',
                    })
                clearIsUploadingVars()
                setAlertSnackbarMainOpen(true)
                toggleUploadSuccess()
                toggleModal('uploadDocuments')
                if (rehydrateView) rehydrateView()
            })
            .catch((message) => {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message:
                            message ??
                            'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeUploadDialogWithoutSave = () => {
        toggleModal('uploadDocuments')
        setIsClosingWithFiles(false)
        if (!isReupload && !canUploadErrors.length) {
            return CB.cases.callCasePatch(caseIds.join(','), 1, 'upload')
                .finally(() => {
                    clearIsUploadingVars()

                })
        }
            clearIsUploadingVars()

    }

    const checkFilesBeforeClose = () => {
        if(
            combinedDocuments.length
            || multiCombinedDocuments.filter(obj => obj.files.length).length
            || comments !== uploadNote
        ) {
            return setIsClosingWithFiles(true)    
        }
        closeUploadDialogWithoutSave()
    }

    const handleBulkRepresentmentUpload = () => {
        if (uploadDisabled) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Please select a file to upload.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }
        setLoading(true)

        const uploadArray = caseIds.map((id) => {
            // find case object in multi combined doc array
            const _case = multiCombinedDocuments.filter(
                (doc) => doc.caseId === id
            )[0]
            return CB.documents.postRepresentment(
                id,
                _case.files,
                isQCReviewChecked,
                isReupload,
                uploadNote
            )
        })

        Promise.all(uploadArray)
            .then(() => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success!',
                        message: 'The representment was successfully uploaded.',
                        intent: 'success',
                    })
                clearIsUploadingVars()
                setAlertSnackbarMainOpen(true)
                toggleUploadSuccess()
                toggleModal('uploadDocuments')
                unselectAllRows()
                if (rehydrateView) rehydrateView()
            })
            .catch((message) => {
                if (isMounted.current)
                    setAlertSnackbarProps({
                        title: 'Error',
                        message,
                        intent: 'error',
                    })
                setAlertSnackbarOpen(true)
            })
            .finally(() => {
                setSelectedPatchIDs && setSelectedPatchIDs('')
                setSelectedPatchType && setSelectedPatchType('')
                setLoading(false)
            })
    }

    React.useEffect(() => {
        if (openModalMemo && isMounted.current) {
            const userMerchantId = user?.merchant?.id || 1
            MerchantApi.getDocumentTemplatesList(userMerchantId).then(
                ({ data: { data } }) => {
                    setTemplatesList(
                        data.map((data: any) => {
                            return {
                                label: data.file.filename,
                                value: data.id,
                                file: data.file,
                            }
                        })
                    )
                }
            )
        }
    }, [clientId, openModalMemo, isMounted, user])

    useEffect(() => {
        const shouldBeDisabled =
            combinedDocuments.length || multiCombinedDocuments.length
                ? false
                : true

        setUploadDisabled(shouldBeDisabled)
    }, [combinedDocuments, multiCombinedDocuments])

    useEffect(() => {
        if (!openModal && hasLockedRepresentment) {
            return setHasLockedRepresentment(false)
        }
        if (openModal && !isReupload && !checkingCanUpload && !hasLockedRepresentment) {
            setCheckingCanUpload(true)
            CB.cases.callCasePatch(caseIds.join(','), 16, 'upload').then(() => {
                setSelectedPatchIDs && setSelectedPatchIDs(caseIds.join(','))
                setSelectedPatchType && setSelectedPatchType('upload')
                history.push({
                    pathname: '',
                    state: {
                        caseId: caseIds.join(','),
                        reviewType: 'upload'
                    },
                })
            }).catch((err: any) => {
                setCanUploadErrors(err.response.data.data)
            }).finally(() => {
                setHasLockedRepresentment(true)
                setCheckingCanUpload(false)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal, isReupload, caseIds, hasLockedRepresentment])

    return (
        <>
            <Dialog open={openModalMemo} fullWidth={true} maxWidth={'md'}>
                <Tooltip title="Close Upload">
                    <>
                        <Icon
                            icon={faTimes}
                            className={'emp-uploadRepresentments-modalClose'}
                            onClick={checkFilesBeforeClose}
                            id={'close-upload-representment-icon'}
                        />
                    </>
                </Tooltip>
                <AccentArea title={dialogTitle}>
                    <div
                        style={{
                            display: 'grid',
                            gap: 8,
                            placeItems: 'center',
                        }}
                    >
                        {checkingCanUpload || canUploadErrors.length ? (
                            <>
                                {!canUploadErrors.length ? (
                                    <LoadingIndicator />
                                ) : (
                                    <>
                                        <div
                                            className={
                                                'emp-uploadRepresentments-errorContainer'
                                            }
                                        >
                                            <h3>
                                                Unable to process the following
                                                cases at this time. Please try
                                                again later.
                                            </h3>
                                            {canUploadErrors.map((err, idx) => {
                                                return (
                                                    <div key={`key-${idx}`}>
                                                        {err.id} -{' '}
                                                        {err.errors.join(', ')}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={checkFilesBeforeClose}
                                        >
                                            Back to cases
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <FileUpload
                                    onUpload={() => {}}
                                    onFileDragAndDrop={handleFileDragAndDrop}
                                    loadingFileDragAndDrop={loadingFileDragAndDrop}
                                    onTemplateListSelect={handleTemplateListSelect}
                                    onUnstage={handleUnstage}
                                    onReorderCombinedDocuments={
                                        handleReorderCombinedDocuments
                                    }
                                    fileListTitle="Documents Added to the Case"
                                    files={[]}
                                    accepts={['.jpg', '.pdf']}
                                    maxUploadFileSize={maxUploadFileSize}
                                    isRepresentmentModal={true}
                                    templatesList={templatesList}
                                    combinedDocuments={
                                        isMultiSelect
                                            ? multiCombinedDocuments
                                            : combinedDocuments
                                    }
                                    isMultiSelect={isMultiSelect}
                                />
                                <TextField
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    value={uploadNote}
                                    onChange={(e): void =>
                                        setUploadNote(e.target.value)
                                    }
                                    label="Case Comments"
                                />
                                {hasQCReviewCheckbox && !isReupload && (
                                    <div
                                        className={
                                            'emp-uploadRepresentments-isQCReviewContainer'
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isQCReviewChecked}
                                                    onChange={(ev: SyntheticEvent)=>{
                                                        const target = ev.target as HTMLInputElement
                                                        return setIsQCReviewChecked(target.checked)
                                                    }}
                                                    color={'secondary'}
                                                />
                                            }
                                            label="Send the case for Quality Review"
                                        />
                                        <Icon
                                            icon={faQuestionCircle}
                                            color="#3cb4e5"
                                            onMouseEnter={() => {
                                                setIsQCReviewHelpHovered(true)
                                            }}
                                            onMouseLeave={() => {
                                                setIsQCReviewHelpHovered(false)
                                            }}
                                        />
                                        <div
                                            className={
                                                'emp-uploadRepresentments-isQCReviewHelp'
                                            }
                                            style={{
                                                display: isQCReviewHelpHovered
                                                    ? 'block'
                                                    : 'none',
                                            }}
                                        >
                                            If this option is selected, then the
                                            case would be routed to Quality
                                            Review after the file upload process
                                            is completed and this pop up window
                                            is closed.
                                        </div>
                                    </div>
                                )}
                                <Typography variant="body1">
                                    {isQCReviewChecked ? (
                                        <span>
                                            Click upload below to send the
                                            document to Quality Control. At
                                            least one document must be uploaded.
                                        </span>
                                    ) : (
                                        <span>
                                            Click upload below to send the
                                            document and complete case. At least
                                            one document must be uploaded.
                                        </span>
                                    )}
                                </Typography>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={
                                        isMultiSelect
                                            ? handleBulkRepresentmentUpload
                                            : handleRepresentmentUpload
                                    }
                                    disabled={loading}
                                >
                                    {iconsTheme.upload && (
                                        <IconIcon
                                            className={`${iconsTheme.upload} emp-uploadRepresentments-uploadIcon`}
                                        />
                                    )}
                                    {loading ? 'Uploading files...' : 'Upload'}
                                </Button>
                            </>
                        )}
                    </div>
                </AccentArea>
                <AlertSnackbar
                    content={alertSnackbarProps}
                    open={alertSnackbarOpen}
                    onClose={() => {
                        setAlertSnackbarOpen(false)
                    }}
                    showCloseIcon
                />
            </Dialog>
            <ConfirmActionModal
                open={isClosingWithFiles}
                toggleOpen={() => setIsClosingWithFiles(false)}
                onConfirm={closeUploadDialogWithoutSave}
                header={'Are you sure?'}
                message={
                    'The changes you made in this session will be lost.'
                }
                confirmButtonText={'Discard'}
                testId={'upload-representments'}
            />
        </>
    )
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places

export default UploadRepresentments
