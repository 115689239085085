import React, { FC } from 'react'
import {
    Area,
    AreaChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Label,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { AlertDatum } from '../ResultantKpi.vm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'

const ResultantKpiAreaChart: FC<{ data: AlertDatum[]; loading: boolean }> = ({
    data,
    loading,
}) => {
    const returnFormattedLegendLabel = (value: any, entry: any) => {
        return (
            <span
                style={{
                    textTransform: 'capitalize',
                    fontSize: clientTheme.typography.body1.fontSize,
                }}
            >
                {value}
            </span>
        )
    }

    const CustomTooltipValue = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className={'emp-tooltipContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={'emp-tooltipTableHeader'}
                                >
                                    {label}
                                </td>
                            </tr>
                            {payload.map((item: any, i: number) => {
                                return (
                                    <tr key={`key-tooltip-${i}`}>
                                        <td
                                            className={'emp-tooltipTableRow'}
                                            style={{
                                                borderRight:
                                                    '1px dotted #b3b3b3',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                size="xs"
                                                icon={faSquare}
                                                style={{
                                                    marginRight: '5px',
                                                    color: payload[i].stroke,
                                                }}
                                            />
                                            {payload[i].name}
                                        </td>
                                        <td
                                            className={'emp-tooltipTableRow'}
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {payload[i].value}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    return (
        <div id={'resultantKpiAreaChart'}>
            {!loading ? (
                data.length ? (
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                            data={data.map((obj: any) => {
                                return {
                                    ...obj,
                                    alert_total: +obj.alert_total,
                                    chargeback_total: +obj.chargeback_total,
                                    total: +obj.total,
                                }
                            })}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <XAxis
                                dataKey="month"
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                            <YAxis
                                dataKey="total"
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                            <Tooltip content={<CustomTooltipValue />} />
                            <Area
                                type="monotone"
                                dataKey="chargeback_total"
                                name="Chargebacks"
                                stroke="#006da8"
                                fill="#d2e4f0"
                                activeDot={{ r: 6 }}
                                dot={{
                                    stroke: '#006da8',
                                    fill: '#006da8',
                                }}
                            />
                            <Area
                                type="monotone"
                                dataKey="alert_total"
                                name="Alerts"
                                stroke="#ff7124"
                                fill="#f5debc"
                                opacity={0.8}
                                activeDot={{ r: 6 }}
                                dot={{
                                    stroke: '#ff7124',
                                    fill: '#ff7124',
                                }}
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#1e9533"
                                fill="#cae8ca"
                                opacity={0.5}
                                activeDot={{ r: 6 }}
                                dot={{
                                    stroke: '#1e9533',
                                    fill: '#1e9533',
                                }}
                                name="Total"
                            />
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                height={36}
                                iconType="square"
                                iconSize={10}
                                formatter={returnFormattedLegendLabel}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={[]}>
                            <XAxis ticks={['No Data']}>
                                <Label
                                    value="No Data"
                                    offset={-140}
                                    position="insideTop"
                                    style={{
                                        fontSize: '14px',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                domain={[0, 100]}
                                tick={{
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                }}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                )
            ) : (
                <div className={'emp-loadingContainer'}>
                    <LoadingIndicator />
                </div>
            )}
        </div>
    )
}

export default ResultantKpiAreaChart
