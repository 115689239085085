import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import {
    ColDef,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
} from 'ag-grid-community'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { AGDataGridLoadingIndicator } from '../AGDataGrid/index'
import { PaginationAGGrid } from 'components'

interface AGDataGridProps {
    gridRef: any
    data: any[]
    columns: any[]
    className?: string
    defaultColDef?: {
        [key: string]: string | number | boolean
    }
    autoGroupColDef?: {
        [key: string]: any
    }
    testId: string
    loading?: boolean
    defaultRowExpandLevel?: number
    groupKey: string
    handleSelectedRow?: (rowData: any) => void
    handleDoubleClickSelectedRow?: (rowData: any) => void
    enableCustomContextMenuItems?: boolean
    getCustomContextMenuItems?: any
    activeNodeIdActual: number
    paginate?: boolean
    pageCount?: number
    pageSize?: number
    totalEntries?: number
    setStart?: (val: number) => void
    setLimit?: (val: number) => void
    currentPage?: number
    setCurrentPage?: (val: number) => void
    fullHeightContainerRef?: any
    enableBasicThemePagination?: boolean
    headerHeight?: number
    rowHeight?: number
    floatingFiltersHeight?: number
    headerComponent?: React.ReactNode
    suppressCellFocus?: boolean
    gridThemeType?: 'alpine' | 'material'
}

const stylesObj = {
    alpineRoot: {
        '& .ag-theme-alpine': {
            '--ag-font-size': `${clientTheme.typography.body1.fontSize} !important`,
            '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
            '--ag-font-family': `${clientTheme.typography.fontFamily.join(
                ','
            )} !important`,
        },
        '--ag-font-family': `${clientTheme.typography.fontFamily.join(
            ','
        )} !important`,
        '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
        '& .ag-header-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            fontSize: clientTheme.typography.body1.fontSize,
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#545454',
        },
        '& .ag-cell': {
            fontSize: clientTheme.typography.body1.fontSize,
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#464646',
        },
        '& .ag-menu-option-icon': {
            color: clientTheme.secondary,
        },
        '& .ag-ltr .ag-row-group-indent-1': {
            paddingLeft: 3,
        },
        '& .ag-ltr .ag-row-group-indent-1:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 0,
        },
        '& .ag-ltr .ag-row-group-indent-2': {
            paddingLeft: 6,
        },
        '& .ag-ltr .ag-row-group-indent-2:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 3,
        },
        '& .ag-ltr .ag-row-group-indent-3': {
            paddingLeft: 9,
        },
        '& .ag-ltr .ag-row-group-indent-3:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 6,
        },
        '& .ag-ltr .ag-row-group-indent-4': {
            paddingLeft: 12,
        },
        '& .ag-ltr .ag-row-group-indent-4:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 9,
        },
        '& .ag-force-vertical-scroll': {
            overflowY: 'auto !important',
        },
    },
    materialRoot: {
        '& .ag-theme-alpine': {
            '--ag-font-size': `${clientTheme.typography.body1.fontSize} !important`,
            '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
            '--ag-font-family': `${clientTheme.typography.fontFamily.join(
                ','
            )} !important`,
            '--ag-checkbox-checked-color': `${clientTheme.secondary} !important`,
        },
        '--ag-font-family': `${clientTheme.typography.fontFamily.join(
            ','
        )} !important`,
        '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
        '--ag-header-background-color': '#fff !important',
        '& .ag-root-wrapper': {
            borderLeft: 'none',
            borderRight: 'none',
            '--ag-border-color': '#e9e9e9',
        },
        '& .ag-header-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            fontSize: clientTheme.typography.body1.fontSize,
            backgroundColor: '#FFFFFF',
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#545454',
        },
        '& .ag-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            fontSize: clientTheme.typography.body1.fontSize,
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#464646',
        },
        '& .ag-row': {
            borderBottom: '1px solid #eeeeee',
        },
        '.ag-row-odd': {
            backgroundColor: 'hsla(0, 0%, 80%, 0.1)',
        },
        '& .ag-menu-option-icon': {
            color: clientTheme.secondary,
        },
        //Changes alignment/padding of merchant tree
        '& .ag-ltr .ag-row-group-indent-1': {
            paddingLeft: 3,
        },
        '& .ag-ltr .ag-row-group-indent-1:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 0,
        },
        '& .ag-ltr .ag-row-group-indent-2': {
            paddingLeft: 6,
        },
        '& .ag-ltr .ag-row-group-indent-2:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 3,
        },
        '& .ag-ltr .ag-row-group-indent-3': {
            paddingLeft: 9,
        },
        '& .ag-ltr .ag-row-group-indent-3:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 6,
        },
        '& .ag-ltr .ag-row-group-indent-4': {
            paddingLeft: 12,
        },
        '& .ag-ltr .ag-row-group-indent-4:has(>span:nth-of-type(4) >span.false)': {
            paddingLeft: 9,
        },
        '& .ag-force-vertical-scroll': {
            overflowY: 'auto !important',
        },
    },
}

const AGDataTree = ({
    gridRef,
    className,
    data,
    columns,
    defaultColDef = {},
    autoGroupColDef = {},
    testId,
    loading = false,
    defaultRowExpandLevel = 1,
    groupKey,
    handleSelectedRow = () => {},
    handleDoubleClickSelectedRow = () => {},
    enableCustomContextMenuItems = false,
    getCustomContextMenuItems,
    activeNodeIdActual,
    paginate = true,
    pageCount = 0,
    pageSize = 0,
    totalEntries = 0,
    setStart = () => {},
    setLimit = () => {},
    currentPage = 0,
    setCurrentPage = () => {},
    fullHeightContainerRef,
    enableBasicThemePagination = false,
    headerHeight = 0,
    rowHeight = 0,
    floatingFiltersHeight = 0,
    headerComponent = null,
    suppressCellFocus = false,
    gridThemeType = 'alpine',
}: AGDataGridProps): React.ReactElement => {
    const { ZEBRA_STRIPED_ROWS } = useFeatureToggle('DATAGRID')

    const [pageSizeCalculated, setPageSizeCalculated] = useState<number>(pageSize)

    const gridThemeTypes = {
        alpine: {
            style: stylesObj.alpineRoot,
            headerHeight: 30,
            rowHeight: 30,
            paginationBorder: true,
        },
        material: {
            style: stylesObj.materialRoot,
            headerHeight: 45,
            rowHeight: 45,
            paginationBorder: false,
        },
    }

    const gridTheme =
        gridThemeType === 'alpine'
            ? gridThemeTypes.alpine
            : gridThemeTypes.material

    const transformedColumns: ColDef[] = columns.map((col) => {
        return {
            headerName: col.Header,
            field: col.accessor,
            cellRenderer: col.Cell ?? undefined,
            width: col.width ?? undefined,
            maxWidth: col.maxWidth ?? undefined,
            minWidth: col.maxWidth ?? undefined,
            cellStyle: col.cellStyle ?? undefined,
        }
    })

    const defaultColumnDef = useMemo<any>(() => {
        return defaultColDef
    }, [defaultColDef])

    const autoGroupColumnDef = useMemo(() => {
        return autoGroupColDef
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isServerSideGroupOpenByDefault = useCallback(
        (params) => {
            return params.rowNode.level < defaultRowExpandLevel
        },
        [defaultRowExpandLevel]
    )

    const isServerSideGroup = useCallback((dataItem) => {
        return dataItem.group
    }, [])

    const getServerSideGroupKey = useCallback(
        (dataItem) => {
            // specify which group key to use
            return dataItem[groupKey]
        },
        [groupKey]
    )

    const loadingOverlayComponent = useMemo<any>(() => {
        return AGDataGridLoadingIndicator
    }, [])

    const overlayNoRowsTemplate = `<span class="ag-overlay-loading-center">No Records Found!</span>`

    const getRowId = useMemo(() => {
        return (params: any) => {
            return params.data.id
        }
    }, [])

    const formatRowData = (rowData: any[]) => {
        const rowDataFormatter = {
            data: rowData,
            getData: function (request: IServerSideGetRowsRequest) {
                //@ts-ignore
                const extractRowsFromData = (
                    groupKeys: string[],
                    data: any[]
                ) => {
                    if (groupKeys.length === 0) {
                        return data.map((d: any) => {
                            return {
                                ...d,
                                group: !!d.children.length,
                            }
                        })
                    }
                    let key = groupKeys[0]
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].id === key) {
                            return extractRowsFromData(
                                groupKeys.slice(1),
                                data[i].children.slice()
                            )
                        }
                    }
                }
                return extractRowsFromData(request.groupKeys, this.data)
            },
        }
        return rowDataFormatter
    }

    const createServerSideDataSource = (formattedRowData: any) => {
        const dataSource: IServerSideDatasource = {
            getRows: (params: IServerSideGetRowsParams) => {
                const allRows = formattedRowData.getData(params.request)
                params.success({ rowData: allRows })
            },
        }
        return dataSource
    }

    const onRowClicked = useCallback(() => {
        const selectedRows = gridRef?.current?.api.getSelectedRows()
        if (selectedRows) handleSelectedRow(selectedRows[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSelectedRow])

    const onRowDoubleClicked = useCallback(() => {
        const selectedRows = gridRef?.current?.api.getSelectedRows()
        if (selectedRows) handleDoubleClickSelectedRow(selectedRows[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleDoubleClickSelectedRow])

    const getDefaultContextMenuItems = useCallback(() => {
        return [
            {
                name: 'Expand All',
                action: () => gridRef.current!.api.expandAll(),
            },
            {
                name: 'Collapse All',
                action: () => gridRef.current!.api.collapseAll(),
            },
            'separator',
            'copy',
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.api.getDisplayedRowAtIndex(0).selectThisNode(true)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loading) gridRef.current.api.showLoadingOverlay()
            if (!loading && !data?.length)
                gridRef.current!.api.showNoRowsOverlay()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const formattedRowData = formatRowData(data)
        const datasource = createServerSideDataSource(formattedRowData)
        params.api.setServerSideDatasource(datasource)
        loading && params.api.showLoadingOverlay()
        if (!loading && !data?.length) gridRef.current!.api.showNoRowsOverlay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resizeGridHeight = () => {
        if (!fullHeightContainerRef) return
        const current = fullHeightContainerRef.current
        if (!current) return
        const gridContainer = current.getElementsByClassName('ag-layout-auto-height')[0] as HTMLElement
        const gridViewportContainer = current.getElementsByClassName('ag-root-wrapper-body')[0] as HTMLElement
        const paginationContainer = current.getElementsByClassName('ag-pagination-container')[0] as HTMLElement
        const gridContainerOffsetTop = gridContainer?.offsetTop ?? 0
        const paginationContainerHeight = paginationContainer?.offsetHeight ?? 0
        const appFooterContainer = document.getElementById('standardFooter-footer-footer')
        const footerHeight = appFooterContainer ? appFooterContainer.offsetHeight : 0
        // The 24px "magic number" is to take bottom page padding into account. May need adjusted in future.
        const gridContainerHeightToSet = gridContainerOffsetTop + paginationContainerHeight + footerHeight + 24

        // Attempt to set pagination page size dynamically.
        try {
            const treeHeaderRect = current.getElementsByClassName('ag-header-viewport')[0].getBoundingClientRect()
            const paginationContainerRect = paginationContainer.getBoundingClientRect()
            const cellHeight = current.getElementsByClassName('ag-center-cols-container')[0].children[0].offsetHeight
            const valueToSet = Math.floor((paginationContainerRect.top - treeHeaderRect.bottom) / cellHeight) - 1
            setPageSizeCalculated(valueToSet)
            setLimit(valueToSet)
        } catch(e) {}

        // Use requestAnimationFrame to defer DOM updates to the next frame and prevent uncaught ResizeObserver loop console errors.
        requestAnimationFrame(() => {
            try {
                gridContainer.style.height = `calc(100vh - ${gridContainerHeightToSet}px)`
                gridContainer.style.minHeight = `135px`
                gridViewportContainer.style.height = `${gridContainer.offsetHeight}px`
            } catch(e) {}
        })
    }

    const resizeObserver = new ResizeObserver(() => {
       resizeGridHeight()
    })

    React.useEffect(() => {
        resizeObserver.observe(document.body)
        return () => {
            resizeObserver.unobserve(document.body)
        }
    }, [resizeObserver])

    return (
        <Box
            className={`ag-theme-alpine ${className} ${
                !ZEBRA_STRIPED_ROWS?.enabled && 'emp-agDataTree-noZebraStriping'
            }`}
            {...(testId ? { id: testId } : {})}
            //@ts-ignore
            sx={() => gridTheme.style}
        >
            {headerComponent && (
                <div className="ag-header-container">{headerComponent}</div>
            )}
            <AgGridReact
                treeData={true}
                ref={gridRef}
                columnDefs={transformedColumns}
                defaultColDef={defaultColumnDef}
                autoGroupColumnDef={autoGroupColumnDef}
                onFirstDataRendered={onFirstDataRendered}
                animateRows={true}
                alwaysShowVerticalScroll={true}
                rowHeight={rowHeight ? rowHeight : gridTheme.rowHeight}
                headerHeight={
                    headerHeight || headerComponent
                        ? headerHeight
                        : gridTheme.headerHeight
                }
                floatingFiltersHeight={floatingFiltersHeight}
                tooltipShowDelay={500}
                domLayout={'autoHeight'}
                suppressCellFocus={suppressCellFocus}
                // Props for Overlays:
                loadingOverlayComponent={loadingOverlayComponent}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                // Props for server-side data:
                rowModelType={'serverSide'}
                isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                isServerSideGroup={isServerSideGroup}
                getServerSideGroupKey={getServerSideGroupKey}
                onGridReady={onGridReady}
                onRowDataUpdated={resizeGridHeight}
                // Props for row selection:
                rowSelection={'single'}
                getRowId={getRowId}
                onRowClicked={onRowClicked}
                // Props for double click row selection:
                onRowDoubleClicked={onRowDoubleClicked}
                // Props for context menu feature:
                getContextMenuItems={(params) =>
                    enableCustomContextMenuItems
                        ? getCustomContextMenuItems(params)
                        : getDefaultContextMenuItems()
                }
            />
            {paginate && (
                <div className="ag-pagination-container">
                    <PaginationAGGrid
                        pageCount={pageCount}
                        pageSize={pageSizeCalculated}
                        totalEntries={totalEntries}
                        setStart={setStart}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        testId={testId}
                        enablePaginationRefreshBtn={false}
                        enableBasicThemePagination={enableBasicThemePagination}
                        enablePaginationBorder={gridTheme.paginationBorder}
                        disablePaginationBtns={loading}
                    />
                </div>
            )}
        </Box>
    )
}

export default AGDataTree