import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
} from '@mui/material'
import 'views/views.css'
import MerchantApi from 'api/MerchantApi'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { LoadingIndicator, Lamp, LampColor, Ellipsify } from 'components'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import useIsMounted from 'hooks/utils/useIsMounted'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'
import idDirectory from './idAttributes'

export const UserStatus = ({
    status,
    testId = '',
}: {
    status: boolean
    testId?: string
}) => {
    return (
        <div
            className={'emp-merchantEdit-lampWrapper'}
            {...(testId && { id: testId })}
        >
            <Lamp
                className={'emp-merchantEdit-lamp'}
                color={status ? 'green' : 'red'}
            />
            {status ? 'Active' : 'Inactive'}
        </div>
    )
}

export const AlertsDeflectorStatus = ({
    label,
    status,
    testId = '',
}: {
    label: string
    status: number
    testId?: string
}) => {
    let color = 'grey' as LampColor

    switch (status) {
        case 2:
            color = 'orange'
            break
        case 3:
            color = 'green'
            break
        default:
            color = 'red'
    }

    return (
        <div
            className={'emp-merchantEdit-lampWrapper'}
            {...(testId && { id: testId })}
        >
            <Lamp className={'emp-merchantEdit-lamp'} color={color} />
            {label}
        </div>
    )
}

const EditLabel = ({
    label,
    testId,
}: {
    label: any
    testId: string
}): React.ReactElement => {
    return (
        <div className={'emp-merchantEdit-label'} id={testId}>
            {label}
        </div>
    )
}

/**
 * @name MerchantEdit
 * @desc Edit merchant component used in merchant settings (for Chase)
 */
const MerchantEdit = ({
    merchantId,
    merchant,
    isRootMerchantSelected,
    merchantParentName,
    setMerchantParentName,
    availableDeflectors,
    setMerchantOverallStatus,
    deflectorsLoading = false,
}: {
    merchantId: number
    merchant: any
    isRootMerchantSelected: boolean
    merchantParentName: string
    setMerchantParentName: (name: string) => void
    availableDeflectors: Deflector[]
    setMerchantOverallStatus?: (status: boolean) => void
    deflectorsLoading?: boolean
}): React.ReactElement => {
    const {
        MERCHANT_EDIT,
        DEFLECTORS,
        MERCHANT_ALIAS,
        SHOW_ISSUER_DOC_TOGGLE,
        MERCHANT_SERVICE_LEVELS,
    } = useFeatureToggle('MERCHANT_HIERARCHY')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [merchantInfo, setMerchantInfo] = useState<any>({})

    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const sendMerchantUpdate = (payload: any, updateInPlaceObj: any) => {
        MerchantApi.editMerchantInfo((merchantId as unknown) as number, payload)
            .then(() => {
                setMerchantInfo({
                    ...merchantInfo,
                    ...updateInPlaceObj,
                })
            })
            .catch((e) => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `An error occurred. Please try again later.`,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const updateServiceLevel = (e: any, value: any) => {
        const payload = {
            service_level_id: +value,
        }
        const updateInPlaceObj = {
            service_level: {
                id: +value,
            },
        }
        sendMerchantUpdate(payload, updateInPlaceObj)
    }

    const { isMounted } = useIsMounted()

    useEffect(() => {
        if (merchant && isRootMerchantSelected) {
            if (isMounted.current) {
                setMerchantInfo(merchant)
                setMerchantParentName('')
                setMerchantOverallStatus &&
                    setMerchantOverallStatus(merchant.status?.id === 1 || false)
            } else return
        }
        if (merchant && merchant.parent_id) {
            setIsLoading(true)
            setMerchantInfo(merchant)
            setMerchantOverallStatus &&
                setMerchantOverallStatus(merchant.status?.id === 1 || false)
            axios
                .get(`/cm/merchants/${merchant.parent_id}`)
                .then(({ data }) => {
                    setMerchantParentName(data.business_name)
                })
                .finally(() => setIsLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant, isRootMerchantSelected, isMounted, setMerchantParentName])

    const hasIssuerDocInFeatures =
        merchantInfo?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 1
        ) ?? false

    if (!merchantInfo.id || isLoading || deflectorsLoading)
        return (
            <div style={{ textAlign: 'center' }}>
                <LoadingIndicator />
            </div>
        )
    return (
        <>
            <Grid container className={'emp-merchantEdit-root'}>
                <Grid
                    container
                    item
                    xs={8}
                    className={'emp-merchantEdit-leftGrid'}
                >
                    <Grid item xs={12}>
                        <EditLabel
                            label="Overall Status"
                            testId={`${idDirectory.labelHeader}-overall-status`}
                        />
                        <Grid
                            container
                            alignItems="center"
                            className={'emp-merchantEdit-innerGrid'}
                        >
                            <Grid container item xs={6}>
                                <UserStatus
                                    status={
                                        merchantInfo.status?.id === 1 || false
                                    }
                                    testId={`${idDirectory.statusField}-overall-status`}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                justifyContent="flex-end"
                            >
                                <Tooltip
                                    title={
                                        isRootMerchantSelected
                                            ? ''
                                            : 'Click the edit button to modify'
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <div>
                                        <Switch
                                            className={
                                                'emp-merchantEdit-switchBtn'
                                            }
                                            checked={
                                                merchantInfo.status?.id === 1 ||
                                                false
                                            }
                                            name={'merchant-status'}
                                            size={'small'}
                                            disabled={true}
                                            id={`${idDirectory.switchField}-overall-status`}
                                            color="secondary"
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={'emp-merchantEdit-middleLeftGrid'}
                    >
                        <Grid item xs={4}>
                            <EditLabel
                                label="Merchant Name"
                                testId={`${idDirectory.labelHeader}-merchant-name`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.business_name || 'N/A'}
                                    testId={`${idDirectory.textField}-merchant-name`}
                                />
                            </div>
                        </Grid>
                        {MERCHANT_ALIAS?.enabled ? (
                            <Grid item xs={4}>
                                <EditLabel
                                    label={MERCHANT_ALIAS.input_edit_label}
                                    testId={`${idDirectory.labelHeader}-${MERCHANT_ALIAS.input_edit_label}`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={merchantInfo.alias || 'N/A'}
                                        testId={`${idDirectory.textField}-${MERCHANT_ALIAS.input_edit_label}`}
                                    />
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={4}>
                                <EditLabel
                                    label="Merchant Parent Name"
                                    testId={`${idDirectory.labelHeader}-merchant-parent-name`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={merchantParentName || 'N/A'}
                                        testId={`${idDirectory.textField}-merchant-parent-name`}
                                    />
                                </div>
                            </Grid>
                        )}
                        {MERCHANT_ALIAS?.enabled && (
                            <Grid item xs={4}>
                                <EditLabel
                                    label="Merchant Parent Name"
                                    testId={`${idDirectory.labelHeader}-merchant-parent-name`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={merchantParentName || 'N/A'}
                                        testId={`${idDirectory.textField}-merchant-parent-name`}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={'emp-merchantEdit-middleLeftGrid'}
                    >
                        <Grid item xs={3}>
                            <EditLabel
                                label={MERCHANT_EDIT.input_edit_label}
                                testId={`${idDirectory.labelHeader}-${MERCHANT_EDIT.input_edit_label}`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={
                                        merchantInfo.partner_company_id || 'N/A'
                                    }
                                    testId={`${idDirectory.textField}-${MERCHANT_EDIT.input_edit_label}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Date Onboarded"
                                testId={`${idDirectory.labelHeader}-date-onboarded`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.date_created || 'N/A'}
                                    testId={`${idDirectory.textField}-date-onboarded`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Business Address"
                                testId={`${idDirectory.labelHeader}-business-address`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={
                                        merchantInfo.business_address || 'N/A'
                                    }
                                    testId={`${idDirectory.textField}-business-address`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="City"
                                testId={`${idDirectory.labelHeader}-city`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.city || 'N/A'}
                                    testId={`${idDirectory.textField}-city`}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        className={'emp-merchantEdit-middleLeftGrid'}
                    >
                        <Grid item xs={3}>
                            <EditLabel
                                label="Region/State"
                                testId={`${idDirectory.labelHeader}-region-state`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.state || 'N/A'}
                                    testId={`${idDirectory.textField}-region-state`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Postal/Zip Code"
                                testId={`${idDirectory.labelHeader}-zip-code`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.zip || 'N/A'}
                                    testId={`${idDirectory.textField}-zip-code`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Country"
                                testId={`${idDirectory.labelHeader}-country`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.country?.name || 'N/A'}
                                    testId={`${idDirectory.textField}-country`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Business Phone"
                                testId={`${idDirectory.labelHeader}-business-phone`}
                            />
                            <div className={'emp-merchantEdit-merchantValue'}>
                                <Ellipsify
                                    value={merchantInfo.business_phone || 'N/A'}
                                    testId={`${idDirectory.textField}-business-phone`}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {/* Admin contact info */}
                    {merchantInfo.contact && (
                        <Grid
                            container
                            item
                            xs={12}
                            className={'emp-merchantEdit-middleLeftGrid'}
                            style={{ rowGap: '8px' }}
                        >
                            {merchantInfo.contact?.username !== null && (
                                <Grid item xs={3}>
                                    <EditLabel
                                        label="Contact Username"
                                        testId={`${idDirectory.labelHeader}-contact-username`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantEdit-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                merchantInfo.contact
                                                    ?.username || 'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-contact-username`}
                                        />
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                <EditLabel
                                    label="Contact First Name"
                                    testId={`${idDirectory.labelHeader}-contact-first-name`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={
                                            merchantInfo.contact?.fname ||
                                            'Unavailable'
                                        }
                                        testId={`${idDirectory.textField}-contact-first-name`}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <EditLabel
                                    label="Contact Last Name"
                                    testId={`${idDirectory.labelHeader}-contact-last-name`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={
                                            merchantInfo.contact?.lname ||
                                            'Unavailable'
                                        }
                                        testId={`${idDirectory.textField}-contact-last-name`}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <EditLabel
                                    label="Contact Phone"
                                    testId={`${idDirectory.labelHeader}-contact-phone`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={
                                            merchantInfo.contact
                                                ?.phone_number || 'Unavailable'
                                        }
                                        testId={`${idDirectory.textField}-contact-phone`}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <EditLabel
                                    label="Contact Email"
                                    testId={`${idDirectory.labelHeader}-contact-email`}
                                />
                                <div
                                    className={'emp-merchantEdit-merchantValue'}
                                >
                                    <Ellipsify
                                        value={
                                            merchantInfo.contact?.email ||
                                            'Unavailable'
                                        }
                                        testId={`${idDirectory.textField}-contact-email`}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Grid
                    container
                    item
                    xs={4}
                    className={'emp-merchantEdit-rightGrid'}
                >
                    <Grid item xs={12}>
                        <EditLabel
                            label="Direct API"
                            testId={`${idDirectory.labelHeader}-direct-api`}
                        />
                        <Grid
                            container
                            alignItems="center"
                            className={'emp-merchantEdit-innerGrid'}
                        >
                            <Grid container item xs={6}>
                                <UserStatus
                                    status={merchantInfo.direct_api}
                                    testId={`${idDirectory.statusField}-direct-api`}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                justifyContent="flex-end"
                            >
                                <Switch
                                    className={'emp-merchantEdit-switchBtn'}
                                    checked={merchantInfo.direct_api}
                                    name={'direct-api'}
                                    size={'small'}
                                    disabled={true}
                                    id={`${idDirectory.switchField}-direct-api`}
                                    color="secondary"
                                />
                            </Grid>
                        </Grid>
                        <Divider style={{ marginBottom: 8 }} />
                        {SHOW_ISSUER_DOC_TOGGLE.enabled && (
                            <>
                                <EditLabel
                                    label="Issuer Docs"
                                    testId={`${idDirectory.labelHeader}-has-issuer-docs`}
                                />
                                <Grid
                                    container
                                    alignItems="center"
                                    className={'emp-merchantEdit-innerGrid'}
                                >
                                    <Grid container item xs={6}>
                                        <UserStatus
                                            status={hasIssuerDocInFeatures}
                                            testId={`${idDirectory.statusField}-has-issuer-docs`}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        justifyContent="flex-end"
                                    >
                                        <Switch
                                            className={
                                                'emp-merchantEdit-switchBtn'
                                            }
                                            checked={hasIssuerDocInFeatures}
                                            name={'hasIssuerDocs'}
                                            size={'small'}
                                            disabled={true}
                                            id={`${idDirectory.switchField}-has-issuer-docs`}
                                            color="secondary"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginBottom: 8 }} />
                            </>
                        )}
                        <EditLabel
                            label="Merchant Service Level"
                            testId={`${idDirectory.labelHeader}-merchant-service-level`}
                        />
                        <RadioGroup
                            aria-label="service level"
                            name={'merchant-service-level'}
                            value={merchantInfo.service_level?.id}
                            onChange={updateServiceLevel}
                            className={'emp-merchantEdit-radiosContainer'}
                        >
                            {MERCHANT_SERVICE_LEVELS.basic_service_enabled && (
                                <FormControlLabel
                                    value={1}
                                    control={<Radio color="secondary" />}
                                    label="Basic Service"
                                    disabled={true}
                                    id={`${idDirectory.radioField}-basic-service`}
                                />
                            )}
                            {MERCHANT_SERVICE_LEVELS.pro_service_enabled && (
                                <FormControlLabel
                                    value={5}
                                    control={<Radio color="secondary" />}
                                    label="Pro Service"
                                    disabled={true}
                                    id={`${idDirectory.radioField}-pro-service`}
                                />
                            )}
                            {MERCHANT_SERVICE_LEVELS.full_service_enabled && (
                                <FormControlLabel
                                    value={3}
                                    control={<Radio color="secondary" />}
                                    label="Full Service"
                                    disabled={true}
                                    id={`${idDirectory.radioField}-full-service`}
                                />
                            )}
                        </RadioGroup>
                        {DEFLECTORS.enabled && (
                            <>
                                <Divider style={{ marginBottom: 8 }} />
                                {availableDeflectors?.map(
                                    (availableDeflector, idx) => {
                                        // Statuses 1: off - 2: pending - 3: active
                                        let foundDeflector = undefined as any
                                        try {
                                            foundDeflector = merchantInfo.deflectors.find(
                                                (obj: any) => {
                                                    return (
                                                        obj.id ===
                                                        availableDeflector.id
                                                    )
                                                }
                                            )
                                        } catch (e) {}

                                        return (
                                            <div key={`key-deflector-${idx}`}>
                                                <EditLabel
                                                    label={
                                                        availableDeflector.name
                                                    }
                                                    testId={`${idDirectory.labelHeader}-${availableDeflector.name}`}
                                                />
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    className={
                                                        'emp-merchantEdit-innerGrid'
                                                    }
                                                >
                                                    <Grid container item xs={6}>
                                                        <AlertsDeflectorStatus
                                                            status={
                                                                foundDeflector
                                                                    ? foundDeflector
                                                                          ?.status
                                                                          ?.id
                                                                    : 1
                                                            }
                                                            label={
                                                                foundDeflector
                                                                    ? foundDeflector
                                                                          ?.status
                                                                          ?.id ===
                                                                      2
                                                                        ? 'Pending Activation'
                                                                        : foundDeflector
                                                                              ?.status
                                                                              ?.id ===
                                                                          3
                                                                        ? 'Active'
                                                                        : 'Inactive'
                                                                    : 'Inactive'
                                                            }
                                                            testId={`${idDirectory.statusField}-${availableDeflector.name}`}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={6}
                                                        justifyContent="flex-end"
                                                    >
                                                        <Tooltip
                                                            title={
                                                                isRootMerchantSelected
                                                                    ? ''
                                                                    : 'Click the edit button to modify'
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <span>
                                                                <Switch
                                                                    className={
                                                                        'emp-merchantEdit-switchBtn'
                                                                    }
                                                                    checked={
                                                                        foundDeflector
                                                                            ? foundDeflector
                                                                                  ?.status
                                                                                  ?.id ===
                                                                              1
                                                                                ? false
                                                                                : true
                                                                            : false
                                                                    }
                                                                    name={
                                                                        availableDeflector.alias
                                                                    }
                                                                    size={
                                                                        'small'
                                                                    }
                                                                    color="secondary"
                                                                    disabled
                                                                    id={`${idDirectory.switchField}-${availableDeflector.name}`}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
            />
        </>
    )
}

export default MerchantEdit
