import React, { useState } from 'react'
import { Button } from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'
import { SearchableSelect, ConfirmActionModal } from 'components'
import { BulkCloseParam, Param, useBulkClose } from './useBulkClose'
import { PopoverRangeSelect } from './PopoverRangeSelect'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import idDirectory from './idAttributes'

interface BulkCloseProps {
    testId?: string
}

export const BulkClose: React.FC<BulkCloseProps> = ({ testId }) => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const {
        getParamByKey,
        params,
        addParam,
        submit,
        confirm,
        removeParam,
        setCurrenciesSearchValue,
        currenciesSearchValue,
        currencies,
        setCyclesSearchValue,
        cyclesSearchValue,
        cycles,
        setReasonCodesSearchValue,
        reasonCodesSearchValue,
        reasonCodes,
        jobConfirmation,
        cancel,
        isSubmitDisabled,
        jobSubmitted,
        currentScheduledJob,
        lastScheduledJob,
    } = useBulkClose()

    const handleConfirm = () => {
        confirm()
            .then(() => {
                setAlertSnackbarProps({
                    intent: 'success',
                    message: 'Successfully submitted job',
                    title: 'Success',
                })
            })
            .catch(() => {
                setAlertSnackbarProps({
                    intent: 'error',
                    message: 'Error confirming job',
                    title: 'Error',
                })
            })
            .finally(() => {
                setAlertSnackbarOpen(true)
                cancel()
            })
    }

    return (
        <div
            className={'emp-bulkClose-root'}
            id={`${idDirectory.bulkClose.divRoot}-${testId}`}
        >
            <div
                id={`${idDirectory.bulkClose.divCriteria}-${testId}`}
                className={'emp-bulkClose-criteriaContainer'}
            >
                <div
                    id={`${idDirectory.bulkClose.divCriteriaLabel}-${testId}`}
                    className={'emp-bulkClose-label'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    Criteria
                </div>
                <div
                    id={`${idDirectory.bulkClose.divSelectors}-${testId}`}
                    className={'emp-bulkClose-selectors'}
                >
                    <PopoverRangeSelect
                        placeholder="Amount"
                        min={
                            getParamByKey(BulkCloseParam.AMOUNT)?.value.min ===
                            'undefined'
                                ? ''
                                : getParamByKey(BulkCloseParam.AMOUNT)?.value
                                      .min
                        }
                        max={
                            getParamByKey(BulkCloseParam.AMOUNT)?.value.max ===
                            'undefined'
                                ? ''
                                : getParamByKey(BulkCloseParam.AMOUNT)?.value
                                      .max
                        }
                        onChange={(range) =>
                            addParam(
                                BulkCloseParam.AMOUNT,
                                range,
                                `${range?.min?.toFixed(
                                    2
                                )} - ${range?.max?.toFixed(2)}`
                            )
                        }
                        disabled={Boolean(currentScheduledJob)}
                        required
                        setAlertSnackbarProps={setAlertSnackbarProps}
                        setAlertSnackbarOpen={setAlertSnackbarOpen}
                        testId={'amount'}
                    />
                    <SearchableSelect
                        placeholder="Currency"
                        value={
                            getParamByKey(BulkCloseParam.CURRENCY)
                                ?.displayValue === 'undefined - undefined'
                                ? ''
                                : getParamByKey(BulkCloseParam.CURRENCY)
                                      ?.displayValue
                        }
                        onValueChange={(value: any) =>
                            addParam(BulkCloseParam.CURRENCY, value.currency)
                        }
                        options={currencies as any}
                        onSearchChange={setCurrenciesSearchValue}
                        searchValue={currenciesSearchValue}
                        accessor="currency"
                        disabled={Boolean(currentScheduledJob)}
                        required
                        testId={'currency'}
                    />
                    <SearchableSelect
                        placeholder="Cycle"
                        value={
                            getParamByKey(BulkCloseParam.CYCLE)
                                ?.displayValue === 'undefined - undefined'
                                ? ''
                                : getParamByKey(BulkCloseParam.CYCLE)
                                      ?.displayValue
                        }
                        onValueChange={(value: any) =>
                            addParam(BulkCloseParam.CYCLE, value.name)
                        }
                        options={cycles as any}
                        onSearchChange={setCyclesSearchValue}
                        searchValue={cyclesSearchValue}
                        accessor="name"
                        disabled={Boolean(currentScheduledJob)}
                        required
                        testId={'cycle'}
                    />
                    <SearchableSelect
                        placeholder="Reason Code"
                        value={
                            getParamByKey(BulkCloseParam.REASON_CODE)
                                ?.displayValue === 'undefined - undefined'
                                ? ''
                                : getParamByKey(BulkCloseParam.REASON_CODE)
                                      ?.displayValue
                        }
                        onValueChange={(value: any) =>
                            addParam(
                                BulkCloseParam.REASON_CODE,
                                value.code,
                                `${value.card_type?.name ?? 'N/A'} - ${
                                    value.code
                                }`
                            )
                        }
                        options={reasonCodes as any}
                        onSearchChange={setReasonCodesSearchValue}
                        searchValue={reasonCodesSearchValue}
                        accessor="name"
                        disabled={Boolean(currentScheduledJob)}
                        required
                        testId={'reasonCode'}
                    />
                </div>
            </div>
            <div
                className={'emp-bulkClose-appliedCriteriaContainer'}
                id={`${idDirectory.bulkClose.divAppliedCriteria}-${testId}`}
            >
                <div
                    id={`${idDirectory.bulkClose.divAppliedCriteriaLabel}-${testId}`}
                    className={'emp-bulkClose-label'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    Applied Criteria
                </div>
                <div
                    id={`${idDirectory.bulkClose.divBadges}-${testId}`}
                    className={'emp-bulkClose-badges'}
                >
                    {params
                        .filter((param) => param.value)
                        .map((i) => (
                            <Badge
                                key={i.name}
                                param={i}
                                onClear={(i) => removeParam(i.name)}
                                disabled={Boolean(currentScheduledJob)}
                                testId={i.name}
                            />
                        ))}
                </div>
            </div>
            <div
                id={`${idDirectory.bulkClose.divActions}-${testId}`}
                className={'emp-bulkClose-actionsContainer'}
            >
                <div
                    id={`${idDirectory.bulkClose.divStatusText}-${testId}`}
                    className={'emp-bulkClose-statusText'}
                >
                    {currentScheduledJob && (
                        <span
                            id={`${idDirectory.bulkClose.textCurrentScheduledJob}-${testId}`}
                        >
                            <i>{currentScheduledJob}</i>
                        </span>
                    )}
                    {lastScheduledJob && (
                        <span
                            id={`${idDirectory.bulkClose.textLastScheduledJob}-${testId}`}
                        >
                            <i>{lastScheduledJob}</i>
                        </span>
                    )}
                </div>
                {!jobSubmitted ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={submit}
                        disabled={isSubmitDisabled}
                        id={`${idDirectory.bulkClose.btnRunAction}-${testId}`}
                    >
                        Run Action
                    </Button>
                ) : (
                    <div
                        id={`${idDirectory.bulkClose.divJobSubmittedMsg}-${testId}`}
                        className={'emp-bulkClose-jobSubmittedMessage'}
                    >
                        Your request has been submitted for processing.
                    </div>
                )}
            </div>
            {Boolean(jobConfirmation) && !jobSubmitted && (
                <ConfirmActionModal
                    open={true}
                    toggleOpen={cancel}
                    onConfirm={handleConfirm}
                    header={`Confirm Action`}
                    message={
                        jobConfirmation?.records_num
                            ? `This will affect ${
                                  jobConfirmation?.records_num
                              } record${
                                  jobConfirmation?.records_num !== 1 ? 's' : ''
                              }.`
                            : `No records will be affected. Please try different criteria.`
                    }
                    confirmButtonText={'Accept'}
                    inactivateConfirmButton={
                        !jobConfirmation?.records_num || jobSubmitted
                    }
                    testId={`bulkClose-${testId}`}
                />
            )}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

interface BadgeProps {
    param: Param
    testId: string
    onClear?: (badge: Param) => void
    disabled?: boolean
}

const Badge: React.FC<BadgeProps> = ({
    param,
    testId,
    onClear = () => {},
    disabled = false,
}) => {
    return (
        <div
            id={`${idDirectory.badge.divRoot}-${testId}`}
            className={'emp-bulkClose-badgeRoot'}
            style={{
                fontSize: clientTheme.typography.body1.fontSize,
                color: disabled ? 'gray' : 'inherit',
                border: disabled
                    ? 'solid 1px gray'
                    : 'solid 1px rgb(98, 194, 234)',
            }}
        >
            <div
                className={'emp-bulkClose-badgeText'}
                id={`${idDirectory.badge.divBadgeText}-${testId}`}
            >
                <span>{`${param.label}: `}</span>
                {param.displayValue}
            </div>
            <Icon
                id={`${idDirectory.badge.iconBadgeClose}-${testId}`}
                icon={faTimes}
                className={'emp-bulkClose-badgeCloseIcon'}
                onClick={() => {
                    if (!disabled) {
                        onClear(param)
                    }
                }}
                style={{ cursor: disabled ? 'default' : 'pointer' }}
            />
        </div>
    )
}
