import React, { useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from '@mui/material'
import { CaseFilters, useCaseFilterValue } from './useCaseFilterValue'
import { ConfirmActionModal } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

const FILTERS = {
    RETRIEVALS_RFIS: 'Retrievals (RFIs)',
    PRIMARY_CYCLE: 'Primary Cycle',
    SECONDARY_CYCLE: 'Secondary Cycle',
    PRIMARY_FRAUD: 'Primary Fraud',
    PRIMARY_NONFRAUD: 'Primary Non-Fraud',
    PRIMARY_CASES_DUE_TO_EXPIRE_IN: 'Primary Cases Due to Expire in',
} as const

type TFilterType = typeof FILTERS[keyof typeof FILTERS]

export interface ICaseFilterProps {
    title: string
    onClose: () => void
    defaultPayload: { [key: string]: any }
    handleChangeCaseFilter: (
        values: Record<string, string | boolean | number | null>
    ) => void
    handleClearCaseFilter: () => void
    isActiveAdvSearchFilters: boolean
    handleClearAdvancedSearch: () => void
    setMetricPresets: (value: any) => void
    setAlertFilterOpen: (value: boolean) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    showPrimaryCasesExpiringXDays: boolean
    selectedTabFilterGroup?: string | null
    className?: string
}

/**
 * Getting counts and allowing actions on filtering of case statuses
 * Really this should use chips, but the reference implementation uses a table
 */
const CaseFilter = ({
    title,
    onClose,
    defaultPayload,
    handleChangeCaseFilter,
    handleClearCaseFilter,
    isActiveAdvSearchFilters,
    handleClearAdvancedSearch,
    setMetricPresets,
    setAlertFilterOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    showPrimaryCasesExpiringXDays,
    selectedTabFilterGroup = null,
    className = '',
}: ICaseFilterProps) => {
    const [selectedFilter, setSelectedFilter] = useState<TFilterType | ''>('')
    const [daysInputField, setDaysInputField] = useState<number>(7)
    const [daysValue, setDaysValue] = useState<number>(7)

    const rfis = useCaseFilterValue(
        defaultPayload,
        CaseFilters.RFIS,
        selectedTabFilterGroup
    )
    const primaryCycles = useCaseFilterValue(
        defaultPayload,
        CaseFilters.Primary,
        selectedTabFilterGroup
    )
    const secondaryCycles = useCaseFilterValue(
        defaultPayload,
        CaseFilters.Secondary,
        selectedTabFilterGroup
    )
    const primaryFrauds = useCaseFilterValue(
        defaultPayload,
        CaseFilters.Primary,
        selectedTabFilterGroup,
        1
    )
    const primaryNonFrauds = useCaseFilterValue(
        defaultPayload,
        CaseFilters.Primary,
        selectedTabFilterGroup,
        0
    )
    const primaryExpiredCases = useCaseFilterValue(
        defaultPayload,
        null,
        selectedTabFilterGroup,
        null,
        daysValue
    )
    const [openCaseFilterAlert, setOpenCaseFilterAlert] = useState<boolean>(
        false
    )

    const filters = [
        {
            name: FILTERS.RETRIEVALS_RFIS,
            data: rfis,
        },
        {
            name: FILTERS.PRIMARY_CYCLE,
            data: primaryCycles,
        },
        {
            name: FILTERS.SECONDARY_CYCLE,
            data: secondaryCycles,
        },
        {
            name: FILTERS.PRIMARY_FRAUD,
            data: primaryFrauds,
        },
        {
            name: FILTERS.PRIMARY_NONFRAUD,
            data: primaryNonFrauds,
        },
    ]

    const handleChangeFilter = async (value: TFilterType | '') => {
        isActiveAdvSearchFilters && (await handleClearAdvancedSearch())
        setSelectedFilter(value)

        switch (value) {
            case FILTERS.RETRIEVALS_RFIS:
                handleChangeCaseFilter({
                    cycle: '9',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'Retrievals Request',
                                id: 9,
                                value: 9,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case FILTERS.PRIMARY_CYCLE:
                handleChangeCaseFilter({
                    cycle: '1',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case FILTERS.SECONDARY_CYCLE:
                handleChangeCaseFilter({
                    cycle: '2',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'Second Chargeback',
                                id: 2,
                                value: 2,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case FILTERS.PRIMARY_FRAUD:
                handleChangeCaseFilter({
                    cycle: '1',
                    is_fraud: 1,
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case FILTERS.PRIMARY_NONFRAUD:
                handleChangeCaseFilter({
                    cycle: '1',
                    is_fraud: 0,
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case FILTERS.PRIMARY_CASES_DUE_TO_EXPIRE_IN:
                handleChangeCaseFilter({
                    filter_group: selectedTabFilterGroup
                        ? `primary_case_expire,${selectedTabFilterGroup}`
                        : `primary_case_expire`,
                    primary_case_due_days: daysInputField,
                })
                break
            default:
                return
        }
        setOpenCaseFilterAlert(false)
    }

    const handleReset = async () => {
        isActiveAdvSearchFilters && (await handleClearAdvancedSearch())
        setSelectedFilter('')
        handleClearCaseFilter()
    }

    const handleErrorPrimaryCasesDueDays = () => {
        setAlertSnackbarMainProps({
            message: 'Primary cases due to expire cannot be 0 days.',
            title: 'Error.',
            intent: 'error',
        })
        setAlertSnackbarMainOpen(true)
        return
    }

    const sendDayValue = useCallback(
        debounce((value) => {
            setDaysValue(value)
        }, 500),
        []
    )

    return (
        <div>
            <Paper elevation={0} className={`emp-caseFilter-root ${className}`}>
                <TableContainer>
                    <Table size="small" aria-label="filters">
                        <TableHead className={'emp-caseFilter-header'}>
                            <TableRow>
                                <TableCell component="th">{title}</TableCell>
                                <TableCell component="th" align="right">
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filters?.map(({ name, data }) => (
                                <TableRow
                                    onClick={() => {
                                        if (isActiveAdvSearchFilters) {
                                            setOpenCaseFilterAlert(true)
                                            setSelectedFilter(name)
                                        } else {
                                            handleChangeFilter(name)
                                        }
                                    }}
                                    className={`emp-caseFilter-table-row ${
                                        selectedFilter === name &&
                                        'emp-caseFilter-selectedRow'
                                    }`}
                                    key={name}
                                >
                                    <TableCell
                                        className={'emp-caseFilter-listItem'}
                                    >
                                        <p>{name}</p>
                                    </TableCell>
                                    <TableCell align="right">
                                        {data.loading ? (
                                            <Skeleton variant="rectangular" />
                                        ) : (
                                            Number(
                                                data.totalCases
                                            ).toLocaleString('en-US')
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {showPrimaryCasesExpiringXDays && (
                                <TableRow
                                    className={'emp-caseFilter-table-row'}
                                >
                                    <TableCell
                                        className={'emp-caseFilter-listItem'}
                                    >
                                        <p>
                                            Primary Cases Due to Expire in
                                            <br />
                                            <input
                                                id="dayValue"
                                                value={daysInputField}
                                                className={
                                                    'emp-caseFilter-dayInput'
                                                }
                                                onChange={(e) => {
                                                    const days = +e.target.value
                                                    if (isNaN(days)) return
                                                    if (!days) {
                                                        handleErrorPrimaryCasesDueDays()
                                                    } else {
                                                        sendDayValue(days)
                                                    }
                                                    setDaysInputField(days)
                                                }}
                                            />
                                            <span>day(s)</span>
                                            <FontAwesomeIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    backgroundColor: '#afafaf',
                                                    padding: '5px',
                                                    color: 'white',
                                                    borderRadius: '4px',
                                                    marginLeft: '5px',
                                                }}
                                                icon={faSearch}
                                                onClick={() => {
                                                    if (!daysInputField)
                                                        return handleErrorPrimaryCasesDueDays()

                                                    if (
                                                        isActiveAdvSearchFilters
                                                    ) {
                                                        setOpenCaseFilterAlert(
                                                            true
                                                        )
                                                        setSelectedFilter(
                                                            FILTERS.PRIMARY_CASES_DUE_TO_EXPIRE_IN
                                                        )
                                                    } else {
                                                        handleChangeFilter(
                                                            FILTERS.PRIMARY_CASES_DUE_TO_EXPIRE_IN
                                                        )
                                                    }
                                                }}
                                            />
                                        </p>
                                    </TableCell>
                                    <TableCell align="right">
                                        {primaryExpiredCases.loading ? (
                                            <Skeleton variant="rectangular" />
                                        ) : (
                                            Number(
                                                primaryExpiredCases.totalCases
                                            ).toLocaleString('en-US')
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={`emp-caseFilter-actions filter-action-area`}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onClose()}
                    >
                        Close
                    </Button>
                </div>
            </Paper>
            <ConfirmActionModal
                open={openCaseFilterAlert}
                toggleOpen={() => setOpenCaseFilterAlert((prev) => !prev)}
                onConfirm={() => {
                    handleChangeFilter(selectedFilter)
                }}
                header={'Apply Case Filters'}
                message={
                    'Applying the selected case filter will overwrite any applied advanced search parameters.'
                }
                confirmButtonText={'Continue'}
                testId={'case-filter'}
            />
        </div>
    )
}

export default CaseFilter
