import React, { useState, useEffect } from 'react'
import UsersApi from 'api/UsersApi'
import { ModalArea, SwitchList, AlertSnackbar } from 'components'
import { ISwitchListOption } from 'components/SwitchList/SwitchList'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface ISitePermissionsProps {
    rowData: any
    modalOpen: boolean
    setModalOpen: (isopen: boolean) => void
}

export const SitePermissions: React.FC<ISitePermissionsProps> = ({
    rowData,
    modalOpen,
    setModalOpen,
}) => {
    const [sitePermissions, setSitePermissions] = useState<ISwitchListOption[]>(
        []
    )
    const [
        loadingSitePermissions,
        setLoadingSitePermissions,
    ] = useState<boolean>(false)
    const [
        updatingSitePermissions,
        setUpdatingSitePermissions,
    ] = useState<boolean>(false)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const userId = rowData?.original?.id

    useEffect(() => {
        if (modalOpen) {
            setLoadingSitePermissions(true)
            UsersApi.getUserSitePermissions(userId)
                .then((data) => {
                    setSitePermissions(data)
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: `An error occurred while loading site permissions. Please try again later.`,
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
                .finally(() => setLoadingSitePermissions(false))
        }
    }, [modalOpen, userId])

    const handleSitePermissionChange = (item: ISwitchListOption): void => {
        const updatedSitePermissions = sitePermissions.map(
            (permission: ISwitchListOption) =>
                item.id === permission.id
                    ? {
                          ...permission,
                          isChecked: !item.isChecked,
                      }
                    : permission
        )

        setUpdatingSitePermissions(true)
        UsersApi.setUserSitePermission(userId, item.id, !item.isChecked)
            .then(() => {
                setSitePermissions(updatedSitePermissions)
                setAlertSnackbarProps({
                    title: 'Success',
                    message: 'Site permission was successfully updated.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `An error occurred updating site permission. Please try again later.`,
                    intent: 'error',
                })
            })
            .finally(() => {
                setAlertSnackbarOpen(true)
                setUpdatingSitePermissions(false)
            })
    }

    return (
        <ModalArea
            title="Site Permissions"
            open={modalOpen}
            closeModal={() => setModalOpen(false)}
            maxWidth="sm"
        >
            <div id={'usersSitePermissions'}>
                {!loadingSitePermissions && !sitePermissions.length ? (
                    <div className={'emp-noSitePermissionsContainer'}>
                        No Site Permissions Found!
                    </div>
                ) : (
                    <SwitchList
                        testId={'usersSitePermissions'}
                        options={sitePermissions}
                        onChange={handleSitePermissionChange}
                        loadingOptions={loadingSitePermissions}
                        updatingOptions={updatingSitePermissions}
                        expectedLength={10}
                    />
                )}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </ModalArea>
    )
}
