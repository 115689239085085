import React from 'react'
import { Switch, FormControlLabel } from '@mui/material'
import { Skeleton } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import idDirectory from './idAttributes'

/**
 * @desc - Material UI Switch at it's core is a checkbox input - FormControlLabel is simply a label.
 * @params name - the human readable name of the switch list option
 * @params key - the object key accessor used in state
 * @params label - the switch list option label
 * @params value - the boolean value of the switch list option
 */
export interface ISwitchListOption {
    id: number
    name: string
    key: string
    label: string
    isChecked: boolean
}

/**
 * @params options - object containing switch list options passed in from the parent
 * @params returnUpdatedSwitchValue - function the parent passes in
 */
export interface ISwitchListProps {
    testId: string
    options: ISwitchListOption[]
    onChange: (item: ISwitchListOption) => void
    loadingOptions: boolean
    updatingOptions?: boolean
    compact?: boolean
    expectedLength?: number
}

/**
 * A way to get toggles with an identity per toggle and extract the current toggles state
 */
const SwitchList = ({
    testId,
    options,
    onChange,
    loadingOptions,
    updatingOptions = false,
    compact = true,
    expectedLength = 20,
}: ISwitchListProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const listOptions = loadingOptions
        ? Array.from({ length: expectedLength }, (_, index) => ({
              id: index + 1,
              name: '',
              key: '',
              label: '',
              isChecked: false,
          }))
        : options

    return (
        <div
            id={`${idDirectory.divRoot}-${testId}`}
            className={`${'emp-switchList-container'} ${
                compact ? 'emp-switchList-compact' : ''
            }`}
        >
            {listOptions.map((item: ISwitchListOption) => (
                <FormControlLabel
                    key={`key-${item?.id}`}
                    id={`${idDirectory.formLabel}-${testId}`}
                    control={
                        <Switch
                            key={`key-${item?.id}`}
                            checked={loadingOptions ? false : item?.isChecked}
                            disabled={
                                loadingOptions || isReadOnly || updatingOptions
                            }
                            onChange={(): void => onChange(item)}
                            name={item?.name}
                            color="primary"
                            sx={[
                                {
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: clientTheme.switch,
                                    },
                                },
                                {
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: clientTheme.switch,
                                    },
                                },
                            ]}
                        />
                    }
                    label={
                        loadingOptions ? <Skeleton width={100} /> : item?.label
                    }
                    className={'emp-switchList-formLabel'}
                />
            ))}
        </div>
    )
}

export default SwitchList
