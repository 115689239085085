import React from 'react'
import FlexAForm from './FlexAForm'
import { IAdvancedFilterConfig, IFormField } from 'hooks/cb-api'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

const formatAdvancedFilterFields = (
    fields: IAdvancedFilterConfig[],
    userRoleId: number
): IFormField[] => {
    return fields
        .filter(
            (field: IAdvancedFilterConfig) =>
                field.is_enabled &&
                (!field.roles ||
                    !field.roles?.length ||
                    field.roles?.includes(userRoleId))
        )
        .flatMap((field: IAdvancedFilterConfig) => {
            const baseField: IFormField = {
                key: field.alias ?? '',
                filterOverrideName: field.alias_override,
                name: field.label ?? '',
                initialValue: field.initial_value ?? '',
                type: field.type,
                apiValuesPath: field.endpoint,
                defaultValueOptions: field.default_options,
                disableMultipleSelections: !field.is_multiple_choice,
                clearDateIcon: field.is_clear_date_icon,
                hideField: false,
            }

            // For 'amount' type fields, add a comparison operator field
            if (field.type === 'amount') {
                const comparisonField: IFormField = {
                    key: field.alias ? `${field.alias}_op` : '',
                    filterOverrideName: field.alias_override
                        ? `${field.alias_override}_op`
                        : undefined,
                    name: '',
                    initialValue: field.initial_compare_value ?? 'eq',
                    type: 'select',
                    apiValuesPath: undefined,
                    defaultValueOptions: undefined,
                    disableMultipleSelections: false,
                    clearDateIcon: false,
                    hideField: true,
                }
                return [baseField, comparisonField]
            }
            return [baseField]
        })
}

export const useFormattedAdvancedFilterFields = (
    fields: IAdvancedFilterConfig[]
) => {
    const { user } = useAuthedUser()
    const userRoleId = user?.role?.id ?? 0

    const updatedAdvancedFilterFields = formatAdvancedFilterFields(
        fields,
        userRoleId
    )

    return {
        updatedAdvancedFilterFields,
    }
}

interface IAdvancedFiltersProps {
    className?: string
    id?: string
    onSubmit: (value: Record<string, string>) => void
    fields: IFormField[]
    metricPresets?: any[] | null
    validate: () => Record<string, string>
    initialValues: Record<string, string>
    onClear: () => void
    selectedTabFilter?: string | number
    isActiveAdvSearchFilters?: boolean
    setIsLoadingAdvFilterSelect?: (loading: boolean) => void
    inactivateSearchBtn?: boolean
    caseFilterOn?: boolean
}

/**
 * Render the various advanced filters for Chargebacks
 * @note Mocked, not operational
 * @todo Complete this functionality
 */
const AdvancedFilters = ({
    className = '',
    id,
    onSubmit,
    selectedTabFilter = '',
    ...rest
}: IAdvancedFiltersProps) => {

    return (
        <>
            <FlexAForm
                {...rest}
                onSubmit={onSubmit}
                selectedTabFilter={selectedTabFilter}
            />
        </>
    )
}

export default AdvancedFilters
