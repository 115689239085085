import axios from 'axios'
import { faUpload, faEdit } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'
import { useActiveMerchant } from 'components'
import { useEffect, useState , useMemo} from 'react'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { CaseData, AdvancedSearchValues, SetSortInfo } from 'hooks/cb-api/types'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { useFormattedAdvancedFilterFields } from 'components/AdvancedFilters/AdvancedFilters'

// SWR
import {
    useSWRActions,
    SWRActions,
    useCaseActions,
    useSwrData,
    useParams,
    IFormField,
    IAdvancedFilterConfig,
    ModalVariant,
    ISelectedRows,
} from 'hooks/cb-api'

type THandleSubmitAdvancedSearch = (
    params: AdvancedSearchValues,
    reload: boolean
) => void

type THandleChangeCaseFilter = (params: any) => void

interface BulkActionParams {
    /** indexs of selected rows */
    selectedRows: string[]
}

type THandleBulkAction = (params: BulkActionParams) => void

interface ChargebacksInstance {
    activeMerchantId: number
    cases: CaseData[]
    error: string | null
    advanceSearchFields: IFormField[]
    handleSubmitAdvancedSearch: THandleSubmitAdvancedSearch
    handleClearAdvancedSearch: ()=>void
    // advancedSearchValues: AdvancedSearchValues
    // advancedSearchParams: any
    setSortInfo: SetSortInfo
    contextMenuItems: any
    isUploadOpen: boolean
    closeUpload: () => void
    selectedCaseIds: number[]
    handleChangeCaseFilter: THandleChangeCaseFilter
    handleClearCaseFilter: () => void
    clearSelectedRows: boolean
    rehydrateView: () => void
    // selectAllRowsInStore: () => void
    // unselectAllRowsInStore: () => void
    canViewPartnerCompanyId: boolean
    canViewIssuerDocs: boolean
    canViewOutcomeVerdicts: boolean
    canViewPlatform: boolean
    disableSelectAll: boolean
    swrActions: SWRActions
    defaultPayload: { [key: string]: any }
    [key: string]: any
    metricPresets: any
    setMetricPresets: (value: any) => void
    collapseOpen: boolean
    setCollapseOpen: (isOpen: boolean) => void
    params: any
    isLoadingAdvFilterSelect: boolean
    setIsLoadingAdvFilterSelect: (loading: boolean) => void
    openEditChargebacksModal: boolean
    setOpenEditChargebacksModal: (isOpen: boolean) => void
    isLoadingAdvFilterFields: boolean
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (isOpen: boolean) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    setAlertSnackbarMainProps: (props: alertSnackbarContentProps) => void
}

export const useChargebacks = ({ ...props }: any): ChargebacksInstance => {
    const history = useHistory()
    const { form_field_variant } = useFeatureToggle('CHARGEBACKS')

    const { client } = useFeatureToggle('CLIENT')
    const [selectedCaseIds, setSelectedCaseIds] = useState<number[]>([])
    const [openRepresentmentUploadModal, setOpenRepresentmentModal] = useState(
        false
    )
    const [openEditChargebacksModal, setOpenEditChargebacksModal] = useState(
        false
    )

    const uiState = useUiStateContext()

    const { id: merchantId } = useActiveMerchant()
    const [clearSelectedRows, setClearSelectedRows] = useState(false)
    const [didSort, setDidSort] = useState(false)
    const [metricPresets, setMetricPresets] = useState(null)
    const [collapseOpen, setCollapseOpen] = useState(false)
    const [isLoadingAdvFilterSelect, setIsLoadingAdvFilterSelect] = useState(false)
    const [isLoadingAdvFilterFields, setIsLoadingAdvFilterFields] = useState(true)
    const [advFilterFields, setAdvFilterFields] = useState<IAdvancedFilterConfig[]>([])

    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    // ** SWR INTEGRATION BELOW
    const { params, setParams, setQueryParams, reset } = useParams({
        sort_order: 'desc',
        sort_by: 'post_date',
    })

    const defaultPayloadMemo = useMemo(() => {
        return {
            client_id: merchantId,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])

    const endpointConfig = {
        paramInUrl: false,
        url: 'cm/cases',
        id: {
            key: 'client_id',
            value: merchantId,
        },
    }

    const chargebacksData = useSwrData(endpointConfig, params)
    const paramSetter = { setParams, setQueryParams, params, reset }

    const swrActions = useSWRActions(chargebacksData, paramSetter)
    const caseActions = useCaseActions(chargebacksData, paramSetter)

    const extendedSwrActions = { ...swrActions, ...caseActions }

    const { mutate, error } = chargebacksData

    const {
        setQueryParams: setQueryParamsSwrInstance,
        caseNetworkActions,
    } = extendedSwrActions

    const {
        submitAdvancedSearchValues,
        clearAdvancedSearchValues,
    } = caseNetworkActions

    // For JPMC and only if cm-view-partner-admin-id in whoami roles.
    const canViewPartnerCompanyId =
        (uiState.whoami?.roles ?? []).filter(
            (role) => role === 'cm-view-partner-admin-id'
        ).length !== 0
            ? true
            : false

    const canViewIssuerDocs =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 1
        ) ?? false

    const canViewOutcomeVerdicts =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 2
        ) ?? false

    const canViewPlatform =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 3
        ) ?? false

    useEffect(() => {
        setIsLoadingAdvFilterFields(true)
        axios
            .get(`/config/cfg/cases_advanced_filters`)
            .then(({ data }) => {
                const configFilters = data?.attr?.filters ?? []
                setAdvFilterFields(configFilters)
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: `An error occurred while loading the advanced search filters. Please try again later.`,
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => setIsLoadingAdvFilterFields(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { updatedAdvancedFilterFields } = useFormattedAdvancedFilterFields(
        advFilterFields
    )

    const FORM_FIELDS = (): IFormField[] => {
        let filteredAdvancedSearchFilters = updatedAdvancedFilterFields
        
        const foundVerdictFilter = filteredAdvancedSearchFilters.find(
            (obj: { key: string }) => obj.key === 'verdict'
        )
        if (foundVerdictFilter && !canViewOutcomeVerdicts) {
            filteredAdvancedSearchFilters = filteredAdvancedSearchFilters.filter(
                (obj: { key: string }) => obj.key !== 'verdict'
            )
        }

        if (form_field_variant === 'jpmc' && canViewPartnerCompanyId) {
            return [
                ...filteredAdvancedSearchFilters,
                {
                    key: 'enterprise_company_id',
                    name: 'Enterprise Company ID',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: undefined,
                    apiValuesPath: undefined,
                    hideField: false,
                    defaultValueOptions: undefined,
                    clearDateIcon: false,
                    disableMultipleSelections: true,
                },
            ]
        } else return filteredAdvancedSearchFilters
    }

    useEffect(() => {
        if (props.location.state?.preset_filter && advFilterFields.length) {
            setMetricPresets(props.location.state.preset_filter)
            // setCollapseOpen(true)
        }
    }, [props.location, advFilterFields])

    // check if we have a case error after sorting - didSort is set in the setSortInfo function
    // if the store returns an error after sorting, we set the sortBy accessor to an empty string
    useEffect(() => {
        if (error && didSort) {
            extendedSwrActions.setSortBy(false, '')
            setDidSort(false)
        }
        return () => setDidSort(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const handleChangeCaseFilter: THandleChangeCaseFilter = (params) => {
        const initialState = {
            cycle: '',
            is_fraud: '',
            filter_group: '',
            primary_case_due_days: '',
        }
        setQueryParamsSwrInstance({ ...initialState, ...params })
    }

    const handleClearCaseFilter = () => {
        handleChangeCaseFilter({})
    }

    const handleSubmitAdvancedSearch: THandleSubmitAdvancedSearch = (params) =>
        submitAdvancedSearchValues(params, FORM_FIELDS())

    const handleClearAdvancedSearch = () => {
        clearAdvancedSearchValues()
        history.push({
            state: {},
        })
    }

    const setSortInfo: SetSortInfo = (sortInfo) => {
        setDidSort(true)
        extendedSwrActions.setSortInfo({
            sortBy: sortInfo.sortBy,
            sortDesc: sortInfo.sortDesc ? 'asc' : 'desc',
        })
    }

    const handleBulkUpload: THandleBulkAction = () => {
        const caseIds = chargebacksData.data
            .filter((_: any, idx: number) =>
                swrActions.selectedRows.includes(idx.toString())
            )
            .map((i: any) => i.id)
        setSelectedCaseIds(caseIds)
        setOpenRepresentmentModal(true)
    }

    const handleBulkEdit: THandleBulkAction = () => {
        const caseIds = chargebacksData.data
            .filter((_: any, idx: number) =>
                swrActions.selectedRows.includes(idx.toString())
            )
            .map((i: any) => i.id)
        setSelectedCaseIds(caseIds)
        setOpenEditChargebacksModal(true)
    }

    const isContextMenuItemDisabled = (
        selectedRows: ISelectedRows,
        variant: ModalVariant
    ): boolean => {
        const { rows, rowSelections } = selectedRows
        const selectedRowsPropertyStatus = rows
            .filter((row: any) => rowSelections.includes(row.id))
            .map((row: any) => {
                if ('uploadDocuments' === variant) {
                    return Boolean(
                        row.original?.properties
                            ?.is_upload_representment_enabled
                    )
                }
                return false
            })

        const isAnyRowDisabled = selectedRowsPropertyStatus.some(
            (isEnabled: boolean) => !isEnabled
        )
        return isAnyRowDisabled
    }

    const contextMenuItems =
        client === 'bluesnap'
            ? [
                  {
                      value: 'Bulk Edit',
                      icon: faEdit,
                      operator: handleBulkEdit,
                  },
              ]
            : [
                  {
                      value: 'Bulk Upload',
                      icon: faUpload,
                      operator: handleBulkUpload,
                      isDisabled: (selectedRows: ISelectedRows) =>
                          isContextMenuItemDisabled(
                              selectedRows,
                              'uploadDocuments'
                          ),
                  },
              ]

    const rehydrateView = () => {
        mutate()
        setClearSelectedRows(true)
    }

    return {
        // SWR INTEGRATION
        activeMerchantId: +merchantId,
        contextMenuItems: contextMenuItems,
        isUploadOpen: openRepresentmentUploadModal,
        closeUpload() {
            setOpenRepresentmentModal(false)
        },
        canViewPartnerCompanyId,
        canViewIssuerDocs,
        canViewOutcomeVerdicts,
        canViewPlatform,
        rehydrateView,
        cases: chargebacksData?.data || [],
        advanceSearchFields: FORM_FIELDS(),
        handleSubmitAdvancedSearch,
        handleClearAdvancedSearch,
        selectedCaseIds,
        error: chargebacksData.error,
        // END SWR INTEGRATION
        handleChangeCaseFilter,
        handleClearCaseFilter,
        clearSelectedRows,
        setSortInfo,
        disableSelectAll: false,
        swrActions: extendedSwrActions,
        defaultPayload: defaultPayloadMemo,
        caseActions,
        metricPresets,
        setMetricPresets,
        collapseOpen,
        setCollapseOpen,
        params,
        isLoadingAdvFilterSelect,
        setIsLoadingAdvFilterSelect,
        openEditChargebacksModal,
        setOpenEditChargebacksModal,
        isLoadingAdvFilterFields,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    }
}
