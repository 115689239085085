import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import BarChart from 'components/BarChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByTransactionAmount
 * @desc Render % of Chargebacks by Transaction Amount
 * @filterValues IReportsFilter
 */
const ByTransactionAmount = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; val: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByTransactionAmount(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BarChart
            chartTitle="% of Chargebacks by Transaction Amount"
            data={data}
            loading={loading}
            barLineColor={clientTheme.charts.barCharts.fill}
            dataValuePercent
            tooltipPercent
            xAxisAngle={true}
        />
    )
}

export default ByTransactionAmount
