import { FeaturePayload } from '../shared'

/**
 * @description Feature configuruation payload for EMP frontend
 *
 * {@link FeaturePayload}
 */

const featuresConfig: FeaturePayload = {
    CLIENT: { client: 'tillpayments' },
    _TEST: {
        enabled: true,
    },
    SIGN_IN_LOGO: {
        enabled: false,
        inverted: false,
    },
    SITE_NAME: {
        site_name: 'Till Payments',
        enabled: true,
    },
    MAIN_TOPBAR: {
        LOGO_IMAGE: {
            enabled: true,
        },
        LOGO_TEXT: {
            enabled: false,
            logo_text: 'Till Payments',
            logo_color: undefined,
        },
        PAGE_TITLE: {
            enabled: false,
        },
        TEXT_TITLE: {
            enabled: false,
            text_title: '',
        },
    },
    MAIN_SIDEBAR: {
        enabled: true,
        client_theme_active: true,
        MERCHANT_NAME: {
            enabled: false,
        },
        NAV_SELECTION_UI: {
            type: 'box',
        },
    },
    MAIN_FOOTER: {
        client_name: 'Till Payments',
        enabled: true,
        positionRight: true,
        footerTitle: `© ${new Date().getFullYear()} - Powered by Chargebacks911`,
        privacy_policy_link: undefined,
    },
    MERCHANT_SWITCHER: {
        enabled: true,
        MERCHANT_LIST: {
            enabled_merchant_alias: false,
        },
    },
    DASHBOARD: {
        METRICS: {
            enabled: true,
            metric_variant: 'central',
        },
        CHARTS: {
            enabled: true,
            pie_chart: {
                variant: 'chargebacksByCardType',
                title: 'Chargebacks By Card Type (This Month)',
            },
        },
    },
    MERCHANT_HIERARCHY: {
        SELECT_SEARCH_TYPE: {
            enabled: true,
        },
        MERCHANT_ALIAS: {
            enabled: false,
            input_edit_label: '',
        },
        MERCHANT_EDIT: {
            enabled: true,
            input_edit_label: 'Partner Company ID',
        },
        MERCHANT_UPDATE: {
            enabled: true,
            input_edit_label: 'Partner Company ID',
        },
        MID_DETAILS: {
            enabled: true,
            input_edit_label: 'MID Alias',
        },
        MID_DETAILS_ID: {
            enabled: true,
            input_edit_label: 'Merchant ID',
        },
        MID_UPDATE: {
            enabled: true,
            input_edit_label: 'MID Alias',
        },
        INHERIT_MERCHANT_SETTING: {
            enabled: false,
        },
        MERCHANT_SERVICE_LEVELS: {
            basic_service_enabled: true,
            pro_service_enabled: false,
            full_service_enabled: true,
        },
        DEFLECTORS: {
            enabled: false,
        },
        SHOW_HELIX_MID: {
            enabled: false,
            input_edit_label: '',
        },
        SHOW_MIDS_WPG_FIELD: {
            enabled: false,
        },
        SHOW_MID_HISTORY: {
            enabled: false,
        },
        SHOW_ISSUER_DOC_TOGGLE: {
            enabled: false,
        },
    },
    CHARGEBACKS: {
        enabled: true,
        form_field_variant: 'tillpayments',
        HELP_LINK: {
            enabled: false,
            text: '',
            url: '',
        },
        DATA_GRID: {
            EDIT_CHARGEBACKS_BUTTON: {
                edit_verdict_only: false,
                disable_status_groups: [],
            },
            CONTEXT_MENU: {
                enabled: true,
            },
        },
        CHARGEBACK_EXPORT_HEADERS: {
            enabled: true,
            headers: [
                { accessor: 'verdict', label: undefined },
                { accessor: 'status_group', label: undefined },
                { accessor: 'id', label: undefined },
                { accessor: 'case_no', label: undefined },
                { accessor: 'order_id', label: undefined },
                { accessor: 'cycle', label: undefined },
                { accessor: 'mid', label: undefined },
                { accessor: 'mid_alias', label: undefined },
                { accessor: 'cc_type', label: undefined },
                { accessor: 'cc_bin', label: undefined },
                { accessor: 'reason_code', label: undefined },
                { accessor: 'reason_category', label: undefined },
                { accessor: 'date_post', label: undefined },
                { accessor: 'date_completed', label: undefined },
                { accessor: 'dispute_amt', label: undefined },
                { accessor: 'dispute_currency', label: undefined },
                { accessor: 'date_trans', label: undefined },
                { accessor: 'trans_amt', label: undefined },
                { accessor: 'cc_last_four', label: undefined },
                { accessor: 'arn', label: undefined },
            ],
        },
    },
    CASE_MANAGEMENT: {
        UPGRADE_ALERT: {
            enabled: false,
            roles: [],
        },
        CASE_MANAGEMENT_EXPORT_HEADERS: {
            enabled: false,
            headers: [],
        },
    },
    HELP: {
        ADD_ONS: {
            client_name: 'Till Payments',
            enabled: true,
            topicTitles: ['Chargebacks'],
            videoURLs: {
                chargebacks:
                    'Navigating_Chargebacks_Using_Advanced_Search_-_Unbranded.mp4',
            },
        },
        USER_DOC: {
            client_name: 'Till Payments',
            enabled: false,
            user_guide_link_is_local_file: false,
            user_guide_link: undefined,
        },
    },
    REPORTS: {
        CHARTS: {
            PARTNER_ADMIN: {
                enabled: true,
                default_date_range: {
                    cbByTransMonthPctLine: {
                        enabled: true,
                        value: 365,
                    },
                },
                // Be cognizant of the order you define chart variants, they render in the order of the chart_variants array
                // Each section renders the chart groups into their own divs, from top to bottom.
                // Be cognizant of the order you define chart variants, they render in the order of the chart variant array
                charts_and_position: {
                    section_1: {
                        type: 'grid',
                        charts: [
                            'cbByTransMonthPctArea',
                            'cbByTransAmountPctBar',
                        ],
                    },
                    section_2: {
                        type: 'grid',
                        charts: [
                            'cbTransRatioBytransDateWeekPctArea',
                            'averageSpanCbTransPostDatebyWeekPctArea',
                        ],
                    },
                    section_3: {
                        type: 'card',
                        charts: [
                            'reasonCodePctTop5Donut',
                            'cardTypeDonut',
                            'cbByIssuerPctTop10Donut',
                        ],
                    },
                },
            },
            MERCHANT_ADMIN: {
                enabled: true,
                default_date_range: {
                    cbByTransMonthPctLine: {
                        enabled: true,
                        value: 365,
                    },
                },
                charts_and_position: {
                    section_1: {
                        type: 'grid',
                        charts: [
                            'cbByTransMonthPctArea',
                            'cbByTransAmountPctBar',
                            'averageSpanCbTransPostDatebyWeekPctArea',
                        ],
                    },
                    section_2: {
                        type: 'card',
                        charts: [
                            'reasonCodePctTop5Donut',
                            'cardTypeDonut',
                            'cbByIssuerPctTop10Donut',
                        ],
                    },
                },
            },
        },
        FILTERS: {
            ALL_GATEWAY_IDS: {
                enabled: false,
            },
            ALL_PLATFORMS: {
                enabled: false,
            },
        },
    },
    ERT: {
        ADD_ERT: {
            enabled: false,
            roles: [],
        },
    },
    CASE_QC_REVIEW: {
        DO_NOT_REPRESENT: {
            enabled: false,
        },
    },
    USERS: {
        READ_ONLY_OPTION: {
            enabled: false,
        },
    },
    ALERTS: {
        AUTO_REFUND_RULES: {
            enabled: true,
        },
    },
    ADVANCED_SEARCH_FILTERS: {
        INVALID_CHARGEBACKS: {
            enabled: true,
            filters: [
                {
                    alias: 'id',
                    alias_override: 'case_id',
                    label: 'Chargeback ID',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'case_no',
                    alias_override: undefined,
                    label: 'Case Reference',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'userDefinedMultiSelect',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'order_id',
                    alias_override: undefined,
                    label: 'Transaction Reference',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'mid_alias',
                    alias_override: undefined,
                    label: 'MID Alias',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'mid',
                    alias_override: undefined,
                    label: 'MID (Merchant Account ID)',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'cycle_id',
                    alias_override: 'cycle',
                    label: 'Cycle',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: '/cm/gen/cycles',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'cc_bin',
                    alias_override: undefined,
                    label: 'Card BIN',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'cc_last_4',
                    alias_override: 'cc_last_four',
                    label: 'Card Last Four',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'reason_code',
                    alias_override: 'reason_code_id',
                    label: 'Reason Code',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: 'cm/gen/rc',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'reason_category',
                    alias_override: undefined,
                    label: 'Reason Category',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: '/cm/gen/rc-categories',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'trans_amt',
                    alias_override: undefined,
                    label: 'Transaction Amount',
                    initial_value: '',
                    initial_compare_value: 'eq',
                    type: 'amount',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'dispute_amt',
                    alias_override: 'case_amt',
                    label: 'Dispute Amount',
                    initial_value: '',
                    initial_compare_value: 'eq',
                    type: 'amount',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'currency',
                    alias_override: 'currency_id',
                    label: 'Dispute Currency',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: '/cm/gen/currencies',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'card_type',
                    alias_override: undefined,
                    label: 'Card Type',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: '/cm/gen/cctypes',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'status_group_id',
                    alias_override: undefined,
                    label: 'Status',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'select',
                    endpoint: '/cm/gen/status-groups',
                    default_options: undefined,
                    is_multiple_choice: true,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'arn',
                    alias_override: undefined,
                    label: 'ARN',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'text',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: false,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'trans_date',
                    alias_override: 'trans_date_range',
                    label: 'Transaction Date',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'date',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: true,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'post_date',
                    alias_override: 'date_range_post',
                    label: 'Post Date',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'date',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: true,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'date_due',
                    alias_override: 'due_date_range',
                    label: 'Due Date',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'due-date',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: true,
                    is_enabled: true,
                    roles: [],
                },
                {
                    alias: 'date_completed',
                    alias_override: 'date_completed_range',
                    label: 'Completed Date',
                    initial_value: '',
                    initial_compare_value: undefined,
                    type: 'date',
                    endpoint: undefined,
                    default_options: undefined,
                    is_multiple_choice: false,
                    is_clear_date_icon: true,
                    is_enabled: true,
                    roles: [],
                },
            ],
        },
    },
    DATAGRID: {
        ZEBRA_STRIPED_ROWS: {
            enabled: true,
        },
        ENABLE_OVERFLOW_COLUMNS: {
            enabled: false,
        },
        ENABLE_HEADER_WRAP: {
            enabled: false, // If switching, check column definition widths. Columns might overflow off DataGridSlim.
        },
    },
    ACCENT_AREA: {
        client_theme_top_border: false,
        title_icon: true,
    },
    SEARCH_BAR: {
        enabled: true,
        style: 'outlined',
    },
    FORM_FIELDS: {
        enabled: true,
        style: 'outlined',
    },
    HAS_THEME_VARIATIONS: {
        enabled: true,
    },
    SHOW_AG_SWITCHER: {
        enabled: false,
    },
    ALERTS_IFRAME: {
        enabled: false,
    },
    HAS_REMEDY: {
        enabled: false,
    },
}

export default featuresConfig
