import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import LineChart from 'components/LineChart'
import AreaChart from 'components/AreaChart'
import { IReportFilter } from 'views/Reports'
import { dateUtilities } from 'utils/dateUtilities'
import { IDateRange } from 'components/PresetDateRange'
import { format } from 'date-fns'
/**
 * @name ByTransactionMonth
 * @desc Render % of Chargebacks by Transaction Months
 * @filterValues IReportsFilter
 */
const ByTransactionMonth = ({
    filterValues,
    isLineChart = false,
    defaultDateRange = {
        enabled: false,
        value: 0,
    },
}: {
    filterValues: IReportFilter
    isLineChart?: boolean
    defaultDateRange?: {
        enabled: boolean
        value: number
    }
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; val: string }[]>([])

    const defaultDateRangeOnLoad: {
        to: Date
        from: Date
    } = dateUtilities.getPriorDays(
        defaultDateRange.enabled ? defaultDateRange.value : 0
    )
    const { to, from } = defaultDateRangeOnLoad
    const presetDateRangeDate: IDateRange = {
        to: format(to, 'yyyy-MM-dd'),
        from: format(from, 'yyyy-MM-dd'),
    }
    const apiDateRange = `${presetDateRangeDate.from}|${presetDateRangeDate.to}`

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByTransactionMonth({
            ...filterValues,
            ...(defaultDateRange.enabled && { date_range: apiDateRange }),
        }).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return isLineChart ? (
        <LineChart
            chartTitle="% of Chargebacks by Transaction Month"
            data={data}
            loading={loading}
            returnCard={false}
            yAxisPercent
            toolTipValueName="Percent"
            // tickCount={8}
            disableAutoDomain
        />
    ) : (
        <AreaChart
            chartTitle="% of Chargebacks by Transaction Month"
            data={data}
            loading={loading}
            returnCard={false}
            yAxisPercent
            toolTipValueName="Percent"
            // tickCount={8}
            disableAutoDomain
        />
    )
}

export default ByTransactionMonth
