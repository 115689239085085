import React, { useState } from 'react'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export const AGDataGridSelectAllCheckbox = (props: any) => {
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false)
    const { api, handleSelectAllCheckbox } = props

    const handleIsAllChecked = (selectAll: boolean) => {
        if (selectAll) {
            setIsAllChecked(true)
            api?.forEachNode((node: any) => {
                node.selectThisNode(true)
            })
            handleSelectAllCheckbox(true)
        } else {
            setIsAllChecked(false)
            api.deselectAll()
            handleSelectAllCheckbox(false)
        }
    }

    return isAllChecked ? (
        <FontAwesomeIcon
            id={idDirectory.agDataGridSelectAllCheckbox.iconChecked}
            icon={faCheckSquare}
            className={'emp-agDataGridSelectAllCheckbox-selected'}
            style={{ color: clientTheme.secondary }}
            onClick={() => handleIsAllChecked(false)}
        />
    ) : (
        <FontAwesomeIcon
            id={idDirectory.agDataGridSelectAllCheckbox.iconNotChecked}
            icon={faSquare}
            className={'emp-agDataGridSelectAllCheckbox-unselected'}
            onClick={() => handleIsAllChecked(true)}
        />
    )
}
