/**
 * @description Directory for ID Attributes on SwitchList.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `switchList-div-root`,
    formLabel: `switchList-form-label`,

}

export default idDirectory
