import BaseService, { PageOf } from 'lib/BaseService'

export interface JobParams {
    user_id: number
    merchant_id: number
    amt_from?: number
    amt_to?: number
    currency_id?: number
    cycle_id?: number
    reason_code_id?: number
    card_type_id?: number
    mid_descriptor_id?: number
    source_id?: number
}

export interface ConfirmJob {
    user_id: number
    merchant_id: number
    currency_id: number
    cycle_id: number
    reason_code_id: number
    amt_from: number
    amt_to: number
    records_num: number
    is_confirmed: number
}

export interface ScheduledJob {
    user_id: number
    amt_from: number
    amt_to: number
    currency_id: number
    cycle_id: number
    reason_code_id: number
    is_confirmed: number
}

interface QueuedJob {
    amt_from: string
    amt_to: string
    currency_id: number
    currency: string
    cycle_id: number
    cycle: string
    date_created: string
    date_run_end: string
    date_run_start: string
    id: number
    is_confirmed: boolean
    merchant_id: number
    reason_code_id: number
    reason_code: string
    records_num: number
    user_id: number
    card_type: string
    source: string
    mid_descriptor: string
}

type ListAutoAcceptJobs = (params?: {
    [key: string]: any
}) => Promise<PageOf<QueuedJob[]>>
type AutoAccept = (params: JobParams) => Promise<ConfirmJob>
type ConfirmAutoAccept = (params: JobParams) => Promise<ScheduledJob>

export class ProcessService extends BaseService {
    basePath = '/cm/cases/process'

    listAutoAcceptJobs: ListAutoAcceptJobs = (params = {}) => {
        const queryString = Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')

        return queryString
            ? this.request(`/autoaccept?${queryString}`)
            : this.request(`/autoaccept`)
    }

    autoAccept: AutoAccept = (params) =>
        this.request('/autoaccept', {
            method: 'POST',
            body: JSON.stringify(params),
        })

    confirmAutoAccept: ConfirmAutoAccept = (params) =>
        this.request('/autoaccept', {
            method: 'POST',
            body: JSON.stringify({
                ...params,
                is_confirmed: 1,
            }),
        })
}
