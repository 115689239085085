import React from 'react'
import { LoadingIndicator, SwitchList } from 'components'
import { ISwitchListOption } from 'components/SwitchList/SwitchList'

interface INotificationsAreaProps {
    emailSettings: ISwitchListOption[]
    loadingEmailSettings: boolean
    updatingEmailSettings: boolean
    handleChange: (item: ISwitchListOption) => void
    expectedLength: number
}

const NotificationsArea: React.FC<INotificationsAreaProps> = ({
    emailSettings = [],
    loadingEmailSettings,
    updatingEmailSettings,
    handleChange,
    expectedLength,
}: INotificationsAreaProps) => {
    return (
        <div id={'settingsNotificationsArea'}>
            {emailSettings.length === 0 ? (
                <div className={'emp-loadingContainer'}>
                    <LoadingIndicator />
                </div>
            ) : (
                <form>
                    <SwitchList
                        testId={'settingsNotificationsArea'}
                        onChange={handleChange}
                        options={emailSettings}
                        loadingOptions={loadingEmailSettings}
                        updatingOptions={updatingEmailSettings}
                        expectedLength={expectedLength}
                        compact={false}
                    />
                </form>
            )}
        </div>
    )
}

export default NotificationsArea
